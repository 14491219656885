import { useState } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

export const useGet = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { keycloak } = useKeycloak();

  const apiURLPrefix = process.env.NODE_ENV !== 'production' ? '' : '/api';
  const subdomain = window.location.hostname.split('.')[0];

  const getData = async (path, options = {}) => {
    try {
      if (keycloak.authenticated) {
        await keycloak.updateTokenIfNeeded();
      }

      const headers = {
        subdomain,
        ...(keycloak.authenticated && { Authorization: `Bearer ${keycloak.token}` }),
      };

      const response = await axios.get(`${apiURLPrefix}${path}`, {
        ...options,
        headers,
        timeout: 30000,
      });

      setData(response.data);
      setError(null);
      return response;
    } catch (error) {
      console.error('Request failed:', error);

      if (error.response?.status === 401 || error.response?.status === 403) {
        if (keycloak.authenticated) {
          try {
            await keycloak.updateToken(30);
            const headers = {
              subdomain,
              Authorization: `Bearer ${keycloak.token}`,
            };
            const response = await axios.get(`${apiURLPrefix}${path}`, {
              ...options,
              headers,
              timeout: 30000,
            });
            setData(response.data);
            setError(null);
            return response;
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError);
            keycloak.login();
          }
        }
      }

      setError({
        message: error.response?.data?.message || error.message || 'Request failed',
        status: error.response?.status,
        data: error.response?.data,
      });
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const getDocument = async (pdfRendered) => {
    const documentURL = pdfRendered.fileLocation === undefined ? `/sds/${pdfRendered.fileHash}` : `/documents/${pdfRendered.fileLocation}`;
    return getData(documentURL, { responseType: 'arraybuffer' });
  };

  const getImage = async (urlFragment) => {
    const imageURL = `/resources/${subdomain}/${urlFragment}`;
    return getData(imageURL);
  };

  return { getDocument, getImage, getData, data, isLoading, error };
};
