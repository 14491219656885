import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears, faTimes, faExclamationTriangle, faBinoculars, faArchive, faPenToSquare, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { faCircleChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { usePatch } from '../../../../hooks/usePatch';
import { updateRecordStatus, viewDocument, editDocument } from '../../../../utils/rowActions';
import { useConfirmationModal } from '../../../common/ConfirmationModal';

const Actions = ({ record }) => {
  const { patchData, isLoading, error } = usePatch();
  const navigate = useNavigate();
  const showConfirmation = useConfirmationModal();

  const handleDeleteRecord = (record) => {
    showConfirmation({
      id: `delete-record-${record._id}`,
      title: 'Delete Record',
      message: `Are you sure you want to delete ${record.productIdentifier}?`,
      onConfirm: async () => {
        await updateRecordStatus(record, 'deleted', patchData);
      },
      confirmText: 'Delete',
      confirmVariant: 'danger',
      isLoading,
      loadingText: 'Deleting...',
      icon: faTrash,
    });
  };

  //available actions will be based on the status of the record, account features, and the users permissions
  const availableActions = [];
  const dropDownActions = []; //this is going to hold the dropdown-items for the dropdown button

  switch (record.status) {
    case 'pending':
      availableActions.push(
        <button
          key="requestChemical"
          onClick={() => updateRecordStatus(record, 'inprocess', patchData)}
          style={{ border: 'none', background: 'none' }}
        >
          <FontAwesomeIcon icon={faGears} title="Send to Data Sheet Solutions" transform="grow-8" />
        </button>
      );
      dropDownActions.push(
        <Dropdown.Item key="requestChemical" onClick={() => updateRecordStatus(record, 'inprocess', patchData)}>
          Request SDS
          <FontAwesomeIcon icon={faGears} title="Send to Data Sheet Solutions" className="ms-2" />
        </Dropdown.Item>
      );
      dropDownActions.push(
        <Dropdown.Item key="sendArchive" onClick={() => updateRecordStatus(record, 'archived')}>
          Archive Request
          <FontAwesomeIcon icon={faArchive} title="Archive" className="ms-2" />
        </Dropdown.Item>
      );
      dropDownActions.push(
        <Dropdown.Item key="deleteRecord" onClick={() => handleDeleteRecord(record)} className="text-danger">
          Delete Record
          <FontAwesomeIcon icon={faTrash} title="Delete Record" className="ms-2" />
        </Dropdown.Item>
      );
      break;
    case 'inprocess':
      dropDownActions.push(
        <Dropdown.Item key="sendPending" onClick={() => updateRecordStatus(record, 'pending', patchData)}>
          Send Back to Pending
          <FontAwesomeIcon icon={faGears} title="Send Back to Pending" className="ms-2" />
        </Dropdown.Item>
      );
      dropDownActions.push(
        <Dropdown.Item key="deleteRecord" onClick={() => handleDeleteRecord(record)} className="text-danger">
          Delete Record
          <FontAwesomeIcon icon={faTrash} title="Delete Record" className="ms-2" />
        </Dropdown.Item>
      );
      break;
    case 'active':
      availableActions.push(
        <button key="viewDocument" onClick={() => viewDocument(record, navigate)} style={{ border: 'none', background: 'none' }}>
          <FontAwesomeIcon icon={faBinoculars} title="View Document" transform="grow-8" />
        </button>
      );
      availableActions.push(
        <button key="editDocument" onClick={() => editDocument(record, navigate)} style={{ border: 'none', background: 'none' }}>
          <FontAwesomeIcon icon={faPenToSquare} title="Edit Document" transform="grow-8" />
        </button>
      );
      dropDownActions.push(
        <Dropdown.Item key="viewDocument" onClick={() => viewDocument(record, navigate)}>
          View Document
          <FontAwesomeIcon icon={faBinoculars} title="View Document" className="ms-2" />
        </Dropdown.Item>
      );
      dropDownActions.push(
        <Dropdown.Item key="editDocument" onClick={() => editDocument(record, navigate)}>
          Edit Document
          <FontAwesomeIcon icon={faPenToSquare} title="Edit Document" className="ms-2" />
        </Dropdown.Item>
      );
      dropDownActions.push(
        <Dropdown.Item key="sendPending" onClick={() => updateRecordStatus(record, 'pending', patchData)}>
          Send Back to Pending
          <FontAwesomeIcon icon={faGears} title="Send Back to Pending" className="ms-2" />
        </Dropdown.Item>
      );
      dropDownActions.push(
        <Dropdown.Item key="sendArchive" onClick={() => updateRecordStatus(record, 'archived', patchData)}>
          Archive Request
          <FontAwesomeIcon icon={faArchive} title="Archive" className="ms-2" />
        </Dropdown.Item>
      );
      dropDownActions.push(
        <Dropdown.Item key="deleteRecord" onClick={() => handleDeleteRecord(record)} className="text-danger">
          Delete Record
          <FontAwesomeIcon icon={faTrash} title="Delete Record" className="ms-2" />
        </Dropdown.Item>
      );
      break;
    case 'archived':
      availableActions.push(
        <button key="restoreRecord" onClick={() => updateRecordStatus(record, 'active', patchData)} style={{ border: 'none', background: 'none' }}>
          <FontAwesomeIcon icon={faArchive} title="Restore Record" transform="grow-8" />
        </button>
      );
      dropDownActions.push(
        <Dropdown.Item key="restoreRecord" onClick={() => updateRecordStatus(record, 'active', patchData)}>
          Restore Record
          <FontAwesomeIcon icon={faArchive} title="Restore Record" className="ms-2" />
        </Dropdown.Item>
      );
      dropDownActions.push(
        <Dropdown.Item key="deleteRecord" onClick={() => handleDeleteRecord(record)} className="text-danger">
          Delete Record
          <FontAwesomeIcon icon={faTrash} title="Delete Record" className="ms-2" />
        </Dropdown.Item>
      );
      break;
    default:
      availableActions.push(<FontAwesomeIcon icon={faExclamationTriangle} title="Unknown Status" />);
      break;
  }
  if (dropDownActions.length > 0) {
    availableActions.push(
      <Dropdown key="dropdown">
        <Dropdown.Toggle variant="secondary" id="dropdownMenuButton" className="noCaret" as="div">
          <FontAwesomeIcon icon={faCircleChevronDown} title="More Actions" transform="grow-8" />
        </Dropdown.Toggle>
        <Dropdown.Menu>{dropDownActions}</Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <div className="actions" style={{ display: 'flex', gap: '10px' }}>
      {availableActions}
    </div>
  );
};

export default Actions;
