import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModal } from '../../context/ModalContext';

/**
 * A reusable confirmation modal component
 * @param {Object} props - The component props
 * @param {string} props.message - The confirmation message
 * @param {Function} props.onConfirm - Function to call when confirmed
 * @param {Function} props.onCancel - Function to call when cancelled
 * @param {string} [props.confirmText='Confirm'] - Text for the confirm button
 * @param {string} [props.confirmVariant='danger'] - Bootstrap variant for confirm button
 * @param {boolean} [props.isLoading=false] - Loading state
 * @param {string} [props.loadingText='Processing...'] - Text to show while loading
 * @param {import('@fortawesome/fontawesome-svg-core').IconDefinition} [props.icon] - FontAwesome icon to show
 */
const ConfirmationModalContent = ({
  message,
  onConfirm,
  onCancel,
  confirmText = 'Confirm',
  confirmVariant = 'danger',
  isLoading = false,
  loadingText = 'Processing...',
  icon,
}) => {
  return (
    <>
      <div className="flex-grow-1 d-flex flex-column overflow-auto">
        <p>{message}</p>
      </div>
      <div className="d-flex manual-modal-footer mt-3 justify-content-end gap-2">
        <Button variant="secondary" onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant={confirmVariant} onClick={onConfirm} disabled={isLoading}>
          {isLoading ? (
            loadingText
          ) : (
            <>
              {icon && <FontAwesomeIcon icon={icon} className="me-2" />}
              {confirmText}
            </>
          )}
        </Button>
      </div>
    </>
  );
};

/**
 * Custom hook for showing confirmation modals
 * @returns {Function} Function to show a confirmation modal
 */
export const useConfirmationModal = () => {
  const { showModal, hideModal } = useModal();

  const showConfirmation = ({
    id,
    title = 'Confirm Action',
    message,
    onConfirm,
    confirmText,
    confirmVariant,
    isLoading,
    loadingText,
    icon,
    size = 'sm',
    centered = true,
    resizable = true,
    overlay = true,
  }) => {
    const modalId = id || `confirmation-${Date.now()}`;

    showModal({
      id: modalId,
      title,
      component: ConfirmationModalContent,
      props: {
        message,
        onConfirm: async () => {
          await onConfirm();
          hideModal(modalId);
        },
        onCancel: () => hideModal(modalId),
        confirmText,
        confirmVariant,
        isLoading,
        loadingText,
        icon,
      },
      size,
      centered,
      resizable,
      overlay,
    });

    return modalId;
  };

  return showConfirmation;
};

export default ConfirmationModalContent;
