import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { PhotoArrayCell } from 'components/custom-attributes/PhotoArray';
import SignalWord from './sub-components/SignalWord';
import Pictogram from './sub-components/Pictogram';
import Actions from './sub-components/Actions';
import SdsDownload from './sub-components/SdsDownload';
import { StringCell } from 'components/custom-attributes/String';
import { NumberCell } from 'components/custom-attributes/Number';
import { MultiSelectCell } from 'components/custom-attributes/Multi-Select';
import { TextAreaDisplay } from 'components/custom-attributes/TextArea';
import { Button } from 'react-bootstrap';
import { LocationArrayCell } from 'components/custom-attributes/LocationArray';
import { usePortal } from '../../../context/Portal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const columnHelper = createColumnHelper();

/**
 * Extracts plain text from a potentially highlighted string or React element
 * @param {*} value - The value to extract text from
 * @returns {string} - The plain text content
 */
const extractTextContent = (value) => {
  // Handle null/undefined
  if (!value) return '';

  // If it's already a string or number, return it
  if (typeof value === 'string' || typeof value === 'number') {
    return value.toString();
  }

  // If it's an array (multiple highlighted segments), join them
  if (Array.isArray(value)) {
    return value
      .map((item) => {
        if (typeof item === 'string') return item;
        // Extract text from React element
        return item?.props?.children || '';
      })
      .join('');
  }

  // If it's a React element with highlighted text
  if (value?.props?.children) {
    if (Array.isArray(value.props.children)) {
      return value.props.children
        .map((child) => {
          if (typeof child === 'string') return child;
          return child?.props?.children || '';
        })
        .join('');
    }
    return value.props.children.toString();
  }

  // Fallback: try to convert to string
  return value.toString();
};

/**
 * Custom sorting function that sorts strings case-insensitively with numbers first
 * Handles highlighted text content from search functionality
 */
const customAlphanumericSort = (rowA, rowB, columnId) => {
  const aValue = rowA.getValue(columnId);
  const bValue = rowB.getValue(columnId);

  const a = extractTextContent(aValue).toLowerCase();
  const b = extractTextContent(bValue).toLowerCase();

  // Check if either value starts with a number
  const aStartsWithNumber = /^\d/.test(a);
  const bStartsWithNumber = /^\d/.test(b);

  // If one starts with a number and the other doesn't, prioritize the number
  if (aStartsWithNumber && !bStartsWithNumber) return -1;
  if (!aStartsWithNumber && bStartsWithNumber) return 1;

  // If both start with numbers or both don't, sort alphabetically
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
};

/**
 * Custom sorting function for search results that sorts by number of hits
 */
const searchResultsSort = (rowA, rowB) => {
  const contentSearchResults = window.contentSearchResults || [];

  // Get hits for row A
  const resultA = contentSearchResults.find((r) => r.fileHash === rowA.original.fileHash);
  const hitsA = resultA?.snippets?.length || 0;

  // Get hits for row B
  const resultB = contentSearchResults.find((r) => r.fileHash === rowB.original.fileHash);
  const hitsB = resultB?.snippets?.length || 0;

  return hitsB - hitsA; // Sort descending (most hits first)
};

// Add this helper function at the top of the file
const getDefaultVisibility = (columns) => {
  const visibility = {};
  columns.forEach((column) => {
    // Get the defaultVisible from either the column def or the column itself
    const defaultVisible = column.columnDef?.defaultVisible ?? column.defaultVisible ?? false;
    visibility[column.id] = defaultVisible;
  });
  return visibility;
};

// Add these state variables outside getColumnDefinitions
let lastSelectedIndex = -1;
let lastPageIndex = -1;

// Define a mapping of attribute types to their corresponding components
const attributeComponents = {
  String: StringCell,
  Number: NumberCell,
  'multi-select': MultiSelectCell,
  TextArea: TextAreaDisplay,
};

// Export a function to get column definitions based on table name
export const getColumnDefinitions = (tableName, onAddFilter) => {
  // Define individual columns
  const selectColumn = columnHelper.display({
    id: 'select',
    defaultVisible: true, // Always visible
    header: ({ table }) => (
      <div
        className="select-cell-container"
        onClick={(e) => {
          e.preventDefault();
          const handler = table.getToggleAllRowsSelectedHandler();
          handler(e);
        }}
      >
        <input
          type="checkbox"
          checked={table.getIsAllRowsSelected()}
          onChange={(e) => {
            const handler = table.getToggleAllRowsSelectedHandler();
            handler(e);
          }}
          style={{ pointerEvents: 'none' }}
        />
      </div>
    ),
    cell: ({ row, table }) => (
      <div
        className="select-cell-container"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const rowIndex = row.index;
          const absoluteRowIndex = rowIndex;

          if (e.shiftKey && lastSelectedIndex >= 0) {
            console.log('shift key pressed and last selected index', lastSelectedIndex);
            const start = Math.min(lastSelectedIndex, absoluteRowIndex);
            const end = Math.max(lastSelectedIndex, absoluteRowIndex);

            const rows = table.getPreFilteredRowModel().rows;
            const targetSelected = !row.getIsSelected();

            // Create a new selection state
            const newSelection = { ...table.getState().rowSelection };

            // Update selection state for all rows in range
            rows.forEach((r, index) => {
              if (index >= start && index <= end && r.id != null) {
                newSelection[r.id] = targetSelected;
              }
            });

            // Filter out any undefined or null keys
            Object.keys(newSelection).forEach((key) => {
              if (key == null || newSelection[key] == null) {
                delete newSelection[key];
              }
            });

            // Update the table's selection state
            table.setRowSelection(newSelection);
          } else {
            const handler = row.getToggleSelectedHandler();
            handler(e);
            lastSelectedIndex = absoluteRowIndex;
            console.log('lastSelectedIndex', lastSelectedIndex);
            lastPageIndex = table.getState().pagination.pageIndex;
            console.log('lastPageIndex', lastPageIndex);
          }
        }}
        style={{
          userSelect: 'none',
          WebkitUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',
        }}
      >
        <input
          type="checkbox"
          checked={row.getIsSelected()}
          onChange={(e) => {
            const handler = row.getToggleSelectedHandler();
            handler(e);
          }}
          style={{ pointerEvents: 'none' }}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
    styleOverride: {
      padding: '0px',
      margin: '0px',
    },
  });

  const recordIdColumn = columnHelper.accessor('recordId', {
    id: 'recordId',
    header: 'SDS ID',
    defaultVisible: false,
    enableSorting: true,
    cell: (info) => info.getValue(),
  });

  const searchResultsColumn = columnHelper.accessor(
    (row) => {
      // This will be populated by the content search results
      return { fileHash: row.fileHash };
    },
    {
      id: 'searchResults',
      header: 'Search',
      defaultVisible: false,
      cell: ({ row }) => {
        const record = row.original;
        const contentSearchResults = window.contentSearchResults || [];
        const result = contentSearchResults.find((r) => r.fileHash === record.fileHash);

        if (!result || !result.snippets || result.snippets.length === 0) {
          return null;
        }

        return (
          <div className="search-results-cell">
            <div className="search-hit-count" title={result.snippets.join('\n\n')}>
              <FontAwesomeIcon icon={faSearch} />
              <span>{result.snippets.length}</span>
            </div>
          </div>
        );
      },
      size: 80,
      enableSorting: true,
      sortingFn: searchResultsSort,
    }
  );

  const photosColumn = columnHelper.accessor('photos', {
    id: 'photos',
    header: 'Images',
    defaultVisible: false,
    cell: (info) => <PhotoArrayCell value={info.getValue()} />,
    textAlign: 'center',
  });

  const productIdentifierColumn = columnHelper.accessor('productIdentifier', {
    id: 'productIdentifier',
    header: 'Product Identifier',
    defaultVisible: true,
    enableSorting: true,
    sortingFn: customAlphanumericSort,
    cell: (info) => info.getValue(),
  });

  const akaColumn = columnHelper.accessor('aka', {
    id: 'aka',
    header: 'AKA',
    defaultVisible: false,
    cell: (info) => (Array.isArray(info.getValue()) ? info.getValue().join(', ') : info.getValue()),
  });

  const manNameColumn = columnHelper.accessor('manName', {
    id: 'manName',
    header: 'Manufacturer',
    defaultVisible: true,
    enableSorting: true,
    sortingFn: customAlphanumericSort,
    cell: (info) => info.getValue(),
  });

  const signalWordColumn = columnHelper.accessor('signalWord', {
    id: 'signalWord',
    header: 'Signal Word',
    defaultVisible: true,
    cell: (info) => <SignalWord word={info.getValue()} />,
    textAlign: 'center',
  });

  const pictogramsColumn = columnHelper.accessor('pictograms', {
    id: 'pictograms',
    header: 'Pictograms',
    defaultVisible: true,
    cell: (info) => {
      const pictograms = info.getValue();
      return Array.isArray(pictograms) ? pictograms.map((ghs, index) => <Pictogram key={index} ghs={ghs} onAddFilter={onAddFilter} />) : null;
    },
    textAlign: 'center',
  });

  const locationsColumn = columnHelper.accessor('locations', {
    id: 'locations',
    header: 'Locations',
    defaultVisible: true,
    cell: (info) => (
      <LocationArrayCell value={{ selected: info.getValue() || [], indeterminate: [] }} onAddFilter={onAddFilter} recordId={info.row.original._id} />
    ),
  });

  const sdsColumn = columnHelper.accessor('sds', {
    id: 'sds',
    header: 'SDS',
    defaultVisible: false,
    cell: (info) => <SdsDownload record={info.row.original} />, // Pass the entire row object
    textAlign: 'center',
  });

  const actionsColumn = columnHelper.accessor('actions', {
    id: 'actions',
    header: 'Actions',
    defaultVisible: true,
    cell: (info) => <Actions record={info.row.original} />, // Pass the entire row object
  });

  //request columns
  const requestorNameColumn = columnHelper.accessor('requestorName', {
    id: 'requestorName',
    header: 'Requestor',
    defaultVisible: true,
    cell: (info) => info.getValue(),
  });

  const requestorPhoneColumn = columnHelper.accessor('requestorPhone', {
    id: 'requestorPhone',
    header: 'Phone',
    defaultVisible: true,
    cell: (info) => info.getValue(),
  });

  const requestDetailsColumn = columnHelper.accessor('requestDetails', {
    id: 'requestDetails',
    header: 'Request Details',
    defaultVisible: true,
    cell: (info) => info.getValue(),
  });

  // Define column configurations for different tables
  const columnConfigs = {
    activeTable: [
      selectColumn,
      recordIdColumn,
      searchResultsColumn,
      photosColumn,
      productIdentifierColumn,
      akaColumn,
      manNameColumn,
      signalWordColumn,
      pictogramsColumn,
      locationsColumn,
      sdsColumn,
      actionsColumn,
    ],
    pendingTable: [
      selectColumn,
      photosColumn,
      productIdentifierColumn,
      akaColumn,
      manNameColumn,
      requestorNameColumn,
      requestorPhoneColumn,
      requestDetailsColumn,
      locationsColumn,
      sdsColumn,
      actionsColumn,
    ],
    inprocessTable: [
      selectColumn,
      photosColumn,
      productIdentifierColumn,
      akaColumn,
      manNameColumn,
      signalWordColumn,
      pictogramsColumn,
      locationsColumn,
      sdsColumn,
      actionsColumn,
    ],
    archivedTable: [
      selectColumn,
      recordIdColumn,
      photosColumn,
      productIdentifierColumn,
      akaColumn,
      manNameColumn,
      signalWordColumn,
      pictogramsColumn,
      locationsColumn,
      sdsColumn,
      actionsColumn,
    ],
  };

  let columns = columnConfigs[tableName] || [];

  // Create a hook component to handle portal config
  const ColumnsWithPortal = () => {
    const { portalConfig } = usePortal();

    if (portalConfig?.portal?.attributeSettings) {
      const customAttributes = portalConfig.portal.attributeSettings;
      const customColumns = Object.entries(customAttributes).map(([key, attr]) =>
        columnHelper.accessor(key, {
          id: attr.name || key,
          header: attr.name || key,
          defaultVisible: attr.defaultVisible ?? false, // Custom columns hidden by default
          cell: (info) => {
            const row = info.row.original;
            const customAttrValue = row[attr.name];
            if (customAttrValue !== undefined) {
              const Component = attributeComponents[attr.type];
              return Component ? <Component value={customAttrValue} columnName={attr.name} onAddFilter={onAddFilter} /> : customAttrValue;
            }
            return null;
          },
        })
      );

      // Insert custom columns before the last column (usually 'actions')
      columns = [...columns.slice(0, -1), ...customColumns, columns[columns.length - 1]];
    }

    return null;
  };

  // Add getDefaultVisibility as a static property of the columns array
  columns.getDefaultVisibility = () => getDefaultVisibility(columns);
  columns.PortalComponent = ColumnsWithPortal;

  return columns;
};
