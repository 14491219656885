import { useState, useCallback } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

const useRequest = (method) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const { keycloak } = useKeycloak();

  const apiURLPrefix = process.env.NODE_ENV === 'development' ? '' : '/api';
  const subdomain = window.location.hostname.split('.')[0];

  // Helper function to create request config
  const createRequestConfig = (path, data, options, token) => {
    const defaultHeaders = {
      subdomain,
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    };
    // Remove Content-Type if FormData or if specified in options.headers
    if (data instanceof FormData || (options.headers && 'Content-Type' in options.headers)) {
      console.log('removing Content-Type');
      delete defaultHeaders['Content-Type'];
    }

    return {
      method,
      url: `${apiURLPrefix}${path}`,
      timeout: 30000,
      ...options,
      data,
      headers: {
        ...defaultHeaders,
        ...(options.headers || {}),
      },
    };
  };

  // Helper function to make the actual request
  const makeRequest = async (config) => {
    const res = await axios(config);
    setResponse(res.data);
    setError(null);
    return method === 'get' ? res : res.data;
  };

  const sendRequest = useCallback(
    async (path, data = {}, options = {}) => {
      console.log('sending request', path, data, options);
      setIsLoading(true);
      setError(null);

      try {
        // Pre-request token refresh if authenticated
        if (keycloak?.authenticated) {
          await keycloak.updateTokenIfNeeded();
        }

        // Make initial request
        const config = createRequestConfig(path, data, options, keycloak?.authenticated ? keycloak.token : null);
        return await makeRequest(config);
      } catch (error) {
        // Handle token related errors with retry
        if ((error.response?.status === 401 || error.response?.status === 403) && keycloak?.authenticated) {
          try {
            await keycloak.updateToken(30);

            // Retry request with new token
            const retryConfig = createRequestConfig(path, data, options, keycloak.token);

            return await makeRequest(retryConfig);
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError);
            keycloak.login();
          }
        }

        // Handle all other errors
        console.error('Request failed:', error);
        const errorObj = {
          message: error.response?.data?.message || error.message || 'Request failed',
          status: error.response?.status || 0,
          data: error.response?.data,
        };

        setError(errorObj);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [apiURLPrefix, keycloak, subdomain, method]
  );

  return { sendRequest, response, isLoading, error };
};

export default useRequest;
