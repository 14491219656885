import './App.css';
import { PortalProvider } from './context/Portal';
import kc from './keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import SplashScreen from './components/portal/Splashscreen';
import { useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
//pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

function App() {
  const [keycloakReady, setKeycloakReady] = useState(false);

  //appends the fontawesome kit
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://kit.fontawesome.com/75da6535ae.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onKeycloakEvent = (event, error) => {
    //console.log('onKeycloakEvent', event, error);
    //console.log(`Keycloak Event ${event}`);
    if (event && event === 'onReady') {
      setKeycloakReady(true);
    }
  };

  const onKeycloakTokens = (tokens) => {
    //console.log('onKeycloakTokens', tokens);
  };

  //console.log('authClient is set', kc);
  return (
    <ReactKeycloakProvider
      authClient={kc}
      initOptions={{
        pkceMethod: 'S256',
        checkLoginIframe: false,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        enableLogging: true,
        // Token refresh settings
        minValidity: 30, // Refresh token when less than 30 seconds remain
        refreshToken: true,
        checkLoginIframeInterval: 5, // Check every 5 seconds
        flow: 'standard',
        responseMode: 'fragment',
        updateMinValidity: 20, // Start trying to refresh 20 seconds before expiry
      }}
      onEvent={(event, error) => {
        if (event === 'onTokenExpired') {
          console.log('Token expired event received');
          kc.updateTokenIfNeeded()
            .then(() => console.log('Token refreshed after expiry event'))
            .catch((err) => console.error('Failed to refresh token after expiry event:', err));
        }
        onKeycloakEvent(event, error);
      }}
      onTokens={onKeycloakTokens}
      LoadingComponent={<div>Loading Keycloak...</div>}
    >
      <SplashScreen keycloakReady={keycloakReady}>
        <PortalProvider />
      </SplashScreen>
    </ReactKeycloakProvider>
  );
}

export default App;
