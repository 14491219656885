import React, { useState } from 'react';

const TextAreaDisplay = ({ attribute }) => {
  const { name, value, label, displayStyles, displayClasses } = attribute;

  //define the default classes and styles below
  const defaultClasses = {
    component: 'textAreaDisplay',
    label: 'textArea-label',
    value: 'textArea-value',
  };

  const defaultStyles = {
    component: {},
    label: {},
    value: {},
  };
  //define the default classes and styles above

  return (
    <div className={displayClasses?.component || defaultClasses.component} style={displayStyles?.component || defaultStyles.component}>
      <div className={displayClasses?.label || defaultClasses.label} style={displayStyles?.label || defaultStyles.label}>
        {label || name}
      </div>
      <div className={displayClasses?.value || defaultClasses.value} style={displayStyles?.value || defaultStyles.value}>
        {value}
      </div>
    </div>
  );
};

const TextAreaEdit = ({ attribute, onChange }) => {
  //define the default classes and styles below
  const defaultClass = {
    component: 'col-12 p-2',
    label: 'form-label fw-bold',
    input: 'form-control',
  };

  const defaultStyle = {
    component: {},
    label: {},
    input: {},
  };
  //define the default classes and styles above

  const { name, value, label, required, editClasses, editStyles, helpText } = attribute;

  const handleChange = (e) => {
    onChange(name, e.target.value);
  };

  const [isHelpTextVisible, setHelpTextVisible] = useState(false);

  const toggleHelpText = () => {
    setHelpTextVisible(!isHelpTextVisible);
  };

  return (
    <div
      className={`textAreaEditComponent ${editClasses?.component || defaultClass.component}`}
      style={editStyles?.component || defaultStyle.component}
    >
      <label
        htmlFor={`textArea-edit-${name}`}
        className={'textAreaEditLabel ' + (editClasses?.label || defaultClass.label)}
        style={editStyles?.label || defaultStyle.label}
      >
        {label || name} {required && <span style={{ color: 'red' }}>*</span>}
        {helpText && (
          <span title={helpText} className="help-text-icon" onClick={toggleHelpText} style={{ marginLeft: '5px', cursor: 'pointer' }}>
            <i className={`fa-regular fa-circle-question ${isHelpTextVisible ? 'text-primary' : ''}`}></i>
          </span>
        )}
      </label>
      <textarea
        className={'textAreaEditInput ' + (editClasses?.input || defaultClass.input)}
        id={`textArea-edit-${name}`}
        name={name}
        value={value || ''}
        onChange={handleChange}
        required={required}
        style={editStyles?.input || defaultStyle.input}
        rows={4}
      />
      {helpText && (
        <small className="form-text text-muted" style={{ display: isHelpTextVisible ? 'block' : 'none' }}>
          {helpText}
        </small>
      )}
    </div>
  );
};

const TextAreaArrayEdit = ({ attribute, onChange }) => {
  // Create a modified version of the attribute with joined value
  const modifiedAttribute = {
    ...attribute,
    value: Array.isArray(attribute.value) ? attribute.value.join('. ') : attribute.value,
  };

  // Handle changes by splitting the text and updating the array
  const handleArrayChange = (name, value) => {
    // Split on periods, trim whitespace, and filter out empty strings
    const newArray = value
      .split('.')
      .map((item) => item.trim())
      .filter((item) => item.length > 0);

    // Call the parent onChange with the new array
    onChange(name, newArray);
  };

  return <TextAreaEdit attribute={modifiedAttribute} onChange={handleArrayChange} />;
};

export { TextAreaDisplay, TextAreaEdit, TextAreaArrayEdit };
