import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faMagicWandSparkles, faTable, faSave } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { usePatch } from '../../../../hooks/usePatch';
import Wizard from './Wizard';
import Expert from './Expert';
import PowerModal from '../PowerModal';

const EditControls = ({ record }) => {
  const [selectedView, setSelectedView] = useState('Wizard');
  const [editedRecord, setEditedRecord] = useState(null);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const navigate = useNavigate();
  const { patchData, isLoading } = usePatch();

  // Initialize editedRecord when component mounts or record changes
  useEffect(() => {
    if (record) {
      setEditedRecord({ ...record }); // Create a deep copy of the record
    }
  }, [record]);

  useEffect(() => {
    const savedView = Cookies.get('selectedView');
    if (savedView) {
      setSelectedView(savedView);
    }
  }, []);

  const handleViewChange = (view) => {
    setSelectedView(view);
    Cookies.set('selectedView', view);
  };

  const handleRecordUpdate = (updates) => {
    setEditedRecord((prevRecord) => ({
      ...prevRecord,
      ...updates,
    }));
  };

  const getChangedFields = () => {
    if (!editedRecord || !record) return [];

    return Object.keys(editedRecord).reduce((changes, key) => {
      if (editedRecord[key] !== record[key]) {
        changes.push({
          action: 'set',
          field: key,
          value: editedRecord[key],
        });
      }
      return changes;
    }, []);
  };

  const handleSave = async () => {
    const changes = getChangedFields();

    if (changes.length === 0) {
      return; // No changes to save
    }

    try {
      const operations = [
        {
          recordId: record._id,
          changes: changes,
        },
      ];

      await patchData('/admin/records/update', { operations });
      // Optionally handle successful save (e.g., show toast notification)
    } catch (error) {
      // Handle error (e.g., show error message)
      console.error('Error saving changes:', error);
    }
  };

  const handleClose = async () => {
    const hasChanges = getChangedFields().length > 0;
    if (hasChanges) {
      setShowCloseModal(true);
    } else {
      navigate(-1);
    }
  };

  const handleSaveAndClose = async () => {
    try {
      await handleSave();
      navigate(-1);
    } catch (error) {
      console.error('Error saving changes:', error);
      // Optionally show error message to user
    }
  };

  const handleDiscardChanges = () => {
    setShowCloseModal(false);
    navigate(-1);
  };

  return (
    <div className="edit-controls d-flex flex-column w-100">
      <div className="edit-controls-header d-flex p-3">
        <div className="btn-group me-2" role="group" aria-label="View Selector">
          <Button variant={selectedView === 'Wizard' ? 'info active text-light' : 'info text-light'} onClick={() => handleViewChange('Wizard')}>
            <FontAwesomeIcon icon={faMagicWandSparkles} /> Wizard
          </Button>
          <Button variant={selectedView === 'Expert' ? 'primary active text-light' : 'primary text-light'} onClick={() => handleViewChange('Expert')}>
            <FontAwesomeIcon icon={faTable} /> Expert
          </Button>
        </div>
        <Button variant="success" onClick={handleSave} disabled={isLoading || getChangedFields().length === 0} className="me-auto">
          <FontAwesomeIcon icon={faSave} /> Save
        </Button>
        <Button variant="danger" onClick={handleClose} className="ms-2">
          <FontAwesomeIcon icon={faXmark} />
        </Button>
      </div>
      <div className="edit-controls-body d-flex flex-grow-1">
        {selectedView === 'Wizard' && editedRecord && <Wizard record={editedRecord} originalRecord={record} onUpdate={handleRecordUpdate} />}
        {selectedView === 'Expert' && editedRecord && <Expert record={editedRecord} originalRecord={record} onUpdate={handleRecordUpdate} />}
      </div>

      <PowerModal
        show={showCloseModal}
        onHide={() => setShowCloseModal(false)}
        title="Unsaved Changes"
        size="sm"
        overlay={true}
        footerContent={
          <div>
            <Button variant="success" onClick={handleSaveAndClose} className="me-2">
              Save and Close
            </Button>
            <Button variant="danger" onClick={handleDiscardChanges}>
              Discard Changes
            </Button>
          </div>
        }
      >
        <p>You have unsaved changes. Would you like to save before closing?</p>
      </PowerModal>
    </div>
  );
};

export default EditControls;
