import React, { useState, useCallback, useEffect, memo } from 'react';
import { Badge, Form, Dropdown, InputGroup } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleChevronDown,
  faUserMagnifyingGlass,
  faGears,
  faFlask,
  faArchive,
  faCog,
  faXmark,
  faSearch,
  faFileSearch,
} from '@fortawesome/pro-solid-svg-icons';

import './LifecycleBar.css';

const DEBOUNCE_DELAY = 500;

// Move SearchControl outside and memoize it
const SearchControl = memo(({ localSearchType, handleSearchTypeChange, localSearchTerm, handleSearchInput, clearSearch }) => (
  <InputGroup style={{ width: '250px', zIndex: 10 }}>
    <Dropdown>
      <Dropdown.Toggle variant="secondary" id="search-type-dropdown" className="d-flex align-items-center noCaret">
        <FontAwesomeIcon icon={faCog} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item active={localSearchType === 'basic'} onClick={() => handleSearchTypeChange('basic')}>
          <FontAwesomeIcon icon={faSearch} /> Basic Keyword
        </Dropdown.Item>
        <Dropdown.Item active={localSearchType === 'contents'} onClick={() => handleSearchTypeChange('contents')}>
          <FontAwesomeIcon icon={faFileSearch} /> SDS Contents
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    <Form.Control
      type="text"
      placeholder={`${localSearchType === 'basic' ? 'Basic' : 'Contents'} Search`}
      value={localSearchTerm}
      onChange={(e) => handleSearchInput(e.target.value)}
      style={{ paddingRight: '30px' }}
    />
    {localSearchTerm && (
      <div
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 10,
          cursor: 'pointer',
        }}
        onClick={clearSearch}
      >
        <FontAwesomeIcon icon={faXmark} className="text-danger" />
      </div>
    )}
  </InputGroup>
));

SearchControl.displayName = 'SearchControl';

const LifecycleBar = ({ counts, onSearchChange, searchTerm, searchType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [localSearchType, setLocalSearchType] = useState(searchType || 'basic');
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm || '');
  const [searchTimeout, setSearchTimeout] = useState(null);

  // Debounced search handler
  const debouncedSearch = useCallback(
    (value, type) => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      const timeoutId = setTimeout(() => {
        onSearchChange(value, type);
      }, DEBOUNCE_DELAY);

      setSearchTimeout(timeoutId);
    },
    [onSearchChange]
  );

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchTimeout]);

  const handleSearchTypeChange = useCallback(
    (type) => {
      setLocalSearchType(type);
      debouncedSearch(localSearchTerm, type);
    },
    [localSearchTerm, debouncedSearch]
  );

  const handleSearchInput = useCallback(
    (value) => {
      setLocalSearchTerm(value);
      debouncedSearch(value, localSearchType);
    },
    [localSearchType, debouncedSearch]
  );

  const clearSearch = useCallback(() => {
    setLocalSearchTerm('');
    debouncedSearch('', localSearchType);
  }, [localSearchType, debouncedSearch]);

  const handleDivClick = (path) => {
    navigate(`/admin/${path}`);
    setIsOpen(false);
  };

  const getClassName = (path) => {
    return location.pathname.includes(path) ? 'lifecycle-item currentTab' : 'lifecycle-item';
  };

  const getCurrentTab = () => {
    if (location.pathname.includes('pending')) return 'Pending Your Review';
    if (location.pathname.includes('in-process')) return 'In Process';
    if (location.pathname.includes('active')) return 'Active Library';
    if (location.pathname.includes('archived')) return 'Archived Documents';
    return 'Active Library';
  };

  const getStatusColor = () => {
    if (location.pathname.includes('pending')) return '#dbdbdb';
    if (location.pathname.includes('in-process')) return '#a8dba9';
    if (location.pathname.includes('active')) return '#3adb4d';
    if (location.pathname.includes('archived')) return '#3f72b3';
    return '#3adb4d'; // default to active color
  };

  const getTextColor = () => {
    // Use light text for dark backgrounds
    return location.pathname.includes('archived') ? 'white' : 'black';
  };

  // Add this function to get the appropriate icon
  const getCurrentIcon = () => {
    if (location.pathname.includes('pending')) {
      return <FontAwesomeIcon icon={faUserMagnifyingGlass} className="me-2" />;
    }
    if (location.pathname.includes('in-process')) {
      return <FontAwesomeIcon icon={faGears} className="me-2" />;
    }
    if (location.pathname.includes('active')) {
      return <FontAwesomeIcon icon={faFlask} className="me-2" />;
    }
    if (location.pathname.includes('archived')) {
      return <FontAwesomeIcon icon={faArchive} className="me-2" />;
    }
    return <FontAwesomeIcon icon={faFlask} className="me-2" />; // default to active icon
  };

  // Add this constant at the top of your component
  const DROPDOWN_WIDTH = '16rem'; // Adjust this value as needed

  // Desktop version
  const desktopView = (
    <div className="d-none d-md-flex lifecycle-bar flex-shrink-0 p-2 align-items-center">
      <div className="d-flex flex-grow-1">
        <div
          className={`${getClassName('pending')} mr-2 pendingTab`}
          onClick={() => handleDivClick('pending')}
          style={{ cursor: 'pointer', display: 'inline-block', zIndex: 2 }}
        >
          <FontAwesomeIcon icon={faUserMagnifyingGlass} className="me-2" />
          Pending Your Review{' '}
          <Badge bg="light" text="dark">
            {counts.pending}
          </Badge>
        </div>
        <div
          className={`${getClassName('in-process')} mr-2 inprocessTab`}
          onClick={() => handleDivClick('in-process')}
          style={{ cursor: 'pointer', display: 'inline-block', zIndex: 1 }}
        >
          <FontAwesomeIcon icon={faGears} className="mx-2" />
          In Process{' '}
          <Badge bg="light" text="dark">
            {counts.inprocess}
          </Badge>
        </div>
        <div
          className={`${getClassName('active')} mr-2 activeTab`}
          onClick={() => handleDivClick('active')}
          style={{ cursor: 'pointer', display: 'inline-block', zIndex: 0 }}
        >
          <FontAwesomeIcon icon={faFlask} className="mx-2" />
          Active Library{' '}
          <Badge bg="light" text="dark">
            {counts.active}
          </Badge>
        </div>
        <div
          className={`${getClassName('archived')} mr-2 archivedTab text-light ms-2`}
          onClick={() => handleDivClick('archived')}
          style={{ cursor: 'pointer', display: 'inline-block' }}
        >
          <FontAwesomeIcon icon={faArchive} className="me-2" />
          Archived Documents{' '}
          <Badge bg="light" text="dark">
            {counts.archived}
          </Badge>
        </div>
        <div className="ms-2">
          <SearchControl
            localSearchType={localSearchType}
            handleSearchTypeChange={handleSearchTypeChange}
            localSearchTerm={localSearchTerm}
            handleSearchInput={handleSearchInput}
            clearSearch={clearSearch}
          />
        </div>
      </div>
    </div>
  );

  // Mobile version
  const mobileView = (
    <div className="d-md-none flex-shrink-0 p-2 bg-light">
      <div className="d-flex flex-column gap-2">
        <Dropdown className="lifecycle-dropdown" show={isOpen} onToggle={(isOpen) => setIsOpen(isOpen)}>
          <Dropdown.Toggle
            className="text-start d-flex justify-content-between align-items-center"
            style={{
              backgroundColor: getStatusColor(),
              borderColor: getStatusColor(),
              color: getTextColor(),
              width: DROPDOWN_WIDTH,
            }}
          >
            <span className="d-flex align-items-center text-truncate">
              {getCurrentIcon()}
              <span className="text-truncate">{getCurrentTab()}</span>
            </span>
            <Badge bg="light" text="dark">
              {counts[location.pathname.split('/').pop().replace('-', '')]}
            </Badge>
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ width: DROPDOWN_WIDTH }}>
            <Dropdown.Item
              onClick={() => handleDivClick('pending')}
              active={location.pathname.includes('pending')}
              style={
                location.pathname.includes('pending')
                  ? {
                      backgroundColor: '#dbdbdb',
                      color: 'black',
                    }
                  : {}
              }
              className="d-flex justify-content-between align-items-center"
            >
              <div className="d-flex align-items-center text-truncate">
                <FontAwesomeIcon icon={faUserMagnifyingGlass} className="me-2" />
                <span className="text-truncate">Pending Your Review</span>
              </div>
              <Badge bg="light" text="dark" className="ms-2 flex-shrink-0">
                {counts.pending}
              </Badge>
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() => handleDivClick('in-process')}
              active={location.pathname.includes('in-process')}
              style={
                location.pathname.includes('in-process')
                  ? {
                      backgroundColor: '#a8dba9',
                      color: 'black',
                    }
                  : {}
              }
              className="d-flex justify-content-between align-items-center"
            >
              <div className="d-flex align-items-center text-truncate">
                <FontAwesomeIcon icon={faGears} className="me-2" />
                <span className="text-truncate">In Process</span>
              </div>
              <Badge bg="light" text="dark" className="ms-2 flex-shrink-0">
                {counts.inprocess}
              </Badge>
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() => handleDivClick('active')}
              active={location.pathname.includes('active')}
              style={
                location.pathname.includes('active')
                  ? {
                      backgroundColor: '#3adb4d',
                      color: 'black',
                    }
                  : {}
              }
              className="d-flex justify-content-between align-items-center"
            >
              <div className="d-flex align-items-center text-truncate">
                <FontAwesomeIcon icon={faFlask} className="me-2" />
                <span className="text-truncate">Active Library</span>
              </div>
              <Badge bg="light" text="dark" className="ms-2 flex-shrink-0">
                {counts.active}
              </Badge>
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() => handleDivClick('archived')}
              active={location.pathname.includes('archived')}
              style={
                location.pathname.includes('archived')
                  ? {
                      backgroundColor: '#3f72b3',
                      color: 'white',
                    }
                  : {}
              }
              className="d-flex justify-content-between align-items-center"
            >
              <div className="d-flex align-items-center text-truncate">
                <FontAwesomeIcon icon={faArchive} className="me-2" />
                <span className="text-truncate">Archived Documents</span>
              </div>
              <Badge bg="light" text="dark" className="ms-2 flex-shrink-0">
                {counts.archived}
              </Badge>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <SearchControl
          localSearchType={localSearchType}
          handleSearchTypeChange={handleSearchTypeChange}
          localSearchTerm={localSearchTerm}
          handleSearchInput={handleSearchInput}
          clearSearch={clearSearch}
        />
      </div>
    </div>
  );

  return (
    <>
      {desktopView}
      {mobileView}
    </>
  );
};

export default LifecycleBar;
