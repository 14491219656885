import React from 'react';
import { Button, Card } from 'react-bootstrap';

const CANONICAL_PROPERTIES = [
  'aka',
  'casNumbers',
  'fileHash',
  'hazardStatements',
  'hazardsNOC',
  'manAddress',
  'manName',
  'msds',
  'photos',
  'pictograms',
  'precautionaryStatements',
  'productIdentifier',
  'sdsDate',
  'signalWord',
  'smarttags',
  'translations',
  'upc',
  'customAttributes',
  'manEmergencyPhone',
];

export const SdsVersionEdit = ({ attribute, onChange }) => {
  const record = attribute.value;

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const handleMakeActive = (revisionIndex) => {
    // Create a copy of the current record
    const updatedRecord = { ...record };
    const revisionToActivate = record.revisionProperties[revisionIndex];

    // Create a new revision from current canonical properties
    const newRevision = {};
    CANONICAL_PROPERTIES.forEach((prop) => {
      if (record[prop] !== undefined) {
        newRevision[prop] = record[prop];
      }
    });

    // Update revisionHashes array
    const oldCanonicalHash = record.fileHash;
    updatedRecord.revisionHashes = record.revisionHashes || [];
    const hashIndex = updatedRecord.revisionHashes.indexOf(revisionToActivate.fileHash);
    if (hashIndex !== -1) {
      updatedRecord.revisionHashes[hashIndex] = oldCanonicalHash;
    } else {
      updatedRecord.revisionHashes.push(oldCanonicalHash);
    }

    // Update canonical properties with revision properties
    CANONICAL_PROPERTIES.forEach((prop) => {
      if (revisionToActivate[prop] !== undefined) {
        updatedRecord[prop] = revisionToActivate[prop];
      }
    });

    // Update revisionProperties array
    updatedRecord.revisionProperties = [...(record.revisionProperties || [])];
    updatedRecord.revisionProperties[revisionIndex] = newRevision;

    // Trigger the change
    onChange(attribute.name, updatedRecord);
  };

  const renderVersionCard = (version, date, hash, isCanonical = false, index = -1) => (
    <Card className="mb-3" key={hash}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>
          <strong>{version}</strong>
          {date && <span className="ms-2">({formatDate(date)})</span>}
        </div>
        {!isCanonical && (
          <Button variant="outline-primary" size="sm" onClick={() => handleMakeActive(index)}>
            Make Active
          </Button>
        )}
      </Card.Header>
      <Card.Body>
        <div>
          <strong>File Hash:</strong> {hash}
        </div>
      </Card.Body>
    </Card>
  );

  if (!record) return null;

  return (
    <div className={attribute.editClasses?.component}>
      <label className="form-label">{attribute.label}</label>
      <div>
        {/* Render current canonical version */}
        {renderVersionCard('Current Active Version', record.sdsDate, record.fileHash, true)}

        {/* Render revision versions */}
        {record.revisionProperties?.map((revision, index) =>
          renderVersionCard(`Previous Version ${record.revisionProperties.length - index}`, revision.sdsDate, revision.fileHash, false, index)
        )}
      </div>
    </div>
  );
};

export default SdsVersionEdit;
