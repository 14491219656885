import React, { useState, useCallback } from 'react';
import { useToaster } from './ToasterContext';
import './Toaster.css';

export const Toaster = () => {
  const { toasts, removeToast } = useToaster();
  const [exitingToasts, setExitingToasts] = useState(new Set());

  const handleRemoveToast = useCallback(
    (id) => {
      setExitingToasts((prev) => new Set([...prev, id]));

      // Wait for animation to complete before actually removing
      setTimeout(() => {
        setExitingToasts((prev) => {
          const newSet = new Set(prev);
          newSet.delete(id);
          return newSet;
        });
        removeToast(id);
      }, 300); // Match animation duration
    },
    [removeToast]
  );

  return (
    <div className="toaster-container">
      {toasts.map(({ id, component: ToastComponent }) => {
        // Clone the toast component to inject our custom onClose
        const enhancedToast = React.cloneElement(ToastComponent, {
          onClose: () => handleRemoveToast(id),
        });

        return (
          <div key={id} className={`toast-wrapper ${exitingToasts.has(id) ? 'exiting' : ''}`}>
            {enhancedToast}
          </div>
        );
      })}
    </div>
  );
};
