import React from 'react';
import { Card } from 'react-bootstrap';

const CommunitySearch = () => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Community Database Search</Card.Title>
        <Card.Text>
          This section will allow you to search through our community database of pre-processed SDS documents and add them directly to your system.
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CommunitySearch;
