import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { usePortal } from '../../context/Portal';
import './Portal.css';
import Container from 'react-bootstrap/Container';
import Header from './sub-components/Header';
import Controls from './sub-components/Controls';
import axios from 'axios';
import { useGet } from 'hooks/useGet';
import PinnedDocumentsList from 'components/portal/sub-components/pinned-documents/Pinned-Documents-List';
import PaginatedSDSDocuments from './sub-components/sds-documents/Paginated-SDS-Documents';
import Fuse from 'fuse.js';
import PhotoSwipe from './pdf-viewer/Photoswipe';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';

// Custom hooks
const useHash = () => {
  const [hash, setHash] = useState(() => window.location.hash);

  const hashChangeHandler = useCallback(() => {
    setHash(window.location.hash);
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', hashChangeHandler);
    return () => {
      window.removeEventListener('hashchange', hashChangeHandler);
    };
  }, []);

  const updateHash = useCallback(
    (newHash) => {
      if (newHash !== hash) window.location.hash = newHash;
    },
    [hash]
  );

  return [hash, updateHash];
};

const useDocuments = () => {
  const [pinnedDocuments, setPinnedDocuments] = useState(null);
  const [sdsDocuments, setSdsDocuments] = useState(null);
  const pinnedDocRequest = useGet();
  const sdsDocRequest = useGet();

  useEffect(() => {
    pinnedDocRequest.getData('/portal/pinned-documents');
    sdsDocRequest.getData('/portal/sds-documents');
  }, []);

  useEffect(() => {
    if (pinnedDocRequest.data) setPinnedDocuments(pinnedDocRequest.data);
    if (sdsDocRequest.data) setSdsDocuments(sdsDocRequest.data);
  }, [pinnedDocRequest.data, sdsDocRequest.data]);

  return { pinnedDocuments, sdsDocuments };
};

// Components
const Portal = () => {
  const { portalConfig } = usePortal();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({ locationID: 0, name: 'All Documents' });
  const [pdfRendered, setPdfRendered] = useState(false);
  const [filteredSDSDocuments, setFilteredSDSDocuments] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [userSettings, setUserSettings] = useState({
    internalViewer: false,
    showPhotos: false,
    devices: [],
    camera: null,
  });

  const { pinnedDocuments, sdsDocuments } = useDocuments();
  const [hash, setHash] = useHash();
  const navigate = useNavigate();

  // New: Create search keys array
  const searchKeys = useMemo(() => {
    const baseKeys = ['productIdentifier', 'manName', 'aka'];
    if (portalConfig && portalConfig.portal && portalConfig.portal.attributeSettings) {
      const customKeys = portalConfig.portal.attributeSettings.filter((attr) => attr.searchInPortal).map((attr) => attr.name);
      return [...baseKeys, ...customKeys];
    }
    return baseKeys;
  }, [portalConfig]);

  // New: Process SDS documents to add custom attributes to top level
  const processedSDSDocuments = useMemo(() => {
    if (!sdsDocuments || !portalConfig || !portalConfig.portal || !portalConfig.portal.attributeSettings) {
      return sdsDocuments;
    }

    const searchableAttributes = portalConfig.portal.attributeSettings.filter((attr) => attr.searchInPortal).map((attr) => attr.name);

    return sdsDocuments.map((doc) => {
      const newDoc = { ...doc };
      doc.customAttributes.forEach((attr) => {
        if (searchableAttributes.includes(attr.name)) {
          newDoc[attr.name] = attr.value;
        }
      });
      return newDoc;
    });
  }, [sdsDocuments, portalConfig]);

  const filterSDSDocuments = useMemo(() => {
    let filteredDocuments = processedSDSDocuments;
    let matchData = {};
    if (searchTerm && processedSDSDocuments) {
      const fuse = new Fuse(processedSDSDocuments, {
        keys: searchKeys,
        includeMatches: true,
        threshold: 0.2,
        includeScore: true,
      });
      const searchResults = fuse.search(searchTerm);
      filteredDocuments = searchResults.map((result) => {
        matchData[result.item.recordId] = result.matches;
        return result.item;
      });
    }
    if (filteredDocuments) {
      setTotalResults(filteredDocuments.length);
    } else {
      setTotalResults(0);
    }

    if (selectedLocation.locationID !== 0) {
      filteredDocuments = filteredDocuments.filter((document) => document.locations.includes(selectedLocation.locationID));
    } else {
      setTotalResults(0);
    }
    setFilteredSDSDocuments(filteredDocuments);
    return { filteredDocuments, matchData };
  }, [searchTerm, selectedLocation, processedSDSDocuments, searchKeys]);

  useEffect(() => {
    if (portalConfig) {
      if (hash.includes('#loc:')) {
        const locationID = parseInt(hash.split('loc:')[1]);
        const location = portalConfig.locations.find((location) => location.locationID === locationID);
        if (location) {
          setSelectedLocation(location);
          window.history.replaceState(null, '', window.location.pathname);
        }
      } else if (hash.includes('#view:')) {
        const recordId = parseInt(hash.split('view:')[1]);
        if (recordId) {
          navigate(`/sds/${recordId}`);
        }
      }
    }
  }, [hash, portalConfig, navigate]);

  if (portalConfig) {
    portalConfig.isMobile = window.innerWidth < 768;
    return (
      <>
        <Routes>
          <Route path="/loc/:locID" element={<SelectLocation setSelectedLocation={setSelectedLocation} />} />
          <Route
            path="/pinned/:docID"
            element={
              <RenderDoc
                documents={pinnedDocuments}
                setPdfRendered={setPdfRendered}
                pdfRendered={pdfRendered}
                userSettings={userSettings}
                paramKey="_id"
              />
            }
          />
          <Route
            path="/sds/:recordId/:language?"
            element={
              <RenderDoc
                documents={sdsDocuments}
                setPdfRendered={setPdfRendered}
                pdfRendered={pdfRendered}
                userSettings={userSettings}
                paramKey="recordId"
              />
            }
          />
        </Routes>
        <Container fluid className="flex-column p-0 p-lg-3 p-lg-5 col-12 col-lg-8 offset-lg-2 justify-content-center d-flex masterContainer">
          <Header />
          <Controls
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            userSettings={userSettings}
            setUserSettings={setUserSettings}
          />
          {pinnedDocuments && (
            <PinnedDocumentsList pinnedDocuments={pinnedDocuments} selectedLocation={selectedLocation} userSettings={userSettings} />
          )}
          <PaginatedSDSDocuments
            filteredSDSDocuments={filterSDSDocuments.filteredDocuments}
            matchData={filterSDSDocuments.matchData}
            totalResults={totalResults}
            setSelectedLocation={setSelectedLocation}
            userSettings={userSettings}
            searchTerm={searchTerm}
          />
        </Container>
      </>
    );
  }

  return null;
};

const RenderDoc = ({ documents, setPdfRendered, pdfRendered, userSettings, paramKey }) => {
  const { portalConfig } = usePortal();
  let params = useParams();

  useEffect(() => {
    if (documents) {
      const document = documents.find((doc) => (paramKey === '_id' ? doc._id === params.docID : doc.recordId === parseInt(params.recordId)));
      if (document) {
        if (params.language && document.translations) {
          const translation = document.translations.find((t) => t.language === params.language);
          if (translation) {
            setPdfRendered({ ...document, fileHash: translation.fileHash });
          } else {
            setPdfRendered(document);
          }
        } else {
          setPdfRendered(document);
        }
      }
    }
  }, [documents, params, paramKey, setPdfRendered]);

  if (pdfRendered) {
    return (
      <PhotoSwipe
        pdfRendered={pdfRendered}
        setPdfRendered={setPdfRendered}
        subdomain={portalConfig.clientSubdomain}
        isMobile={portalConfig.isMobile}
        userSettings={userSettings}
      />
    );
  } else {
    return <div>Loading {params[paramKey]}</div>;
  }
};

const SelectLocation = ({ setSelectedLocation }) => {
  const { portalConfig } = usePortal();
  let params = useParams();
  const locationID = parseInt(params.locID);

  useEffect(() => {
    const location = portalConfig.locations.find((location) => location.locationID === locationID);
    if (location) setSelectedLocation(location);
  }, [locationID, portalConfig.locations, setSelectedLocation]);

  return null;
};

export default Portal;
