import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Button, Tabs, Tab, Table, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { StringEdit } from '../../../custom-attributes/String';
import { usePatch } from '../../../../hooks/usePatch';
import { useModal } from '../../../../context/ModalContext';
import { usePortal } from '../../../../context/Portal';
import { useReactTable, getCoreRowModel, getPaginationRowModel, getFilteredRowModel, getSortedRowModel } from '@tanstack/react-table';
import TableHeader from '../../tables/TableHeader';
import TableBody from '../../tables/TableBody';
import { LocationArraySelect } from '../../../custom-attributes/LocationArray';
import { faEdit, faPlus, faSave } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../PowerModal.css';
import { getGroupColumnDefinitions } from './groupColumnDefinitions';
import TableConfig, { useFormattedColumns, getGlobalFilterFn } from '../../tables/TableConfig';
import PaginationComponent from '../../sub-components/Pagination';
import Cookies from 'js-cookie';

// Memoized Locations Tab Component
const LocationsTab = React.memo(({ locations, value, onChange }) => (
  <div className="d-flex flex-column flex-grow-1">
    <div className="location-select-container flex-grow-1">
      <LocationArraySelect value={value} onChange={onChange} className="" />
    </div>
  </div>
));

const EditUserModal = ({ user, onSave, onDelete, modalId, onUserUpdate, users }) => {
  console.log('EditUserModal rendered with users:', {
    usersProvided: !!users,
    usersLength: users?.length,
    usersType: typeof users,
    users,
  });

  const { patchData } = usePatch();
  const { hideModal } = useModal();
  const { portalConfig } = usePortal();
  const [activeTab, setActiveTab] = useState('general');
  const [modalWidth, setModalWidth] = useState(null);
  const formRef = useRef(null);
  const [emailExists, setEmailExists] = useState(false);
  const [groupsPageIndex, setGroupsPageIndex] = useState(0);
  const [groupsPageSize, setGroupsPageSize] = useState(5);
  const [groupsGlobalFilter, setGroupsGlobalFilter] = useState('');
  const [groupsSorting, setGroupsSorting] = useState([]);
  const [groupsColumnVisibility, setGroupsColumnVisibility] = useState({
    permissions: false,
  });

  // Memoize initial state to prevent re-creation on every render
  const [record, setRecord] = useState(useMemo(() => user, []));
  const [showChangePassword, setShowChangePassword] = useState(false);
  const originalRecord = useMemo(() => user, []);

  // Memoize groups and locations
  const groups = useMemo(() => {
    // Ensure we have the permission groups data
    if (!portalConfig?.permissionGroups?.length) {
      console.warn('No permission groups found in portal config');
      return [];
    }
    return portalConfig.permissionGroups;
  }, [portalConfig?.permissionGroups]);

  // Memoize locations
  const locations = useMemo(() => portalConfig?.locations || [], [portalConfig?.locations]);

  // Memoize selectedGroups initial state
  const [selectedGroups, setSelectedGroups] = useState(useMemo(() => (user.groups || []).map((g) => g.toString()), [user.groups]));

  // Memoize handlers
  const handleChange = useCallback((field, value) => {
    setRecord((prev) => {
      if (field === 'groups') {
        const newGroups = value.map((g) => g.toString());
        return { ...prev, groups: newGroups };
      }
      return { ...prev, [field]: value };
    });
  }, []);

  const handleSave = useCallback(() => {
    try {
      onSave?.(record);
      hideModal(modalId);
    } catch (error) {
      console.error('Error in save handler:', error);
    }
  }, [record, hideModal, onSave, modalId]);

  const handleDelete = useCallback(() => {
    onDelete(user);
  }, [user, onDelete]);

  const handleLocationChange = useCallback((locationState) => {
    setRecord((prev) => ({
      ...prev,
      locations: locationState.selected,
    }));
  }, []);

  // Memoize field modification check
  const isFieldModified = useCallback((fieldName) => record[fieldName] !== originalRecord[fieldName], [record, originalRecord]);

  // Memoize changes check
  const hasChanges = useMemo(() => Object.keys(record).some((key) => record[key] !== originalRecord[key]), [record, originalRecord]);

  // Memoize column classes
  const getColumnClass = useCallback(
    (defaultSize) => {
      if (!modalWidth) return defaultSize;
      return modalWidth < 500 ? 'col-12' : defaultSize;
    },
    [modalWidth]
  );

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Check if email exists in active users
  const checkEmailExists = useCallback(
    (email) => {
      console.log('checkEmailExists called with:', {
        email,
        usersProvided: !!users,
        usersLength: users?.length,
        usersType: typeof users,
        users,
      });

      if (!email || !Array.isArray(users)) return false;

      const existingUser = users
        .filter((u) => u.status === 'active')
        .find((u) => u.email?.toLowerCase() === email.toLowerCase() && u._id !== user?._id);

      setEmailExists(!!existingUser);
      return !!existingUser;
    },
    [users, user?._id]
  );

  // Update email check when email changes
  useEffect(() => {
    if (record?.email) {
      checkEmailExists(record.email);
    }
  }, [record?.email, checkEmailExists]);

  // Memoize user fields with dynamic email label
  const userFields = useMemo(
    () => [
      {
        name: 'firstName',
        label: 'First Name',
        type: StringEdit,
        required: true,
        editClasses: { component: getColumnClass('col-6 p-1') },
        value: record?.firstName || '',
      },
      {
        name: 'lastName',
        label: 'Last Name',
        type: StringEdit,
        required: true,
        editClasses: { component: getColumnClass('col-6 p-1') },
        value: record?.lastName || '',
      },
      {
        name: 'email',
        label: emailExists ? (
          <span>
            Email <span style={{ color: 'red' }}>(user exists)</span>
          </span>
        ) : (
          'Email'
        ),
        type: StringEdit,
        required: true,
        editClasses: { component: getColumnClass('col-7 p-1') },
        value: record?.email || '',
      },
      {
        name: 'telephone',
        label: 'Telephone',
        type: StringEdit,
        required: false,
        editClasses: { component: getColumnClass('col-5 p-1') },
        value: record?.telephone || '',
      },
    ],
    [record, getColumnClass, emailExists]
  );

  const handleGroupsPageSizeChange = (newPageSize) => {
    setGroupsPageSize(newPageSize);
    setGroupsPageIndex(0);
  };

  const handleGroupsPageChange = (newPageIndex) => {
    setGroupsPageIndex(newPageIndex);
  };

  // Get base columns and format them
  const baseGroupColumns = useMemo(() => {
    // Get base columns from groupColumnDefinitions
    const baseColumns = getGroupColumnDefinitions()
      // Remove only the actions column
      .filter((col) => col.id !== 'actions')
      // Add enableFiltering where it's missing
      .map((col) => ({
        ...col,
        enableFiltering: col.enableFiltering ?? false,
      }));

    // Prepend the select column
    return [
      {
        id: 'select',
        header: ({ table }) => (
          <input
            type="checkbox"
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            className="form-check-input"
          />
        ),
        cell: ({ row }) => (
          <input type="checkbox" checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} className="form-check-input" />
        ),
        size: 40,
      },
      ...baseColumns,
    ];
  }, []);

  const groupColumns = useFormattedColumns(baseGroupColumns, groupsGlobalFilter);

  const handleGroupsColumnVisibilityChange = (newVisibility) => {
    setGroupsColumnVisibility(newVisibility);
    Cookies.set('groupsTableColumnVisibility', JSON.stringify(newVisibility), { expires: 365 });
  };

  const groupsTable = useReactTable({
    data: groups,
    columns: groupColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      rowSelection: useMemo(
        () =>
          selectedGroups.reduce((acc, groupId) => {
            acc[groupId] = true;
            return acc;
          }, {}),
        [selectedGroups]
      ),
      pagination: { pageIndex: groupsPageIndex, pageSize: groupsPageSize },
      globalFilter: groupsGlobalFilter,
      sorting: groupsSorting,
      columnVisibility: groupsColumnVisibility,
    },
    globalFilterFn: getGlobalFilterFn(groupColumns),
    enableRowSelection: true,
    enableSortingRemoval: false,
    enableMultiSort: false,
    enableColumnResizing: true,
    onSortingChange: setGroupsSorting,
    onColumnVisibilityChange: handleGroupsColumnVisibilityChange,
    getRowId: (row) => row.groupID.toString(),
    onRowSelectionChange: useCallback(
      (updatedSelection) => {
        if (typeof updatedSelection === 'function') {
          const newSelection = updatedSelection(groupsTable.getState().rowSelection);
          const newSelectedGroups = Object.keys(newSelection).filter((key) => newSelection[key]);
          setSelectedGroups(newSelectedGroups);
          handleChange('groups', newSelectedGroups);
        } else {
          const newSelectedGroups = Object.keys(updatedSelection).filter((key) => updatedSelection[key]);
          setSelectedGroups(newSelectedGroups);
          handleChange('groups', newSelectedGroups);
        }
      },
      [handleChange]
    ),
    pageCount: Math.ceil(groups.length / groupsPageSize),
  });

  // Memoize badges
  const getGroupBadges = useMemo(() => {
    const assignedGroups = (record.groups || []).map((groupId) => groups.find((g) => String(g.groupID) === String(groupId))).filter(Boolean);

    return (
      <div className="d-flex flex-wrap gap-1 align-items-center">
        {assignedGroups.length === 0 ? (
          <div className="text-muted">No groups assigned</div>
        ) : (
          assignedGroups.map((group) => (
            <Badge key={group.groupID} bg="secondary" style={{ margin: '0 2px' }}>
              {group.name}
            </Badge>
          ))
        )}
        <FontAwesomeIcon
          icon={faEdit}
          className="ms-2 text-secondary"
          style={{ cursor: 'pointer' }}
          onClick={() => setActiveTab('groups')}
          size="lg"
        />
      </div>
    );
  }, [record.groups, groups]);

  const getLocationBadges = useMemo(() => {
    const assignedLocations = (record.locations || [])
      .map((locationId) => locations.find((l) => String(l.locationID) === String(locationId)))
      .filter(Boolean);

    return (
      <div className="d-flex flex-wrap gap-1 align-items-center">
        {assignedLocations.length === 0 ? (
          <div className="text-muted">No locations assigned</div>
        ) : (
          assignedLocations.map((location) => (
            <Badge key={location.locationID} bg={location.color} style={{ margin: '0 2px' }}>
              {location.name}
            </Badge>
          ))
        )}
        <FontAwesomeIcon
          icon={faEdit}
          className="ms-2 text-secondary"
          style={{ cursor: 'pointer' }}
          onClick={() => setActiveTab('locations')}
          size="lg"
        />
      </div>
    );
  }, [record.locations, locations]);

  // Only track modal width changes
  useEffect(() => {
    if (!formRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setModalWidth(entry.contentRect.width);
      }
    });

    resizeObserver.observe(formRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  // Check if this is a new user (no _id means it's a new user)
  const isNewUser = !user?._id;

  // Update form validation to include email validation
  const isValidForm = useMemo(() => {
    // Check if email is valid
    const validEmail = record?.email?.trim() && isValidEmail(record.email);

    // If email exists for another user, form is not valid
    if (emailExists) return false;

    // For new users, check all required fields
    if (isNewUser) {
      return validEmail && record?.firstName?.trim() && record?.lastName?.trim() && record?.groups?.length > 0;
    }

    // For existing users, only check if the changed fields are valid
    if (!hasChanges) return false;

    // If password is being changed, ensure it's not empty
    if (showChangePassword && !record?.newPassword?.trim()) {
      return false;
    }

    // Check if any modified fields are required and empty
    const modifiedFields = Object.keys(record).filter((key) => record[key] !== originalRecord[key]);
    const requiredFields = userFields.filter((field) => field.required).map((field) => field.name);

    return modifiedFields.every((field) => {
      if (requiredFields.includes(field)) {
        if (field === 'email') return validEmail;
        return record[field]?.toString().trim();
      }
      return true;
    });
  }, [record, isNewUser, hasChanges, showChangePassword, originalRecord, userFields, emailExists]);

  // Update tooltip message to include email validation
  const getTooltipMessage = useMemo(() => {
    if (emailExists) {
      return 'Email address already exists for another user';
    }

    if (record?.email && !isValidEmail(record.email)) {
      return 'Please enter a valid email address';
    }

    if (isNewUser) {
      const missing = [];
      if (!record?.firstName?.trim()) missing.push('First Name');
      if (!record?.lastName?.trim()) missing.push('Last Name');
      if (!record?.email?.trim()) missing.push('Email');
      if (!record?.groups?.length) missing.push('Groups');
      return missing.length ? `Required fields missing: ${missing.join(', ')}` : '';
    }

    if (!hasChanges) return 'No changes made';

    if (showChangePassword && !record?.newPassword?.trim()) {
      return 'New password is required';
    }

    const modifiedFields = Object.keys(record).filter((key) => record[key] !== originalRecord[key]);
    const requiredFields = userFields.filter((field) => field.required).map((field) => field.name);
    const missingRequired = modifiedFields.filter((field) => requiredFields.includes(field) && !record[field]?.toString().trim());

    return missingRequired.length ? `Required fields missing: ${missingRequired.join(', ')}` : '';
  }, [isNewUser, record, hasChanges, showChangePassword, originalRecord, userFields, emailExists]);

  // Memoize location array select value
  const locationSelectValue = useMemo(
    () => ({
      selected: record.locations || [],
      indeterminate: [],
    }),
    [record.locations]
  );

  return (
    <>
      <div className="row flex-grow-1 d-flex flex-column overflow-auto" ref={formRef}>
        <div className="col-12 flex-grow-1 d-flex flex-column">
          <Tabs activeKey={activeTab} onSelect={setActiveTab} className="mb-3">
            <Tab eventKey="general" title="General">
              <div className="d-flex flex-column flex-grow-1 overflow-y">
                <div className="row p-2">
                  {userFields.map((field) => {
                    const isModified = isFieldModified(field.name);
                    const fieldWithValue = {
                      ...field,
                      editClasses: {
                        ...field.editClasses,
                        input: `${field.editClasses.input || ''} ${isModified ? 'form-control unsaved-field' : ''}`.trim(),
                      },
                    };
                    return <field.type key={field.name} attribute={fieldWithValue} onChange={handleChange} />;
                  })}
                </div>
                {!isNewUser && (
                  <div className="row">
                    <div className="col-12 pt-2">
                      <Button variant="outline-info" onClick={() => setShowChangePassword(!showChangePassword)}>
                        {showChangePassword ? '▲ Change Password' : '▼ Change Password'}
                      </Button>
                    </div>
                    {showChangePassword && (
                      <div className="col-12">
                        <StringEdit
                          attribute={{
                            name: 'newPassword',
                            label: 'New Password',
                            type: StringEdit,
                            required: true,
                            editClasses: { component: 'col-12' },
                            value: record?.newPassword || '',
                          }}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className="row mt-3">
                  <div className="col-12 row">
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label fw-bold">
                        Assigned Groups <span style={{ color: 'red' }}>*</span>
                      </label>
                      {getGroupBadges}
                    </div>
                    <div className="mb-3 col-12 col-md-6">
                      <label className="form-label fw-bold">Assigned Locations</label>
                      {getLocationBadges}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="groups" title="Groups">
              <div className="d-flex flex-column flex-grow-1">
                <TableConfig
                  table={groupsTable}
                  globalFilter={groupsGlobalFilter}
                  setGlobalFilter={setGroupsGlobalFilter}
                  columnVisibility={groupsColumnVisibility}
                  onColumnVisibilityChange={handleGroupsColumnVisibilityChange}
                  title="Available Groups"
                  totalCount={groups.length}
                />
                <div className="table-responsive flex-grow-1">
                  <Table striped bordered hover size="sm">
                    <TableHeader headerGroups={groupsTable.getHeaderGroups()} />
                    <TableBody rows={groupsTable.getRowModel().rows} />
                  </Table>
                </div>
                <div className="mt-2">
                  <PaginationComponent
                    currentPage={groupsTable.getState().pagination.pageIndex + 1}
                    totalPages={groupsTable.getPageCount()}
                    pageSize={groupsTable.getState().pagination.pageSize}
                    totalEntries={groups.length}
                    onPageChange={handleGroupsPageChange}
                    previousPage={() => handleGroupsPageChange(groupsTable.getState().pagination.pageIndex - 1)}
                    nextPage={() => handleGroupsPageChange(groupsTable.getState().pagination.pageIndex + 1)}
                    onPageSizeChange={handleGroupsPageSizeChange}
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="locations" title="Locations">
              <LocationsTab locations={locations} value={locationSelectValue} onChange={handleLocationChange} />
            </Tab>
          </Tabs>
        </div>
      </div>
      <div className="manual-modal-footer">
        <div className="d-flex justify-content-end w-100">
          <div>
            <Button
              variant={isValidForm ? 'success' : 'secondary'}
              className="text-light"
              onClick={isValidForm ? handleSave : undefined}
              title={!isValidForm ? getTooltipMessage : ''}
              style={{ cursor: !isValidForm ? 'help' : 'pointer' }}
            >
              {isNewUser ? (
                <>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Create User
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faSave} className="me-2" />
                  Save Changes
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(EditUserModal);
