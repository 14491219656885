const permissionDefinitions = [
  {
    permissionName: 'addChemicals',
    permissionDescription: 'Allows users to request new chemicals for the library',
  },
  {
    permissionName: 'adminArea',
    permissionDescription: 'Can Login to the Admin area',
  },
  {
    permissionName: 'assignLocations',
    permissionDescription: 'Allows user to assign a location for a chemical',
    removes: ['restrictAssignLoc'],
    requires: ['adminArea'],
  },
  {
    permissionName: 'billingInfo',
    permissionDescription: 'Allows for viewing and changing parameters associated with billing.',
    requires: ['adminArea'],
  },
  {
    permissionName: 'deleteChemicals',
    permissionDescription: 'Allows user to delete chemicals from database.',
    requires: ['adminArea'],
  },
  {
    permissionName: 'editGroups',
    permissionDescription: 'Allows user to make changes to User Groups',
    requires: ['adminArea'],
  },
  {
    permissionName: 'editLocations',
    permissionDescription: 'Can edit locations',
    removes: ['restrictEditLoc'],
    requires: ['adminArea'],
  },
  {
    permissionName: 'editMetaData',
    permissionDescription: 'Allows user to edit the meta data for each SDS',
    requires: ['adminArea'],
  },
  {
    permissionName: 'editUsers',
    permissionDescription: 'Can edit other users',
    requires: ['adminArea'],
  },
  {
    permissionName: 'globalConfig',
    permissionDescription: 'Allows for updating and modifying global parameters displayed in the admin area and the employee portal',
    requires: ['adminArea'],
  },
  {
    permissionName: 'restrictAssignLoc',
    permissionDescription: 'Allows user to assign chemicals only to locations they are assigned to.',
    removes: ['assignLocations'],
  },
  {
    permissionName: 'restrictEditLoc',
    permissionDescription: 'Allows user to only edit locations they are assigned to',
    removes: ['editLocations'],
  },
  {
    permissionName: 'selfManageOnly',
    permissionDescription: 'Forces all chemicals added by this user to be self managed. User has no access to Auto-pilot.',
  },
  {
    permissionName: 'smartTagEditor',
    permissionDescription: 'Can add, delete, and update smart tags.',
    requires: ['adminArea'],
  },
  {
    permissionName: 'translate',
    permissionDescription: 'Allows user to request new translations of SDS documents.',
  },
  {
    permissionName: 'viewLogs',
    permissionDescription: 'Allows user to see logs',
    requires: ['adminArea'],
  },
];

export default permissionDefinitions;
