import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useReactTable, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';
import { Table } from 'react-bootstrap';
import { getColumnDefinitions } from './columnDefinitions';
import ColumnVisibilityToggle from './ColumnVisibilityToggle';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import PaginationComponent from '../sub-components/Pagination';
import WithSelected from './sub-components/WithSelected';
import './LifecycleTable.css';
import Cookies from 'js-cookie';

const LifecycleTable = ({
  data = [],
  tableType,
  pageIndex,
  pageSize,
  setPageIndex,
  setPageSize,
  onAddFilter,
  columnVisibility = {},
  setColumnVisibility,
  rowSelection = {},
  setRowSelection,
  sorting = [],
  onSortingChange,
}) => {
  const columns = useMemo(() => {
    const columnDefs = getColumnDefinitions(tableType, onAddFilter);
    // Set enableSorting to false by default for all columns that don't explicitly enable it
    const updatedColumns = columnDefs.map((column) => ({
      ...column,
      enableSorting: column.enableSorting === true,
    }));
    // Preserve the PortalComponent and other properties
    updatedColumns.PortalComponent = columnDefs.PortalComponent;
    updatedColumns.getDefaultVisibility = columnDefs.getDefaultVisibility;
    return updatedColumns;
  }, [tableType, onAddFilter]);

  const ColumnsWithPortal = columns.PortalComponent;

  // Initialize defaults immediately when columns are created
  const initializeDefaults = useCallback(() => {
    const initialVisibility = {};
    columns.forEach((column) => {
      const defaultVisible = column.defaultVisible ?? false;
      initialVisibility[column.id] = defaultVisible;
      console.log(`Initializing column ${column.id}: defaultVisible = ${defaultVisible}`);
    });
    return initialVisibility;
  }, [columns]);

  // Modified effect to properly handle initialization
  useEffect(() => {
    const cookieKey = `columnVisibility_${tableType}`;

    // Check if we need to initialize
    if (!columnVisibility || Object.keys(columnVisibility).length === 0) {
      const savedVisibility = Cookies.get(cookieKey);

      if (savedVisibility) {
        try {
          const parsedVisibility = JSON.parse(savedVisibility);
          setColumnVisibility(parsedVisibility);
        } catch (error) {
          console.error('Error parsing column visibility cookie:', error);
          const defaults = initializeDefaults();
          setColumnVisibility(defaults);
        }
      } else {
        const defaults = initializeDefaults();
        setColumnVisibility(defaults);
      }
    }
  }, [tableType, columns]);

  const handlePageChange = useCallback(
    (newPageIndex) => {
      setPageIndex(newPageIndex);
    },
    [setPageIndex]
  );

  const handlePageSizeChange = useCallback(
    (newPageSize) => {
      setPageSize(newPageSize);
      setPageIndex(0); // Reset to first page when changing page size
    },
    [setPageSize, setPageIndex]
  );

  const handleRowSelectionChange = useCallback(
    (updater) => {
      setRowSelection(tableType, updater);
    },
    [setRowSelection, tableType]
  );

  const getSelectedRecords = useCallback(() => {
    return data.filter((record) => rowSelection[record._id]);
  }, [data, rowSelection]);

  const handleColumnVisibilityChange = useCallback(
    (newVisibility) => {
      setColumnVisibility(newVisibility);
      const cookieKey = `columnVisibility_${tableType}`;
      Cookies.set(cookieKey, JSON.stringify(newVisibility), { expires: 365 });
      // Verify cookie was set
      const savedCookie = Cookies.get(cookieKey);
    },
    [tableType, setColumnVisibility]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: (updater) => {
      console.log('LifecycleTable: onSortingChange called', {
        currentSorting: sorting,
        tableType,
        updater,
      });
      if (onSortingChange) {
        onSortingChange(updater);
      }
    },
    state: {
      rowSelection: rowSelection || {},
      columnVisibility: columnVisibility || {},
      sorting: sorting || [],
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    enableMultiSort: false,
    enableColumnResizing: true,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    getRowId: (row) => row._id,
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    pageCount: Math.ceil((data?.length || 0) / pageSize),
  });

  // Get table rows
  const tableRows = table.getRowModel().rows;

  // Get sorted and paginated data
  const paginatedData = useMemo(() => {
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    return tableRows.slice(start, end);
  }, [tableRows, pageIndex, pageSize]);

  useEffect(() => {
    console.log('LifecycleTable render:', {
      tableType,
      dataLength: data?.length,
      pageIndex,
      pageSize,
      rowsLength: tableRows.length,
      paginatedLength: paginatedData.length,
    });
  }, [data, pageIndex, pageSize, tableType, tableRows, paginatedData]);

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="table-wrapper-new d-flex flex-column flex-grow-1">
      <div className="table-container-new d-flex flex-column flex-grow-1">
        <div className="d-flex justify-content-end flex-shrink-0 me-3 pb-3" style={{ marginTop: '-4rem' }}>
          <ColumnVisibilityToggle
            columns={table.getAllLeafColumns()}
            columnVisibility={columnVisibility}
            onColumnVisibilityChange={handleColumnVisibilityChange}
          />
        </div>
        <div className="table-responsive flex-fill overflow-auto mx-2" style={{ height: '50vh' }}>
          <Table striped bordered hover className="flex-grow-1">
            <TableHeader headerGroups={table.getHeaderGroups()} />
            <TableBody rows={paginatedData} />
          </Table>
        </div>

        <div className="sticky-pagination-new d-flex justify-content-between align-items-center shadow-lg flex-shrink-0">
          <WithSelected rowSelection={rowSelection} tableType={tableType} getSelectedRecords={getSelectedRecords} />
          <PaginationComponent
            currentPage={pageIndex + 1}
            totalPages={Math.ceil(data.length / pageSize)}
            pageSize={pageSize}
            totalEntries={data.length}
            onPageChange={handlePageChange}
            previousPage={() => handlePageChange(pageIndex - 1)}
            nextPage={() => handlePageChange(pageIndex + 1)}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
      <ColumnsWithPortal />
    </div>
  );
};

export default LifecycleTable;
