import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { usePortal, usePermissions } from '../../../context/Portal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersGear, faFileCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const NavigationBar = () => {
  const { portalConfig } = usePortal();
  const logoRelativeUrl = portalConfig.portal.dashboardLogo;
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  // Function to get the correct logo URL based on the environment
  const getLogoUrl = () => {
    if (process.env.NODE_ENV === 'production') {
      return logoRelativeUrl;
    } else {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://api.dss2.center';
      const subdomain = window.location.hostname.split('.')[0];
      return `${apiBaseUrl}/resources/${subdomain}/${logoRelativeUrl}`;
    }
  };

  const logoUrl = getLogoUrl();

  // Function to check if the file is a JPG or JPEG
  const isJpg = (url) => {
    return url.toLowerCase().endsWith('.jpg') || url.toLowerCase().endsWith('.jpeg');
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="flex-shrink-0">
      <Container fluid>
        {/* Mobile View */}
        <div className="d-flex d-lg-none w-100 align-items-center">
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="order-1" />
          <Navbar.Brand href="#home" className="order-2 ms-auto">
            {logoUrl && (
              <div className="logo-container" style={{ position: 'relative', display: 'inline-block' }}>
                <img
                  src={logoUrl}
                  alt="Dashboard Logo"
                  className="d-inline-block align-top"
                  style={{
                    filter: isJpg(logoUrl) ? 'none' : 'brightness(0) invert(1)',
                    maxHeight: '30px',
                  }}
                />
              </div>
            )}
          </Navbar.Brand>
        </div>

        {/* Desktop View */}
        <div className="d-none d-lg-flex align-items-center">
          <Navbar.Brand href="#home" className="me-4">
            {logoUrl && (
              <div className="logo-container" style={{ position: 'relative', display: 'inline-block' }}>
                <img
                  src={logoUrl}
                  alt="Dashboard Logo"
                  className="d-inline-block align-top me-2"
                  style={{
                    filter: isJpg(logoUrl) ? 'none' : 'brightness(0) invert(1)',
                    maxHeight: '30px',
                  }}
                />
              </div>
            )}
            <strong>SDS Management</strong>
          </Navbar.Brand>
        </div>

        {/* Mobile Menu Items */}
        <Navbar.Collapse id="basic-navbar-nav" className="d-lg-none">
          <Nav className="">
            {hasPermission('addChemicals') && (
              <Nav.Link href="#add-sds">
                <FontAwesomeIcon icon={faFileCirclePlus} className="me-2" />
                Add New Chemicals
              </Nav.Link>
            )}
            {hasPermission('editLocations') && <Nav.Link href="#chemicals">Edit Locations</Nav.Link>}
            <NavDropdown title="Tools" id="reports-dropdown">
              <NavDropdown.Item href="#reports">Reports</NavDropdown.Item>
              <NavDropdown.Item href="#smarttags">Smart Tags</NavDropdown.Item>
              <NavDropdown.Item href="#logs">Logs</NavDropdown.Item>
              <NavDropdown.Item href="#stats">Statistics</NavDropdown.Item>
              {hasPermission('editUsers') && (
                <NavDropdown.Item href="#users">
                  <FontAwesomeIcon icon={faUsersGear} className="me-2" />
                  User Administration
                </NavDropdown.Item>
              )}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
