import React from 'react';
import { usePortal } from '../../../context/Portal';

const Header = () => {
  const { portalConfig } = usePortal();
  const logoStyle = {
    maxWidth: '50%',
    textAlign: 'center',
    height: 'auto',
    maxHeight: '15vh',
  };

  let logo = <h1>{portalConfig.clientName}</h1>;
  if (portalConfig.portal?.customerLogo) {
    const logoUrl =
      process.env.REACT_APP_DEV === 'true'
        ? `${process.env.REACT_APP_API_URL}/resources/${portalConfig.clientSubdomain}/${portalConfig.portal.customerLogo}`
        : `${process.env.REACT_APP_API_URL}/resources/${portalConfig.portal.customerLogo}`;

    logo = <img style={logoStyle} alt={portalConfig.clientName} src={logoUrl} />;
  }
  return (
    <header className="mb-3 d-flex justify-content-center flex-wrap">
      {logo}
      <h1 className="titleLine flex-grow-1 align-self-center">Safety Data Sheet Library</h1>
    </header>
  );
};

export default Header;
