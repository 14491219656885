import React from 'react';
import { flexRender } from '@tanstack/react-table';

const TableBody = ({ rows }) => {
  if (!rows || rows.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan="100%" className="text-center">
            No data available
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {rows.map((row) => (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <td
              key={cell.id}
              className="align-middle"
              style={{
                textAlign: cell.column.columnDef.meta?.align || cell.column.columnDef.textAlign || 'left',
                ...(cell.column.columnDef.styleOverride || {}),
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
