import React from 'react';

const PDFViewer = React.memo(
  ({ previewURL }) => {
    if (!previewURL) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 className="text-light">
            <i className="fa-duotone fa-loader fa-spin-pulse fa-2xl" />
          </h1>
          <br />
          <p>Loading Document</p>
        </div>
      );
    }

    return (
      <div
        className="pdf-viewer"
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <iframe
          src={previewURL}
          title="PDF Document"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.previewURL === nextProps.previewURL
);

export default PDFViewer;
