import React, { useState, useMemo, useCallback } from 'react';
import { Button, Table } from 'react-bootstrap';
import { StringEdit } from '../../../custom-attributes/String';
import { useReactTable, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table';
import TableHeader from '../../tables/TableHeader';
import TableBody from '../../tables/TableBody';
import ColumnVisibilityToggle from '../../tables/ColumnVisibilityToggle';
import PaginationComponent from '../../sub-components/Pagination';
import permissionDefinitions from './permissionDefinitions';
import Cookies from 'js-cookie';
import '../PowerModal.css';

const EditGroupModal = ({ group, onSave, onDelete }) => {
  // Pagination and visibility states
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const saved = Cookies.get('permissionTableColumnVisibility');
    return saved ? JSON.parse(saved) : {};
  });

  // Original state handling
  const originalRecord = useMemo(
    () => ({
      name: group?.name || '',
      description: group?.description || '',
      permissions: group?.permissions || [],
    }),
    [group]
  );

  const [record, setRecord] = useState(originalRecord);

  const handleChange = useCallback((field, value) => {
    setRecord((prev) => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  // Pagination handlers
  const handlePageSizeChange = useCallback((newPageSize) => {
    setPageSize(newPageSize);
    setPageIndex(0);
  }, []);

  const handlePageChange = useCallback((newPageIndex) => {
    setPageIndex(newPageIndex);
  }, []);

  // Column visibility handler
  const handleColumnVisibilityChange = useCallback((newVisibility) => {
    setColumnVisibility(newVisibility);
    Cookies.set('permissionTableColumnVisibility', JSON.stringify(newVisibility), { expires: 365 });
  }, []);

  const isFieldModified = useCallback(
    (fieldName) => {
      if (Array.isArray(record[fieldName])) {
        return JSON.stringify(record[fieldName]) !== JSON.stringify(originalRecord[fieldName]);
      }
      return record[fieldName] !== originalRecord[fieldName];
    },
    [record, originalRecord]
  );

  const hasChanges = useMemo(() => {
    return Object.keys(record).some((key) => {
      if (Array.isArray(record[key])) {
        return JSON.stringify(record[key]) !== JSON.stringify(originalRecord[key]);
      }
      return record[key] !== originalRecord[key];
    });
  }, [record, originalRecord]);

  const handleSubmit = useCallback(
    (e) => {
      e?.preventDefault();
      console.log('EditGroupModal handleSubmit called');

      const groupData = {
        groupID: group?.groupID,
        name: record.name,
        description: record.description,
        permissions: record.permissions || [],
      };

      console.log('Calling onSave with:', groupData);
      onSave(groupData);
    },
    [group, record, onSave]
  );

  const handleDelete = useCallback(() => {
    if (window.confirm('Are you sure you want to delete this group?')) {
      onDelete(group);
    }
  }, [group, onDelete]);

  const groupFields = useMemo(
    () => [
      {
        name: 'name',
        label: 'Name',
        type: StringEdit,
        required: true,
        editClasses: { component: 'col-12 px-1' },
        helpText: 'The name of the group',
        value: record.name,
        isModified: isFieldModified('name'),
      },
      {
        name: 'description',
        label: 'Description',
        type: StringEdit,
        required: false,
        editClasses: { component: 'col-12 px-1' },
        helpText: 'A description of the group',
        value: record.description,
        isModified: isFieldModified('description'),
      },
    ],
    [record, isFieldModified]
  );

  // Process permissions helper function
  const processPermissions = useCallback(
    (selectedPermissions) => {
      // Find newly selected permissions by comparing with current permissions
      const currentPermissions = record.permissions;
      const newlySelected = selectedPermissions.filter((p) => !currentPermissions.includes(p));

      // Start with the selected permissions
      let finalPermissions = [...selectedPermissions];

      // Process newly selected permissions
      newlySelected.forEach((permission) => {
        const def = permissionDefinitions.find((p) => p.permissionName === permission);

        // Handle removes
        if (def?.removes?.length) {
          finalPermissions = finalPermissions.filter((p) => !def.removes.includes(p));
        }

        // Handle requires
        if (def?.requires?.length) {
          def.requires.forEach((requiredPerm) => {
            if (!finalPermissions.includes(requiredPerm)) {
              finalPermissions.push(requiredPerm);
            }
          });
        }
      });

      // Check if any permission in finalPermissions has requirements
      let hasNewRequirements;
      do {
        hasNewRequirements = false;
        finalPermissions.forEach((permission) => {
          const def = permissionDefinitions.find((p) => p.permissionName === permission);
          if (def?.requires?.length) {
            def.requires.forEach((requiredPerm) => {
              if (!finalPermissions.includes(requiredPerm)) {
                finalPermissions.push(requiredPerm);
                hasNewRequirements = true;
              }
            });
          }
        });
      } while (hasNewRequirements);

      return finalPermissions;
    },
    [record.permissions]
  );

  // Handle permission changes
  const handlePermissionChange = useCallback(
    (updatedSelection) => {
      if (typeof updatedSelection === 'function') {
        const rowSelection = record.permissions.reduce((acc, permission) => {
          acc[permission] = true;
          return acc;
        }, {});
        const newSelection = updatedSelection(rowSelection);
        const selectedPermissions = Object.keys(newSelection).filter((key) => newSelection[key]);
        handleChange('permissions', processPermissions(selectedPermissions));
      } else {
        const selectedPermissions = Object.keys(updatedSelection).filter((key) => updatedSelection[key]);
        handleChange('permissions', processPermissions(selectedPermissions));
      }
    },
    [record.permissions, handleChange, processPermissions]
  );

  // Table configuration
  const permissionsTable = useReactTable({
    data: permissionDefinitions,
    columns: useMemo(
      () => [
        {
          id: 'select',
          header: ({ table }) => (
            <input
              type="checkbox"
              checked={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              className="form-check-input"
            />
          ),
          cell: ({ row }) => (
            <input type="checkbox" checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} className="form-check-input" />
          ),
          size: 40,
        },
        {
          header: 'Permission',
          accessorKey: 'permissionName',
          cell: (info) => info.getValue(),
        },
        {
          header: 'Description',
          accessorKey: 'permissionDescription',
          cell: (info) => info.getValue(),
        },
      ],
      []
    ),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: true,
    state: {
      rowSelection: useMemo(
        () =>
          record.permissions.reduce((acc, permission) => {
            acc[permission] = true;
            return acc;
          }, {}),
        [record.permissions]
      ),
      pagination: { pageIndex, pageSize },
      columnVisibility,
    },
    onRowSelectionChange: handlePermissionChange,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    getRowId: (row) => row.permissionName,
    pageCount: Math.ceil(permissionDefinitions.length / pageSize),
  });

  return (
    <>
      <div className="flex-grow-1 d-flex flex-column overflow-auto">
        <div className="mb-3">
          {groupFields.map((field) => (
            <field.type key={field.name} attribute={field} onChange={handleChange} />
          ))}
        </div>
        <div className="flex-grow-1 overflow-auto d-flex flex-column">
          <div className="d-flex flex-column flex-grow-1 overflow-auto">
            <Table striped bordered hover size="sm" className="overflow-auto flex-grow-1">
              <TableHeader headerGroups={permissionsTable.getHeaderGroups()} />
              <TableBody rows={permissionsTable.getRowModel().rows} />
            </Table>
            <div className="sticky-pagination-new d-flex justify-content-end align-items-center shadow-lg flex-shrink-0">
              <PaginationComponent
                currentPage={permissionsTable.getState().pagination.pageIndex + 1}
                totalPages={permissionsTable.getPageCount()}
                pageSize={permissionsTable.getState().pagination.pageSize}
                totalEntries={permissionDefinitions.length}
                onPageChange={handlePageChange}
                previousPage={() => handlePageChange(pageIndex - 1)}
                nextPage={() => handlePageChange(pageIndex + 1)}
                onPageSizeChange={handlePageSizeChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="manual-modal-footer mt-3">
        <div className="d-flex justify-content-between align-items-center">
          {group && (
            <Button variant="danger" type="button" onClick={handleDelete}>
              Delete Group
            </Button>
          )}
          <div className="ms-auto">
            <Button variant="primary" onClick={handleSubmit} disabled={!hasChanges}>
              {group ? 'Save Changes' : 'Create Group'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditGroupModal;
