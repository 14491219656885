import React, { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EditControls from './EditControls';
import PDFViewer from './PDFViewer';
import { useGet } from 'hooks/useGet';
import './Editor.css';

const Editor = ({ records }) => {
  const [documentData, setDocumentData] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const documentRequest = useGet();
  const { recordId } = useParams();

  const record = useMemo(() => {
    const allRecords = [...records.active, ...records.archived, ...records.inprocess, ...records.pending];

    return allRecords.find((r) => String(r.recordId) === String(recordId));
  }, [records, recordId]);

  useEffect(() => {
    if (record?.fileHash) {
      documentRequest.getDocument(record);
    }
  }, [record?.fileHash]);

  useEffect(() => {
    if (documentRequest.data && !documentRequest.isLoading) {
      setDocumentData(documentRequest.data);
      // Create preview URL from document data
      setPreviewURL(URL.createObjectURL(new Blob([documentRequest.data], { type: 'application/pdf' })));
    }
  }, [documentRequest.data]);

  // Cleanup
  useEffect(() => {
    return () => {
      if (previewURL) {
        URL.revokeObjectURL(previewURL);
      }
    };
  }, [previewURL]);

  if (!record) {
    return <div>Record not found for ID: {recordId}</div>;
  } else {
    console.log('record', record);
  }

  return (
    <div className="editor-container d-flex flex-column flex-md-row flex-grow-1 align-items-stretch">
      <div className="controls-section col-12 col-md-4 order-1 order-md-2 d-flex flex-grow-1">
        <EditControls record={record} />
      </div>
      <div className="pdf-section col-12 col-md-8 order-2 order-md-1 d-flex flex-grow-1">
        <PDFViewer previewURL={previewURL} />
      </div>
    </div>
  );
};

export default Editor;
