import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { updateRecordStatus } from '../../../../utils/rowActions';
import { usePatch } from '../../../../hooks/usePatch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons';

const WithSelected = ({ rowSelection, tableType, getSelectedRecords }) => {
  const { patchData, isLoading, error } = usePatch();
  const selectedCount = Object.keys(rowSelection).length;
  if (selectedCount === 0) return null;

  // Define actions for each table type
  const getActionsForTableType = (tableType) => {
    switch (tableType) {
      case 'pendingTable':
        return [
          { key: 'delete', label: 'Delete', action: () => console.log('Delete action triggered for selected rows:', rowSelection) },
          { key: 'export', label: 'Export', action: () => console.log('Export action triggered for selected rows:', rowSelection) },
        ];
      case 'inprocessTable':
        return [{ key: 'archive', label: 'Archive', action: () => updateRecordStatus(getSelectedRecords(), 'archived', patchData) }];
      case 'activeTable':
        return [
          { key: 'view', label: 'View', action: () => console.log('View action triggered for selected rows:', rowSelection) },
          { key: 'archive', label: 'Archive', action: () => updateRecordStatus(getSelectedRecords(), 'archived', patchData) },
        ];
      case 'archivedTable':
        return [{ key: 'restore', label: 'Restore', action: () => updateRecordStatus(getSelectedRecords(), 'active', patchData) }];
      default:
        return [];
    }
  };

  const actions = getActionsForTableType(tableType);

  return (
    <Dropdown drop="up" className="ms-2">
      <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ color: 'white' }}>
        <FontAwesomeIcon icon={faCheckSquare} /> With {selectedCount} Selected
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {actions.map(({ key, label, action }) => (
          <Dropdown.Item key={key} onClick={action}>
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default WithSelected;
