import React, { useState } from 'react';
import PowerModal from './PowerModal.js';

const Reports = ({ onClose }) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    // Clear the hash from the URL without triggering a navigation
    window.history.replaceState(null, '', window.location.pathname);
    // Call the original onClose handler
    onClose?.();
  };

  return (
    <PowerModal show={show} onHide={handleClose} title="Reports" size="lg" centered overlay={false} closeButton={true}>
      <div className="p-4">
        <h4>Setup Reports Here</h4>
      </div>
    </PowerModal>
  );
};

export default Reports;
