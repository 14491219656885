import React, { createContext, useState, useEffect, useCallback, useContext, useMemo } from 'react';
import axios from 'axios';
import Portal from '../components/portal/Portal';
import { useKeycloak } from '@react-keycloak/web';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Admin from '../components/admin/Admin';
import Col from 'react-bootstrap/Col';
import { useGet } from 'hooks/useGet';
import { ModalProvider } from './ModalContext';
import { ToasterProvider } from '../components/admin/ToasterContext';
import { WebSocketProvider } from './WebSocketContext';
const PortalContext = createContext();
const PermissionContext = createContext();

/**
 * Custom hook for accessing user permissions and information.
 * @returns {Object} An object containing user permissions and information
 *
 * @example
 * // Basic usage
 * const MyComponent = () => {
 *   const { hasPermission, email, userId, locations } = usePermissions();
 *
 *   if (hasPermission('admin')) {
 *     return <AdminPanel />;
 *   }
 *
 *   return (
 *     <div>
 *       <p>User: {email}</p>
 *       <LocationList locations={locations} />
 *     </div>
 *   );
 * };
 */
const usePermissions = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionProvider');
  }
  return context;
};

/**
 * Custom hook for accessing the portal configuration and update functions.
 * @returns {Object} An object containing portal configuration and update functions
 *
 * @example
 * // Basic usage
 * const MyComponent = () => {
 *   const { portalConfig, updatePortalConfig } = usePortal();
 *
 *   // Update specific parts of the config
 *   const handleUpdate = () => {
 *     updatePortalConfig((prev) => ({
 *       ...prev,
 *       portal: {
 *         ...prev.portal,
 *         someKey: 'newValue'
 *       }
 *     }));
 *   };
 *
 *   return (
 *     <div>
 *       <h1>{portalConfig.portal.title}</h1>
 *       <button onClick={handleUpdate}>Update Config</button>
 *     </div>
 *   );
 * };
 */
const usePortal = () => {
  const context = useContext(PortalContext);
  if (!context) {
    throw new Error('usePortal must be used within a PortalProvider');
  }
  return context;
};

/**
 * Provider component that makes user permissions and information available to its children.
 */
const PermissionProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(new Set());
  const [userInfo, setUserInfo] = useState({
    email: '',
    userId: '',
    locations: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const { keycloak } = useKeycloak();
  const permissionsRequest = useGet();

  const fetchPermissions = useCallback(async () => {
    try {
      const response = await permissionsRequest.getData('/users/permissions');

      if (response?.data) {
        // Set permissions
        setPermissions(new Set(response.data.permissions));

        // Set additional user info
        const userInfoData = {
          email: response.data.email,
          userId: response.data.userId,
          locations: response.data.locations || [],
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          groups: response.data.groups,
          status: response.data.status,
        };
        setUserInfo(userInfoData);
      } else {
        console.error('Frontend - Missing required data in response');
      }
    } catch (error) {
      console.error('Frontend - Failed to fetch user permissions:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Function to force a permissions refresh
  const refreshPermissions = useCallback(async () => {
    setIsLoading(true);
    console.log('Manually refreshing permissions');
    await fetchPermissions();
  }, [fetchPermissions]);

  // Fetch permissions on initial load and token refresh
  useEffect(() => {
    if (keycloak.authenticated) {
      fetchPermissions();

      // Set up token refresh listener
      const refreshListener = () => {
        console.log('Token refreshed, updating permissions');
        fetchPermissions();
      };

      keycloak.onTokenExpired = refreshListener;

      return () => {
        keycloak.onTokenExpired = null;
      };
    } else {
      // If not authenticated, set isLoading to false and clear permissions
      setIsLoading(false);
      setPermissions(new Set());
      setUserInfo({
        email: '',
        userId: '',
        locations: [],
        firstName: '',
        lastName: '',
        groups: [],
        status: '',
      });
    }
  }, [keycloak.authenticated, fetchPermissions]);

  const hasPermission = useCallback((permission) => permissions.has(permission), [permissions]);
  const hasAnyPermission = useCallback((permissionArray) => permissionArray.some((permission) => permissions.has(permission)), [permissions]);

  // Memoize the context value
  const permissionValue = useMemo(
    () => ({
      hasPermission,
      hasAnyPermission,
      permissions: Array.from(permissions),
      email: userInfo.email,
      userId: userInfo.userId,
      locations: userInfo.locations,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      groups: userInfo.groups,
      status: userInfo.status,
      refreshPermissions,
      isLoading,
    }),
    [
      hasPermission,
      hasAnyPermission,
      permissions,
      userInfo.email,
      userInfo.userId,
      userInfo.locations,
      userInfo.firstName,
      userInfo.lastName,
      userInfo.groups,
      userInfo.status,
      refreshPermissions,
      isLoading,
    ]
  );

  return <PermissionContext.Provider value={permissionValue}>{children}</PermissionContext.Provider>;
};

const PortalProvider = ({ children }) => {
  const [portalConfig, setPortalConfig] = useState(null);
  const [lightbox, setLightbox] = useState(null);
  const { keycloak, initialized } = useKeycloak();
  const portalConfigRequest = useGet();

  // Function to update specific parts of the portal configuration
  const updatePortalConfig = useCallback((updater) => {
    setPortalConfig((prevConfig) => {
      // If updater is a function, call it with the previous config
      if (typeof updater === 'function') {
        return updater(prevConfig);
      }
      // If updater is an object, merge it with the previous config
      return { ...prevConfig, ...updater };
    });
  }, []);

  //monitor the pinnedDocRequest.data state, and set the pinnedDocuments state when there is data
  useEffect(() => {
    if (portalConfigRequest.data) {
      setPortalConfig(portalConfigRequest.data);
    }
  }, [portalConfigRequest.data]);

  //when the component loads, get the pinned documents using the pinnedDocRequest.getData function
  useEffect(() => {
    portalConfigRequest.getData('/portal/config');
  }, []);

  // Memoize the context value outside of any conditionals
  const portalValue = useMemo(
    () => ({
      portalConfig,
      updatePortalConfig,
      lightbox,
      setLightbox,
    }),
    [portalConfig, updatePortalConfig, lightbox, setLightbox]
  );

  //if the portalConfig has not been successfully loaded return a loading div
  if (!portalConfig?.portal) {
    return <div>Preparing the SDS Portal</div>;
  }

  // Add mobile detection
  portalConfig.isMobile = window.innerWidth < 768;

  // Handle authentication and routing
  if (portalConfig.portal.accessControl === 'auth') {
    if (keycloak.authenticated) {
      // Check if user is authorized for this subdomain
      if (!keycloak.tokenParsed.authorized_clients.includes(window.location.hostname.split('.')[0])) {
        console.log('User is not authorized for', window.location.hostname.split('.')[0]);

        // If user is authorized for at least one client, redirect to the first one
        if (keycloak.tokenParsed.authorized_clients.length > 0) {
          window.location.href = `https://${keycloak.tokenParsed.authorized_clients[0]}${process.env.REACT_APP_REDIRECT_SUFFIX}`;
          return null;
        } else {
          // Log out and return to login
          keycloak.logout();
          keycloak.login({ idpHint: portalConfig.portal.idpHint, redirectUri: window.location.href });
          return null;
        }
      }

      return (
        <PortalContext.Provider value={portalValue}>
          <PermissionProvider>
            <BrowserRouter>
              <Routes>
                <Route exact path="/*" element={<Portal />} />
                <Route
                  path="/admin/*"
                  element={
                    <PrivateRoute>
                      <ModalProvider>
                        <Admin />
                      </ModalProvider>
                    </PrivateRoute>
                  }
                />
                <Route element={<Col />} />
              </Routes>
            </BrowserRouter>
          </PermissionProvider>
        </PortalContext.Provider>
      );
    } else {
      keycloak.login({ idpHint: portalConfig.portal.idpHint, redirectUri: window.location.href });
      return null;
    }
  }

  // Non-authenticated portal
  return (
    <PortalContext.Provider value={portalValue}>
      <PermissionProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/*" element={<Portal />} />
            <Route
              path="/admin/*"
              element={
                <PrivateRoute>
                  <WebSocketProvider>
                    <ToasterProvider>
                      <ModalProvider>
                        <Admin />
                      </ModalProvider>
                    </ToasterProvider>
                  </WebSocketProvider>
                </PrivateRoute>
              }
            />
            <Route element={<Col />} />
          </Routes>
        </BrowserRouter>
      </PermissionProvider>
    </PortalContext.Provider>
  );
};

const PrivateRoute = ({ children }) => {
  const { keycloak } = useKeycloak();
  const portalConfig = useContext(PortalContext);
  const { hasPermission, permissions, isLoading } = usePermissions();
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setInitialCheckDone(true);
    }
  }, [isLoading]);

  if (!keycloak.authenticated) {
    const idpHint = portalConfig?.portal?.idpHint || '';
    keycloak.login({ idpHint, redirectUri: window.location.href });
    return null;
  }

  const currentSubdomain = window.location.hostname.split('.')[0];
  const authorizedClients = keycloak.tokenParsed?.authorized_clients || [];

  if (!authorizedClients.includes(currentSubdomain)) {
    console.log('User is not authorized for', currentSubdomain);
    return <Navigate to="/" replace />;
  }

  // Only show loading on initial load
  if (!initialCheckDone && isLoading) {
    return <div>Loading...</div>;
  }

  // After initial load, keep showing content during refreshes
  if (hasPermission('adminArea')) {
    return children;
  }

  console.log('User does not have permission to access the admin area');
  return <Navigate to="/" replace />;
};

// Export the hooks and providers
export { usePermissions, usePortal, PermissionProvider, PortalProvider };
