import React, { useMemo } from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';
import { StringEdit } from '../../../custom-attributes/String';
import { MultiSelectEdit } from '../../../custom-attributes/Multi-Select';
import { TextAreaEdit, TextAreaArrayEdit } from '../../../custom-attributes/TextArea';
import { DateEdit } from '../../../custom-attributes/Date';
import { CheckboxEdit } from '../../../custom-attributes/Checkbox';
import { SelectEdit } from '../../../custom-attributes/Select';
import { StringArrayEdit } from '../../../custom-attributes/StringArray';
import { PictogramArrayEdit } from '../../../custom-attributes/PictogramArray';
import { PhotoArrayEdit } from '../../../custom-attributes/PhotoArray';
import { NumberEdit } from '../../../custom-attributes/Number';
import { SdsVersionEdit } from '../../../custom-attributes/SdsVersionEdit';
import { usePortal } from '../../../../context/Portal';
import './Expert.css';

const Expert = ({ record, onUpdate, originalRecord }) => {
  const { portalConfig } = usePortal();

  const getAttributeType = (type) => {
    const typeMap = {
      string: StringEdit,
      text: TextAreaEdit,
      textArray: TextAreaArrayEdit,
      stringArray: StringArrayEdit,
      select: SelectEdit,
      'multi-select': MultiSelectEdit,
      date: DateEdit,
      checkbox: CheckboxEdit,
      photoArray: PhotoArrayEdit,
      pictogramArray: PictogramArrayEdit,
      number: NumberEdit,
    };
    return typeMap[type] || StringEdit;
  };

  const handleChange = (field, value) => {
    if (field === 'versions') {
      onUpdate(value);
    } else {
      onUpdate({ [field]: value });
    }
  };

  // Prepare tab definitions with memoization
  const expertTabDefinitions = useMemo(() => {
    const tabs = {
      General: [
        {
          name: 'productIdentifier',
          label: 'Product Identifier',
          type: StringEdit,
          required: true,
          editClasses: { component: 'col-12 p-2' },
          helpText: 'The product identifier is a unique identifier for the product. It is used to identify the product in the system.',
        },
        {
          name: 'aka',
          label: 'Other Names or Codes',
          type: StringArrayEdit,
          required: false,
          editClasses: { component: 'col-12 p-2' },
          helpText: 'Other names or codes for the product that will be used for employee search purposes.',
        },
        {
          name: 'manName',
          label: 'Manufacturer Name',
          type: StringEdit,
          required: true,
          editClasses: { component: 'col-12 p-2' },
          helpText: 'The name of the manufacturer of the product.',
        },
        {
          name: 'manAddress',
          label: 'Manufacturer Address',
          type: StringEdit,
          required: false,
          editClasses: { component: 'col-12 p-2' },
          helpText: 'The address of the manufacturer of the product Street, City, State, Zip',
        },
        {
          name: 'manEmergencyPhone',
          label: 'Emergency Phone',
          type: StringEdit,
          required: false,
          editClasses: { component: 'col-6 p-2' },
          helpText: 'The emergency phone number of the manufacturer of the product.',
        },
        {
          name: 'sdsDate',
          label: 'Revision Date',
          type: DateEdit,
          required: false,
          editClasses: { component: 'col-6 p-2' },
          helpText: 'The revisiondate of the Safety Data Sheet.',
        },
        {
          name: 'upc',
          label: 'Chemical Barcode',
          type: StringArrayEdit,
          required: false,
          editClasses: { component: 'col-12 p-2' },
          helpText: 'The Universal Product Code of the product.',
        },
        {
          name: 'photos',
          label: 'Photos',
          type: PhotoArrayEdit,
          required: false,
          editClasses: { component: 'col-12 p-2' },
        },
      ],
      Hazard: [
        {
          name: 'msds',
          label: 'MSDS',
          type: CheckboxEdit,
          required: false,
          editClasses: { component: 'col-4 p-2 d-flex justify-content-center align-items-center' },
          helpText: 'Is this an old MSDS?',
        },
        {
          name: 'signalWord',
          label: 'Signal Word',
          type: SelectEdit,
          options: ['', 'DANGER', 'WARNING'],
          required: true,
          helpText: 'The signal word.',
          editClasses: { component: 'col-8 p-2' },
        },
        {
          name: 'pictograms',
          label: 'Pictograms',
          type: PictogramArrayEdit,
          required: false,
          editClasses: { component: 'col-12 p-2' },
        },
        {
          name: 'hazardStatements',
          label: 'Hazard Statements',
          type: TextAreaArrayEdit,
          required: false,
          editClasses: { component: 'col-12 p-2' },
        },
        {
          name: 'precautionaryStatements',
          label: 'Precautionary Statements',
          type: TextAreaArrayEdit,
          required: false,
          editClasses: { component: 'col-12 p-2' },
        },
        {
          name: 'hazardsNOC',
          label: 'Hazards not otherwise classified',
          type: TextAreaEdit,
          required: false,
          editClasses: { component: 'col-12 p-2' },
        },
      ],
      Notes: [
        {
          name: 'notes',
          label: 'Notes',
          type: TextAreaEdit,
          required: false,
          editClasses: { component: 'col-12 p-2' },
        },
      ],
      Versions: [
        {
          name: 'versions',
          label: 'SDS Version History',
          type: SdsVersionEdit,
          required: false,
          editClasses: { component: 'col-12 p-2' },
        },
      ],
    };

    if (portalConfig?.portal?.attributeSettings?.length > 0) {
      tabs.Custom = portalConfig.portal.attributeSettings.map((attribute) => ({
        name: attribute.name,
        label: attribute.label || attribute.name,
        type: getAttributeType(attribute.type),
        required: attribute.required || false,
        editClasses: { component: 'col-12 p-2' },
        helpText: attribute.helpText,
        options: attribute.options,
      }));
    }

    return tabs;
  }, [portalConfig]);

  // Prepare fields with values and modification status
  const preparedFields = useMemo(() => {
    return Object.entries(expertTabDefinitions).reduce((acc, [tabName, fields]) => {
      acc[tabName] = fields.map((field) => ({
        ...field,
        value: field.type === SdsVersionEdit ? record : record[field.name],
        editClasses: {
          ...field.editClasses,
          component: `${field.editClasses.component || ''} ${
            field.type !== SdsVersionEdit && record[field.name] !== originalRecord[field.name] ? 'unsaved-field' : ''
          }`.trim(),
        },
      }));
      return acc;
    }, {});
  }, [expertTabDefinitions, record, originalRecord]);

  return (
    <div className="expert-editor p-3 flex-fill">
      <Tabs defaultActiveKey={Object.keys(expertTabDefinitions)[0]} id="expert-editor-tabs">
        {Object.entries(preparedFields).map(([tabName, fields]) => (
          <Tab eventKey={tabName} title={tabName} key={tabName}>
            <div className="row">
              {fields.map((field) => (
                <field.type key={field.name} attribute={field} onChange={handleChange} />
              ))}
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default Expert;
