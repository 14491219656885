import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import { Button, Toast } from 'react-bootstrap';
import UserEditor from './UserEditor';
import GroupEditor from './GroupEditor';
import { useGet } from '../../../../hooks/useGet';
import { TabContainer, Tab, TabContent, TabPane, Nav } from 'react-bootstrap';
import { useModal } from '../../../../context/ModalContext';
import { usePortal, usePermissions } from '../../../../context/Portal';
import EditGroupModal from './EditGroupModal';
import EditUserModal from './EditUserModal';
import { usePatch } from '../../../../hooks/usePatch';
import { usePost } from '../../../../hooks/usePost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUsersMedical, faUser, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { useToaster } from '../../ToasterContext';

// Memoized Tab Navigation Component
const TabNavigation = memo(({ activeTab, onTabSelect }) => (
  <Nav variant="tabs" className="mb-3">
    <Nav.Item>
      <Nav.Link eventKey="users">
        <FontAwesomeIcon icon={faUser} className="me-2" />
        Users
      </Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="groups">
        <FontAwesomeIcon icon={faUsers} className="me-2" />
        Groups
      </Nav.Link>
    </Nav.Item>
  </Nav>
));

// Memoized Tab Content Component
const TabContentWrapper = memo(({ activeTab, users, onRefresh, groupHandlers, userHandlers }) => (
  <TabContent className="flex-grow-1 d-flex flex-column">
    <TabPane eventKey="users" className="flex-grow-1">
      <UserEditor users={users} onRefresh={onRefresh} userHandlers={userHandlers} />
    </TabPane>
    <TabPane eventKey="groups" className="flex-grow-1">
      <GroupEditor groupHandlers={groupHandlers} />
    </TabPane>
  </TabContent>
));

const UserAdminContent = memo(
  ({ onClose, users, isLoading, error, onRefresh, onCreateGroup, activeTab, onTabSelect, userHandlers, groupHandlers }) => {
    // Memoize error component
    const errorComponent = useMemo(() => error && <div className="alert alert-danger">Failed to load data: {error.message}</div>, [error]);

    // Memoize loading component
    const loadingComponent = useMemo(() => isLoading && <div>Loading...</div>, [isLoading]);

    // Handle Create Group click
    const handleCreateGroupClick = useCallback(() => {
      onTabSelect('groups');
      onCreateGroup();
    }, [onCreateGroup, onTabSelect]);

    if (errorComponent) return errorComponent;
    if (loadingComponent) return loadingComponent;

    return (
      <TabContainer activeKey={activeTab} onSelect={onTabSelect}>
        <TabNavigation activeTab={activeTab} onTabSelect={onTabSelect} />
        <TabContentWrapper activeTab={activeTab} users={users} onRefresh={onRefresh} groupHandlers={groupHandlers} userHandlers={userHandlers} />
      </TabContainer>
    );
  }
);

// Add display name for debugging
UserAdminContent.displayName = 'UserAdminContent';

const Users = memo(({ onClose }) => {
  const { showModal, hideModal } = useModal();
  const { getData: getUsers, data: users, isLoading: usersLoading, error: usersError } = useGet();
  const { getData: getConfig } = useGet();
  const { portalConfig, updatePortalConfig } = usePortal();
  const { patchData } = usePatch();
  const { postData } = usePost();
  const { refreshPermissions } = usePermissions();
  const [activeTab, setActiveTab] = useState('users');
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const { addToast, removeToast } = useToaster();

  // Fetch users only when needed
  useEffect(() => {
    if (shouldRefresh) {
      getUsers('/users');
      setShouldRefresh(false);
    }
  }, [getUsers, shouldRefresh]);

  // Centralized refresh function
  const refreshUsers = useCallback(() => {
    setShouldRefresh(true);
  }, []);

  // Centralized user management functions
  const handleSaveUser = useCallback(
    async (userData, modalId) => {
      try {
        const toastId = addToast({
          component: (
            <Toast onClose={() => removeToast(toastId)}>
              <Toast.Header>
                <strong className="me-auto">Creating User</strong>
              </Toast.Header>
              <Toast.Body>
                {userData._id ? 'Updating' : 'Creating'} user: {userData.firstName} {userData.lastName}
              </Toast.Body>
            </Toast>
          ),
        });
        if (userData._id) {
          await patchData(`/users/${userData._id}`, userData);
        } else {
          await postData('/users', userData);
        }
        setTimeout(() => {
          removeToast(toastId);
        }, 3000);
        hideModal(modalId);
        await refreshPermissions();
        refreshUsers();
      } catch (error) {
        // Close any toasts we opened above
        if (toastId) {
          removeToast(toastId);
        }
        console.error('Error saving user:', error);
        const toastId = addToast({
          component: (
            <Toast onClose={() => removeToast(toastId)} delay={3000} autohide bg="danger" text="white">
              <Toast.Header>
                <strong className="me-auto">Error</strong>
              </Toast.Header>
              <Toast.Body>Failed to {userData._id ? 'update' : 'create'} user. Please try again.</Toast.Body>
            </Toast>
          ),
        });
      }
    },
    [patchData, postData, hideModal, refreshPermissions, refreshUsers, addToast, removeToast]
  );

  const handleDeleteUser = useCallback(
    async (user) => {
      try {
        await patchData(`/users/${user._id}/delete`);
        hideModal(`delete-user-${user._id}`);
        refreshUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    },
    [patchData, hideModal, refreshUsers]
  );

  const handleCreateNewUser = useCallback(() => {
    const modalId = 'create-new-user';
    showModal({
      id: modalId,
      component: EditUserModal,
      props: {
        user: {
          firstName: '',
          lastName: '',
          email: '',
          groups: [],
          locations: [],
          status: 'active',
        },
        users: users?.users || users,
        onSave: (userData) => handleSaveUser(userData, modalId),
        modalId,
      },
      title: 'Create New User',
      size: 'lg',
      resizable: true,
      centered: true,
      overlay: false,
      width: 550,
      height: 675,
    });
  }, [showModal, users, handleSaveUser]);

  // Centralized group management functions
  const handleSaveGroup = useCallback(
    async (groupData, modalId) => {
      try {
        const isExistingGroup = portalConfig?.permissionGroups?.some((g) => g.groupID === groupData.groupID);

        // Create operations object for the patch
        const operations = {
          action: isExistingGroup ? 'update' : 'create',
          groupId: groupData.groupID,
          changes: {
            name: groupData.name,
            description: groupData.description,
            permissions: groupData.permissions,
          },
        };

        console.log('Sending patch request with operations:', operations);

        const response = await patchData(`/portal/groups/${groupData.groupID}`, { operations });

        if (response?.success) {
          // Update the portal context with the new group data
          const updatedGroups = isExistingGroup
            ? portalConfig.permissionGroups.map((group) => (group.groupID === response.group.groupID ? response.group : group))
            : [...(portalConfig.permissionGroups || []), response.group];

          updatePortalConfig({
            ...portalConfig,
            permissionGroups: updatedGroups,
          });

          // Close the modal
          hideModal(modalId);
        } else {
          console.error('Failed to save group:', response?.message || 'Unknown error');
        }
      } catch (error) {
        console.error('Failed to update group:', error);
      }
    },
    [portalConfig, patchData, updatePortalConfig, hideModal]
  );

  const handleDeleteGroup = useCallback(
    async (group) => {
      try {
        const response = await patchData(`/portal/groups/${group.groupID}`, {
          operations: {
            action: 'delete',
            groupId: group.groupID,
          },
        });

        if (response?.success && response?.deletedGroupId) {
          // Update local state with filtered groups
          const updatedGroups = portalConfig.permissionGroups.filter((g) => g.groupID !== response.deletedGroupId);
          updatePortalConfig({
            ...portalConfig,
            permissionGroups: updatedGroups,
          });

          // Close the modal if one is open
          hideModal(`delete-group-${group.groupID}`);
        } else {
          console.error('Failed to delete group:', response?.message || 'Unknown error');
        }
      } catch (error) {
        console.error('Error deleting group:', error);
      }
    },
    [portalConfig, patchData, updatePortalConfig, hideModal]
  );

  const handleCreateNewGroup = useCallback(async () => {
    const modalId = 'create-new-group';
    const maxGroupId = Math.max(...(portalConfig?.permissionGroups || []).map((g) => g.groupID), 0);
    const newGroupId = maxGroupId + 1;

    const newGroup = {
      groupID: newGroupId,
      name: '',
      description: '',
      permissions: [],
    };

    showModal({
      id: modalId,
      component: EditGroupModal,
      props: {
        group: newGroup,
        onSave: (groupData) => handleSaveGroup(groupData, modalId),
        modalId,
      },
      title: 'Create New Group',
      size: 'lg',
      resizable: true,
      centered: true,
      overlay: false,
      width: 720,
      height: 790,
    });
  }, [showModal, portalConfig, handleSaveGroup]);

  // Memoize handlers
  const userHandlers = useMemo(
    () => ({
      onSaveUser: handleSaveUser,
      onDeleteUser: handleDeleteUser,
    }),
    [handleSaveUser, handleDeleteUser]
  );

  const groupHandlers = useMemo(
    () => ({
      onSaveGroup: handleSaveGroup,
      onDeleteGroup: handleDeleteGroup,
      onCreateGroup: handleCreateNewGroup,
    }),
    [handleSaveGroup, handleDeleteGroup, handleCreateNewGroup]
  );

  // Memoize footer content
  const footerContent = useMemo(
    () => (
      <div className="w-100 d-flex justify-content-end gap-2">
        {activeTab === 'users' && (
          <Button variant="primary" className="text-light" onClick={handleCreateNewUser}>
            <FontAwesomeIcon icon={faUserPlus} className="me-2" />
            Create New User
          </Button>
        )}
        {activeTab === 'groups' && (
          <Button variant="primary" className="text-light" onClick={handleCreateNewGroup}>
            <FontAwesomeIcon icon={faUsersMedical} className="me-2" />
            Create New Group
          </Button>
        )}
      </div>
    ),
    [activeTab, handleCreateNewUser, handleCreateNewGroup]
  );

  // Memoize content component
  const content = useMemo(
    () => (
      <UserAdminContent
        onClose={onClose}
        users={users}
        isLoading={usersLoading}
        error={usersError}
        onRefresh={refreshUsers}
        activeTab={activeTab}
        onTabSelect={setActiveTab}
        userHandlers={userHandlers}
        groupHandlers={groupHandlers}
      />
    ),
    [onClose, users, usersLoading, usersError, refreshUsers, activeTab, userHandlers, groupHandlers]
  );

  // Show modal on mount
  useEffect(() => {
    const modalId = 'user-administration';
    const modalConfig = {
      id: modalId,
      title: 'User Administration',
      size: 'lg',
      width: 630,
      height: 670,
      resizable: true,
      centered: true,
      overlay: false,
      closeButton: true,
      onHide: () => {
        hideModal(modalId);
        if (window.location.hash === '#users') {
          window.location.hash = '';
        }
        window.history.replaceState(null, '', window.location.pathname);
        onClose?.();
      },
      footerContent,
      component: () => content,
    };

    showModal(modalConfig);
    return () => hideModal(modalId);
  }, [showModal, hideModal, onClose, content, footerContent]);

  return null;
});

// Add display name for debugging
Users.displayName = 'Users';

export default Users;
