import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Card, Toast } from 'react-bootstrap';
import { usePost } from '../../../../hooks/usePost';
import { useToaster } from '../../ToasterContext';

const FileUpload = () => {
  const { postData, isLoading } = usePost();
  const { addToast, removeToast } = useToaster();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const formData = new FormData();
      const uploadToasts = new Map();

      // Add files to formData and create toasts
      acceptedFiles.forEach((file) => {
        formData.append('files', file);

        // Create a toast for this file
        const toastId = addToast({
          component: (
            <Toast>
              <Toast.Header>
                <strong className="me-auto">File Upload</strong>
              </Toast.Header>
              <Toast.Body>Uploading {file.name}...</Toast.Body>
            </Toast>
          ),
        });
        uploadToasts.set(file.name, toastId);
      });

      try {
        const response = await postData('/sds/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Upload response:', response);

        // Remove all upload toasts
        uploadToasts.forEach((toastId) => removeToast(toastId));

        // Show success toast
        const successToastId = addToast({
          component: (
            <Toast onClose={() => removeToast(successToastId)} delay={3000} autohide>
              <Toast.Header>
                <strong className="me-auto">Upload Complete</strong>
              </Toast.Header>
              <Toast.Body>Successfully uploaded {acceptedFiles.length} file(s)</Toast.Body>
            </Toast>
          ),
        });
      } catch (error) {
        console.error('Upload error:', error);

        // Remove all upload toasts
        uploadToasts.forEach((toastId) => removeToast(toastId));

        // Show error toast
        const errorToastId = addToast({
          component: (
            <Toast onClose={() => removeToast(errorToastId)} delay={3000} autohide bg="danger" text="white">
              <Toast.Header>
                <strong className="me-auto">Upload Error</strong>
              </Toast.Header>
              <Toast.Body>Failed to upload files. Please try again.</Toast.Body>
            </Toast>
          ),
        });
      }
    },
    [postData, addToast, removeToast]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  return (
    <Card>
      <Card.Body>
        <Card.Title>Bulk SDS Upload</Card.Title>
        <div
          {...getRootProps()}
          style={{
            border: '2px dashed #cccccc',
            borderRadius: '4px',
            padding: '20px',
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: isDragActive ? '#f8f9fa' : 'white',
          }}
        >
          <input {...getInputProps()} />
          {isLoading ? (
            <p>Uploading files...</p>
          ) : isDragActive ? (
            <p>Drop the PDF files here...</p>
          ) : (
            <p>Drag and drop PDF files here, or click to select files</p>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default FileUpload;
