/**
 * PowerModal Component
 *
 * This component is a customizable modal dialog built using `react-bootstrap` and `react-draggable`.
 * It provides features such as draggable and resizable modals, and allows for custom content and styling.
 *
 * Props:
 * - show (bool): Controls the visibility of the modal.
 * - onHide (func): Function to call when the modal is requested to be closed.
 * - title (string): Title text to display in the modal header.
 * - footerContent (node): Content to display in the modal footer.
 * - size (string): Size of the modal ('sm', 'lg', 'xl').
 * - children (node): Content to display in the modal body.
 * - draggable (bool): Enables dragging of the modal.
 * - resizable (bool): Enables resizing of the modal.
 * - overlay (bool): Controls the display of the overlay backdrop.
 * - onClick (func): Function to call when the modal is clicked.
 * - zIndex (number): zIndex value to apply to the modal for stacking order.
 * - width (number | string): Width of the modal.
 * - height (number | string): Height of the modal.
 *
 * Usage:
 *
 * ```jsx
 * import PowerModal from './PowerModal';
 *
 * function App() {
 *   const [showModal, setShowModal] = useState(false);
 *
 *   const handleShow = () => setShowModal(true);
 *   const handleClose = () => setShowModal(false);
 *
 *   return (
 *     <div>
 *       <button onClick={handleShow}>Show Modal</button>
 *       <PowerModal
 *         show={showModal}
 *         onHide={handleClose}
 *         title="My Modal Title"
 *         footerContent={<button onClick={handleClose}>Close</button>}
 *         size="lg"
 *         draggable
 *         resizable
 *         overlay
 *         onClick={() => console.log('Modal clicked')}
 *         zIndex={1050}
 *         width="500px"
 *         height="300px"
 *       >
 *         <p>This is the modal content.</p>
 *       </PowerModal>
 *     </div>
 *   );
 * }
 * ```
 *
 * This component leverages `react-bootstrap` for the modal structure and `react-draggable` for the draggable functionality.
 * Ensure you have both `react-bootstrap` and `react-draggable` installed in your project.
 *
 * Installation:
 *
 * ```bash
 * npm install react-bootstrap react-draggable
 * ```
 *
 * For more information on `react-bootstrap` modals, refer to the [react-bootstrap documentation](https://react-bootstrap.github.io/components/modal/).
 * For draggable functionality, refer to the [react-draggable documentation](https://github.com/react-grid-layout/react-draggable).
 */

import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Draggable from 'react-draggable';
import './PowerModal.css';

// Import the image
import datasheetSolutionsIcon from '../../../assets/images/datasheetsolutions_small.png';

// Add a helper function to manage modal settings
const modalSettings = {
  activeModals: new Set(), // Track currently active modals

  save: (title, settings) => {
    try {
      const allSettings = JSON.parse(localStorage.getItem('modalSettings') || '{}');
      allSettings[title] = settings;
      localStorage.setItem('modalSettings', JSON.stringify(allSettings));
    } catch (error) {
      console.error('Error saving modal settings:', error);
    }
  },

  load: (title) => {
    try {
      const allSettings = JSON.parse(localStorage.getItem('modalSettings') || '{}');
      const baseSettings = allSettings[title];

      if (baseSettings) {
        // Check if this title is already active
        if (modalSettings.activeModals.has(title)) {
          // Apply offset for duplicate modals
          return {
            ...baseSettings,
            position: {
              x: baseSettings.position.x + 10,
              y: baseSettings.position.y + 10,
            },
          };
        }

        // Add this title to active modals
        modalSettings.activeModals.add(title);
        return baseSettings;
      }
      return null;
    } catch (error) {
      console.error('Error loading modal settings:', error);
      return null;
    }
  },

  // Add cleanup method
  remove: (title) => {
    modalSettings.activeModals.delete(title);
  },
};

const DraggableModalDialog = React.memo(
  ({ children, width, height, title, onClick, ...props }) => {
    const nodeRef = useRef(null);
    const [position, setPosition] = useState(() => {
      // Initialize position from localStorage immediately
      const savedSettings = modalSettings.load(title);
      if (savedSettings?.position) {
        return savedSettings.position;
      }
      return null;
    });

    const [dimensions, setDimensions] = useState(() => {
      // Initialize dimensions from localStorage or use provided dimensions
      const savedSettings = modalSettings.load(title);
      if (savedSettings?.dimensions) {
        return savedSettings.dimensions;
      }
      if (width || height) {
        return {
          width: width || 'auto',
          height: height || 'auto',
        };
      }
      return null;
    });

    const hasBeenInitialized = useRef(false);

    const initializeModal = useCallback(() => {
      if (hasBeenInitialized.current) return;

      const modalDialog = nodeRef.current;
      if (!modalDialog) return;

      if (!position || !dimensions) {
        // Calculate initial dimensions
        const modalWidth = width || modalDialog.offsetWidth;
        const modalHeight = height || modalDialog.offsetHeight;

        // Calculate center position
        const centerX = (window.innerWidth - modalWidth) / 2;
        const centerY = (window.innerHeight - modalHeight) / 2;

        if (!position) {
          setPosition({ x: centerX, y: centerY });
        }
        if (!dimensions) {
          setDimensions({ width: modalWidth, height: modalHeight });
        }
      }

      hasBeenInitialized.current = true;
    }, [width, height, position, dimensions]);

    useEffect(() => {
      // Small delay to ensure DOM is ready
      const timer = setTimeout(initializeModal, 50);
      return () => clearTimeout(timer);
    }, [initializeModal]);

    // Handle position changes
    const handleDragStop = (e, data) => {
      const newPosition = { x: data.x, y: data.y };
      setPosition(newPosition);
      modalSettings.save(title, {
        position: newPosition,
        dimensions: dimensions,
      });
    };

    // Handle resize
    const handleResize = useCallback(() => {
      if (nodeRef.current) {
        const newDimensions = {
          width: nodeRef.current.offsetWidth,
          height: nodeRef.current.offsetHeight,
        };
        setDimensions(newDimensions);
        if (position) {
          // Only save if we have a position
          modalSettings.save(title, {
            position: position,
            dimensions: newDimensions,
          });
        }
      }
    }, [position, title]);

    useEffect(() => {
      const resizeObserver = new ResizeObserver(handleResize);
      if (nodeRef.current) {
        resizeObserver.observe(nodeRef.current);
      }
      return () => resizeObserver.disconnect();
    }, [handleResize]);

    const style = {
      ...props.style,
      width: dimensions?.width || width || 'auto',
      height: dimensions?.height || height || 'auto',
      zIndex: props.zIndex,
      visibility: position ? 'visible' : 'hidden', // Hide modal until position is set
    };

    // Add cleanup on unmount
    useEffect(() => {
      return () => {
        modalSettings.remove(title);
      };
    }, [title]);

    // Add onDragStart handler
    const handleDragStart = (e, data) => {
      // Only trigger onClick if it's not coming from a child modal button
      if (!e.target.closest('.btn')) {
        onClick?.(e);
      }
    };

    return (
      <Draggable handle=".modal-header" nodeRef={nodeRef} position={position || { x: 0, y: 0 }} onStop={handleDragStop} onStart={handleDragStart}>
        <div
          ref={nodeRef}
          className={`modal-dialog ${props.contentClassName || ''}`}
          {...props}
          style={style}
          onClick={(e) => {
            // Only trigger onClick if it's not coming from a child modal button
            if (!e.target.closest('.btn')) {
              onClick?.(e);
            }
          }}
        >
          {children}
        </div>
      </Draggable>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if these props change
    return (
      prevProps.children === nextProps.children &&
      prevProps.width === nextProps.width &&
      prevProps.height === nextProps.height &&
      prevProps.title === nextProps.title &&
      prevProps.style?.zIndex === nextProps.style?.zIndex
    );
  }
);

const ModalContent = React.memo(
  ({ children, footerContent, title, draggable, onHide }) => {
    return (
      <div className="d-flex flex-column h-100">
        <Modal.Header closeButton className={draggable ? 'draggable-header' : ''}>
          <Modal.Title as="h5">
            <img
              src={datasheetSolutionsIcon}
              alt="icon"
              style={{
                display: 'inline-block',
                marginRight: '8px',
                width: '30px',
                height: '30px',
              }}
            />
            <span>{title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="flex-grow-1 d-flex flex-column overflow-auto p-0 ps-3 pe-3 pt-3">{children}</Modal.Body>
        {footerContent && <Modal.Footer>{footerContent}</Modal.Footer>}
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison function to determine if re-render is needed
    return (
      prevProps.children === nextProps.children &&
      prevProps.footerContent === nextProps.footerContent &&
      prevProps.title === nextProps.title &&
      prevProps.draggable === nextProps.draggable
    );
  }
);

const PowerModal = ({ show, onHide, title, footerContent, size, children, draggable, resizable, overlay, onClick, zIndex, width, height }) => {
  const modalContent = useMemo(
    () => (
      <Modal
        show={show}
        onHide={onHide}
        size={size}
        backdrop={overlay ? true : false}
        dialogAs={draggable ? DraggableModalDialog : undefined}
        dialogClassName={`clickable-modal ${resizable ? 'resizable-modal' : ''} ${size} ${draggable ? 'draggable-modal' : ''}`}
        onClick={onClick}
        style={{
          zIndex: overlay ? 1055 : zIndex,
        }}
        title={title}
        width={width}
        height={height}
      >
        <ModalContent title={title} footerContent={footerContent} draggable={draggable} onHide={onHide}>
          {children}
        </ModalContent>
      </Modal>
    ),
    [show, onHide, title, footerContent, size, children, draggable, resizable, overlay, onClick, zIndex, width, height]
  );

  return modalContent;
};

PowerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  footerContent: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  children: PropTypes.node.isRequired,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  overlay: PropTypes.bool,
  onClick: PropTypes.func,
  zIndex: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

PowerModal.defaultProps = {
  title: '',
  footerContent: null,
  size: 'sm',
  draggable: true,
  resizable: false,
  overlay: true,
  onClick: () => {},
  zIndex: 1040,
  width: undefined,
  height: undefined,
};

export default PowerModal;
