import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Badge } from 'react-bootstrap';
import { usePortal } from '../../../../context/Portal';

// Component to render group badges
const GroupBadges = ({ groupIds }) => {
  const { portalConfig } = usePortal();
  const groupMap = useMemo(() => {
    const map = new Map();
    portalConfig?.permissionGroups?.forEach((group) => {
      map.set(group.groupID, group.name);
      map.set(group.groupID.toString(), group.name);
    });
    return map;
  }, [portalConfig?.permissionGroups]);

  if (!groupIds || groupIds.length === 0) {
    return <span className="text-muted">No groups</span>;
  }

  if (!groupMap.size) {
    return <span className="text-muted">Loading groups...</span>;
  }

  return (
    <div className="d-flex flex-wrap gap-1 justify-content-center align-items-center">
      {groupIds.map((groupId) => {
        const groupName = groupMap.get(groupId) || groupMap.get(parseInt(groupId));
        return (
          <Badge
            key={groupId}
            bg="secondary"
            className="text-wrap"
            style={{
              fontSize: '0.8rem',
              fontWeight: 'bold',
              padding: '0.25rem 0.5rem',
              margin: '0.1rem',
            }}
          >
            {groupName || `Deleted Group (${groupId})`}
          </Badge>
        );
      })}
    </div>
  );
};

export const getUserColumnDefinitions = (onEditUser, onDeleteUser) => [
  {
    id: 'firstName',
    header: 'First Name',
    accessorKey: 'firstName',
    defaultVisible: true,
    enableSorting: true,
    enableFiltering: true,
  },
  {
    id: 'lastName',
    header: 'Last Name',
    accessorKey: 'lastName',
    defaultVisible: true,
    enableSorting: true,
    enableFiltering: true,
  },
  {
    id: 'email',
    header: 'Email',
    accessorKey: 'email',
    defaultVisible: true,
    enableSorting: true,
    enableFiltering: true,
  },
  {
    id: 'groups',
    header: 'Groups',
    accessorKey: 'groups',
    defaultVisible: true,
    enableSorting: false,
    meta: {
      align: 'center',
    },
    cell: ({ getValue }) => {
      const groupIds = getValue() || [];
      return <GroupBadges groupIds={groupIds} />;
    },
  },
  {
    id: 'lastLogin',
    header: 'Last Login',
    accessorKey: 'lastLogin',
    defaultVisible: true,
    enableSorting: true,
    enableFiltering: false,
    sortingFn: 'datetime',
    meta: {
      align: 'center',
    },
    cell: ({ getValue }) => {
      const date = getValue();
      return date ? new Date(date).toLocaleDateString() : 'Never';
    },
  },
  {
    id: 'actions',
    header: 'Actions',
    defaultVisible: true,
    enableSorting: false,
    meta: {
      align: 'center',
    },
    cell: ({ row }) => {
      const user = row.original;
      return (
        <div className="d-flex gap-2 justify-content-center">
          <button className="btn btn-lg icon-btn text-primary" onClick={() => onEditUser(user)} title="Edit User">
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button className="btn btn-lg icon-btn text-danger" onClick={() => onDeleteUser(user)} title="Delete User">
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      );
    },
  },
];
