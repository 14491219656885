import React from 'react';
import HelpText from './HelpText';

const StringDisplay = ({ attribute }) => {
  const { name, value, label, displayStyles, displayClasses } = attribute;

  const defaultClasses = {
    component: 'stringDisplay',
    label: 'string-label',
    value: 'string-value',
  };

  const defaultStyles = {
    component: {},
    label: {},
    value: {},
  };

  return (
    <div className={displayClasses?.component || defaultClasses.component} style={displayStyles?.component || defaultStyles.component}>
      <div className={displayClasses?.label || defaultClasses.label} style={displayStyles?.label || defaultStyles.label}>
        {label || name}
      </div>
      <div className={displayClasses?.value || defaultClasses.value} style={displayStyles?.value || defaultStyles.value}>
        {value}
      </div>
    </div>
  );
};

const StringEdit = ({ attribute, onChange }) => {
  const defaultClass = {
    component: 'col-12 col-md-6 p-2',
    label: 'form-label fw-bold',
    input: 'form-control',
  };

  const defaultStyle = {
    component: {},
    label: {},
    style: {},
  };

  const { name, value, label, required, editClasses, editStyles, helpText } = attribute;

  const handleChange = (e) => {
    onChange(name, e.target.value);
  };

  return (
    <div
      className={`stringEditComponent ${editClasses?.component || defaultClass.component}`}
      style={editStyles?.component || defaultStyle.component}
    >
      <label
        htmlFor={`string-edit-${name}`}
        className={'stringEditLabel ' + (editClasses?.label || defaultClass.label)}
        style={editStyles?.label || defaultStyle.label}
      >
        {label || name} {required && <span style={{ color: 'red' }}>*</span>}
        <HelpText text={helpText} />
      </label>
      <input
        type="text"
        className={`stringEditInput ${editClasses?.input || defaultClass.input} ${required && !value ? 'border-danger' : ''}`}
        id={`string-edit-${name}`}
        name={name}
        value={value || ''}
        onChange={handleChange}
        required={required}
        style={editStyles?.input || defaultStyle.input}
      />
    </div>
  );
};

const StringCell = ({ value }) => {
  return <div className="stringCell">{value}</div>;
};

export { StringDisplay, StringEdit, StringCell };
