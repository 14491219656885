import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faXmark } from '@fortawesome/pro-solid-svg-icons';
import ColumnVisibilityToggle from './ColumnVisibilityToggle';

// Helper function for text highlighting
const highlightText = (text, filter) => {
  if (!filter || !text) return text;
  const parts = String(text).split(new RegExp(`(${filter})`, 'gi'));
  return parts.map((part, i) =>
    part.toLowerCase() === filter.toLowerCase() ? (
      <span key={i} className="highlighted-text">
        {part}
      </span>
    ) : (
      part
    )
  );
};

// Generic global filter function that respects enableFiltering
export const getGlobalFilterFn = (columns) => (row, columnId, filterValue) => {
  const column = columns.find((col) => col.id === columnId);
  // Skip filtering for columns where enableFiltering is false
  if (!column?.enableFiltering) return true;

  const value = row.getValue(columnId);
  if (value == null) return false;

  return String(value).toLowerCase().includes(String(filterValue).toLowerCase());
};

export const useFormattedColumns = (columns, globalFilter) => {
  return React.useMemo(() => {
    return columns.map((column) => ({
      ...column,
      cell: (info) => {
        const value = info.getValue();

        // If column has custom cell renderer and is not filterable, use custom renderer
        if (column.cell && !column.enableFiltering) {
          return column.cell(info);
        }

        // If column is filterable and we have a filter, apply highlighting
        if (column.enableFiltering && globalFilter) {
          return highlightText(value, globalFilter);
        }

        // For non-filterable columns or no filter, just return the value
        return value;
      },
      // Ensure filtering is properly disabled at the column level
      enableGlobalFilter: column.enableFiltering,
    }));
  }, [columns, globalFilter]);
};

const TableConfig = ({ table, globalFilter, setGlobalFilter, columnVisibility, onColumnVisibilityChange, title, totalCount }) => {
  const hasFilterableColumns = table.getAllLeafColumns().some((column) => column.columnDef.enableFiltering);

  return (
    <div className="d-flex justify-content-between align-items-center mb-1">
      <h5>
        {title} {totalCount !== undefined && `(${totalCount})`}
      </h5>
      <div className="d-flex align-items-center gap-2">
        {hasFilterableColumns && (
          <InputGroup style={{ width: '200px' }}>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Search..."
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              style={{ paddingRight: '30px' }}
            />
            {globalFilter && (
              <div
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  zIndex: 10,
                  cursor: 'pointer',
                }}
                onClick={() => setGlobalFilter('')}
              >
                <FontAwesomeIcon icon={faXmark} className="text-danger" />
              </div>
            )}
          </InputGroup>
        )}
        <ColumnVisibilityToggle
          columns={table.getAllLeafColumns()}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={onColumnVisibilityChange}
        />
      </div>
    </div>
  );
};

export default TableConfig;
