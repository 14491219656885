import Keycloak from 'keycloak-js';

let subdomain = window.location.hostname.split('.')[0];

const keycloakConfig = {
  url: 'https://sds.zone/auth/',
  realm: 'dss-realm',
  clientId: 'portal-app',
};

const kc = new Keycloak(keycloakConfig);

// Configure token refresh
kc.onTokenExpired = () => {
  console.log('Token expired, refreshing...');
  kc.updateToken(30).catch((error) => {
    console.error('Failed to refresh token:', error);
    // Only force re-login if we're really unable to refresh
    if (error.error === 'invalid_grant') {
      kc.login();
    }
  });
};

// Add a min validity check
const MIN_VALIDITY = 30;

// Add a method to ensure token validity before requests
kc.updateTokenIfNeeded = () => {
  return new Promise((resolve, reject) => {
    kc.updateToken(MIN_VALIDITY)
      .then((refreshed) => {
        if (refreshed) {
          console.log('Token was successfully refreshed');
        } else {
          console.log('Token is still valid');
        }
        resolve(kc.token);
      })
      .catch((error) => {
        console.error('Failed to refresh token:', error);
        reject(error);
      });
  });
};

export default kc;
