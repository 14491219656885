import React, { createContext, useContext, useEffect, useRef, useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const { keycloak } = useKeycloak();
  const wsRef = useRef(null);
  const listenersRef = useRef(new Set());
  const reconnectTimeoutRef = useRef(null);

  const broadcast = useCallback((message) => {
    listenersRef.current.forEach((listener) => listener(message));
  }, []);

  const connectWebSocket = useCallback(() => {
    // Clear any existing connection
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }

    if (!keycloak.token) {
      console.warn('No token available for WebSocket connection');
      return;
    }
    const subdomain = window.location.hostname.split('.')[0];
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const wsUrl =
      process.env.NODE_ENV === 'development'
        ? `${protocol}//${subdomain}.localhost:3499/ws-updates?token=${keycloak.token}`
        : `${protocol}//${subdomain}.sds.zone/api/ws-updates?token=${keycloak.token}`;

    const ws = new WebSocket(wsUrl);
    wsRef.current = ws;

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        broadcast(data);
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    ws.onclose = (event) => {
      console.log('WebSocket closed:', event.code, event.reason);
      wsRef.current = null;

      // Only attempt reconnect if it wasn't a normal closure or token expiration
      if (event.code !== 1000 && event.code !== 1008) {
        // Exponential backoff for reconnection
        const timeout = Math.min(1000 * Math.pow(2, reconnectTimeoutRef.current || 0), 30000);
        reconnectTimeoutRef.current = (reconnectTimeoutRef.current || 0) + 1;

        setTimeout(() => {
          if (keycloak.authenticated) {
            connectWebSocket();
          }
        }, timeout);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Reset reconnect timeout on successful connection
    ws.onopen = () => {
      reconnectTimeoutRef.current = 0;
      console.log('WebSocket connected');
    };
  }, [keycloak.token, broadcast]);

  // Handle token refresh and reconnection
  useEffect(() => {
    if (keycloak.authenticated) {
      // Initial connection
      connectWebSocket();

      // Setup token refresh handler
      const tokenUpdateHandler = () => {
        console.log('Token refreshed, reconnecting WebSocket');
        connectWebSocket();
      };

      keycloak.onTokenExpired = () => {
        console.log('Token expired, updating token...');
        keycloak
          .updateToken(30)
          .then(tokenUpdateHandler)
          .catch((error) => {
            console.error('Failed to refresh token:', error);
            // Close existing connection if token refresh fails
            if (wsRef.current) {
              wsRef.current.close();
              wsRef.current = null;
            }
          });
      };

      return () => {
        if (wsRef.current) {
          wsRef.current.close();
          wsRef.current = null;
        }
        if (reconnectTimeoutRef.current) {
          clearTimeout(reconnectTimeoutRef.current);
          reconnectTimeoutRef.current = null;
        }
        keycloak.onTokenExpired = null;
      };
    }
  }, [keycloak.authenticated, connectWebSocket, keycloak]);

  const addListener = useCallback((listener) => {
    listenersRef.current.add(listener);
    return () => listenersRef.current.delete(listener);
  }, []);

  return <WebSocketContext.Provider value={{ addListener }}>{children}</WebSocketContext.Provider>;
};

export const useWebSocket = (messageHandler) => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }

  useEffect(() => {
    if (messageHandler) {
      return context.addListener(messageHandler);
    }
  }, [context, messageHandler]);

  return context;
};
