import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import NavigationBar from './sub-components/NavigationBar';
import LifecycleBar from './sub-components/LifecycleBar';
import Editor from './sub-components/editor/Editor';
import LifecycleTable from './tables/LifecycleTable';
import PhotoSwipe from '../portal/pdf-viewer/Photoswipe';
import { useGet } from '../../hooks/useGet';
import { usePost } from '../../hooks/usePost';
import { useKeycloak } from '@react-keycloak/web';
import { usePortal } from '../../context/Portal';
import { useWebSocket } from '../../context/WebSocketContext';
import TableFilters from './tables/TableFilter';
import Fuse from 'fuse.js';
import pako from 'pako';
import '../../Admin.css';
import './Admin.css';
import Cookies from 'js-cookie';
import Reports from './sub-components/Reports';
import Users from './sub-components/user-administration/Users';
import AddSds from './sub-components/add-sds/AddSds';
import { Toaster } from './Toaster';

// Helper functions for highlighting (copied from Paginated-SDS-Item.js)
const highlightTextOrArray = (textOrArray, fieldMatches) => {
  if (Array.isArray(textOrArray)) {
    return highlightTextArray(textOrArray, fieldMatches);
  } else if (React.isValidElement(textOrArray)) {
    return textOrArray;
  } else if (typeof textOrArray === 'object' && textOrArray !== null) {
    try {
      return highlightText(JSON.stringify(textOrArray), fieldMatches);
    } catch (error) {
      return '[Complex Object]';
    }
  } else {
    return highlightText(String(textOrArray), fieldMatches);
  }
};

const highlightTextArray = (textArray, fieldMatches) => {
  return (
    <React.Fragment>
      {textArray.map((item, index) => {
        let displayItem;
        if (React.isValidElement(item)) {
          displayItem = item;
        } else if (typeof item === 'object' && item !== null) {
          try {
            displayItem = JSON.stringify(item);
          } catch (error) {
            displayItem = '[Complex Object]';
          }
        } else {
          displayItem = String(item);
        }

        const itemMatches = fieldMatches.filter((match) => {
          if (React.isValidElement(displayItem)) {
            // Skip matching for React elements
            return false;
          }
          return match.indices.some(([start, end]) => {
            const substring = match.value.substring(start, end + 1);
            return String(displayItem).includes(substring) && substring.length >= 3;
          });
        });

        const highlightedItem = React.isValidElement(displayItem) ? displayItem : highlightText(String(displayItem), itemMatches);

        return (
          <React.Fragment key={index}>
            {highlightedItem}
            {index < textArray.length - 1 && ', '}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const highlightText = (text, fieldMatches) => {
  if (!text || !fieldMatches || fieldMatches.length === 0) {
    return text;
  }

  const indices = fieldMatches.flatMap((match) =>
    match.indices
      .filter(([start, end]) => {
        const substring = match.value.substring(start, end + 1);
        return text.includes(substring) && substring.length >= 3;
      })
      .map(([start, end]) => [
        text.indexOf(match.value.substring(start, end + 1)),
        text.indexOf(match.value.substring(start, end + 1)) + (end - start),
      ])
  );

  if (indices.length === 0) return text;

  // Merge overlapping indices
  const mergedIndices = indices.reduce((acc, curr) => {
    if (acc.length === 0) return [curr];
    const last = acc[acc.length - 1];
    if (curr[0] <= last[1]) {
      last[1] = Math.max(last[1], curr[1]);
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);

  let result = [];
  let lastIndex = 0;

  mergedIndices.forEach(([start, end], index) => {
    if (start > lastIndex) {
      result.push(text.substring(lastIndex, start));
    }
    const highlightedText = text.substring(start, end + 1);
    result.push(
      <span key={`highlight-${index}`} className="highlighted-text">
        {highlightedText}
      </span>
    );
    lastIndex = end + 1;
  });

  if (lastIndex < text.length) {
    result.push(text.substring(lastIndex));
  }

  return result.map((item, index) => (typeof item === 'string' ? <React.Fragment key={`text-${index}`}>{item}</React.Fragment> : item));
};

const AdminContent = () => {
  const { getData, data, isLoading: getLoading, error: getError } = useGet();
  const { postData, response: searchResponse, isLoading: searchLoading, error: searchError } = usePost();

  const [keywordSearch, setKeywordSearch] = useState({
    searchTerm: '',
    searchType: 'basic',
    searchOptions: {
      keys: ['productIdentifier', 'manName', 'aka'],
      threshold: 0.3,
      ignoreLocation: true,
      minMatchCharLength: 2,
      includeMatches: true,
    },
    contentSearchResults: null,
    isSearching: false,
    searchError: null,
  });

  const { portalConfig } = usePortal();
  const [records, setRecords] = useState({
    pending: [],
    inprocess: [],
    active: [],
    archived: [],
  });
  const [tableFilter, setTableFilter] = useState([]);

  // Function to get fileHashes from records
  const getFileHashesFromRecords = useCallback((records) => {
    const hashes = new Set();
    records.forEach((record) => {
      if (record.fileHash) {
        hashes.add(record.fileHash);
      }
    });
    return Array.from(hashes);
  }, []);

  const filterData = useCallback(
    (data) => {
      return data.filter((record) => {
        return tableFilter.every((filter) => {
          const { propertyName, filterValue } = filter;

          // Special handling for locations
          if (propertyName === 'locations') {
            // Ensure we're comparing with strings
            const recordLocations = record[propertyName]?.map(String) || [];
            return recordLocations.includes(String(filterValue));
          }

          // Handle other array properties
          if (Array.isArray(record[propertyName])) {
            return record[propertyName].includes(filterValue);
          }

          // Handle regular properties
          return record[propertyName] === filterValue;
        });
      });
    },
    [tableFilter]
  );

  const filteredRecords = useMemo(() => {
    // First apply regular filters
    const filtered = {
      pending: filterData(records.pending),
      inprocess: filterData(records.inprocess),
      active: filterData(records.active),
      archived: filterData(records.archived),
    };

    if (keywordSearch.searchTerm.length >= 2) {
      if (keywordSearch.searchType === 'basic') {
        // Basic Fuse.js search
        const fuse = new Fuse(Object.values(filtered).flat(), keywordSearch.searchOptions);
        const searchResults = fuse.search(keywordSearch.searchTerm);

        const highlightedResults = searchResults.map((result) => {
          const highlightedItem = { ...result.item };
          result.matches.forEach((match) => {
            const value = highlightedItem[match.key];
            highlightedItem[match.key] = highlightTextOrArray(value, [match]);
          });
          return highlightedItem;
        });

        return {
          pending: highlightedResults.filter((result) => result.status === 'pending'),
          inprocess: highlightedResults.filter((result) => result.status === 'inprocess'),
          active: highlightedResults.filter((result) => result.status === 'active'),
          archived: highlightedResults.filter((result) => result.status === 'archived'),
        };
      } else if (keywordSearch.searchType === 'contents' && keywordSearch.contentSearchResults) {
        // Content search - filter by matching fileHashes
        const matchingHashes = new Set(keywordSearch.contentSearchResults.map((result) => result.fileHash));

        return {
          pending: filtered.pending.filter((record) => matchingHashes.has(record.fileHash)),
          inprocess: filtered.inprocess.filter((record) => matchingHashes.has(record.fileHash)),
          active: filtered.active.filter((record) => matchingHashes.has(record.fileHash)),
          archived: filtered.archived.filter((record) => matchingHashes.has(record.fileHash)),
        };
      }
    }

    return filtered;
  }, [records, filterData, keywordSearch]);

  // Function to perform content search
  const performContentSearch = useCallback(
    async (searchTerm) => {
      try {
        // Get currently filtered records before any content search filtering
        const currentFiltered = {
          pending: filterData(records.pending),
          inprocess: filterData(records.inprocess),
          active: filterData(records.active),
          archived: filterData(records.archived),
        };

        // Get fileHashes from all filtered records
        const fileHashes = getFileHashesFromRecords(Object.values(currentFiltered).flat());

        console.log('Content search with filtered hashes:', {
          totalHashes: fileHashes.length,
          searchTerm,
        });

        setKeywordSearch((prev) => ({
          ...prev,
          isSearching: true,
          searchError: null,
        }));

        // Compress the fileHashes array
        const compressedHashes = pako.deflate(JSON.stringify(fileHashes));

        // Convert Uint8Array to string
        const binaryString = Array.from(compressedHashes)
          .map((byte) => String.fromCharCode(byte))
          .join('');

        // Convert to base64 using btoa
        const base64Compressed = btoa(binaryString);

        const response = await postData('/portal/content-search', {
          searchTerm,
          compressedHashes: base64Compressed,
          isCompressed: true,
        });

        // Make search results available globally for the table
        window.contentSearchResults = response?.matches || [];

        setKeywordSearch((prev) => ({
          ...prev,
          isSearching: false,
          contentSearchResults: response?.matches || null,
        }));

        if (searchError) {
          throw new Error(searchError);
        }

        return response?.matches || null;
      } catch (error) {
        console.error('Error performing content search:', error);
        setKeywordSearch((prev) => ({
          ...prev,
          isSearching: false,
          searchError: error.message,
        }));
        return null;
      }
    },
    [records, filterData, getFileHashesFromRecords, postData, searchError]
  );

  const handleSearchChange = useCallback(
    async (searchTerm, searchType) => {
      // Reset pagination
      setPaginationState((prevState) => ({
        ...prevState,
        pendingTable: { ...prevState.pendingTable, pageIndex: 0 },
        inprocessTable: { ...prevState.inprocessTable, pageIndex: 0 },
        activeTable: { ...prevState.activeTable, pageIndex: 0 },
        archivedTable: { ...prevState.archivedTable, pageIndex: 0 },
      }));

      // If switching to content search, perform the search
      if (searchType === 'contents' && searchTerm.length >= 2) {
        const contentResults = await performContentSearch(searchTerm);

        // Show search results column if we have results
        if (contentResults && contentResults.length > 0) {
          setColumnVisibilityState((prevState) => ({
            ...prevState,
            pendingTable: { ...prevState.pendingTable, searchResults: true },
            inprocessTable: { ...prevState.inprocessTable, searchResults: true },
            activeTable: { ...prevState.activeTable, searchResults: true },
            archivedTable: { ...prevState.archivedTable, searchResults: true },
          }));
        }

        setKeywordSearch((prevState) => ({
          ...prevState,
          searchTerm,
          searchType,
          contentSearchResults: contentResults,
        }));
      } else {
        // Clear content search results and hide the column
        window.contentSearchResults = [];

        setColumnVisibilityState((prevState) => ({
          ...prevState,
          pendingTable: { ...prevState.pendingTable, searchResults: false },
          inprocessTable: { ...prevState.inprocessTable, searchResults: false },
          activeTable: { ...prevState.activeTable, searchResults: false },
          archivedTable: { ...prevState.archivedTable, searchResults: false },
        }));

        setKeywordSearch((prevState) => ({
          ...prevState,
          searchTerm,
          searchType,
          contentSearchResults: null,
          searchError: null,
        }));
      }
    },
    [performContentSearch]
  );

  const { keycloak } = useKeycloak();
  const wsRef = useRef(null); // Use a ref to store the WebSocket instance

  const [paginationState, setPaginationState] = useState({
    pendingTable: { pageIndex: 0, pageSize: 10 },
    inprocessTable: { pageIndex: 0, pageSize: 10 },
    activeTable: { pageIndex: 0, pageSize: 10 },
    archivedTable: { pageIndex: 0, pageSize: 10 },
  });

  const [columnVisibilityState, setColumnVisibilityState] = useState({
    pendingTable: {},
    inprocessTable: {},
    activeTable: {},
    archivedTable: {},
  });

  const [pendingTableSelection, setPendingTableSelection] = useState({});
  const [inprocessTableSelection, setInprocessTableSelection] = useState({});
  const [activeTableSelection, setActiveTableSelection] = useState({});
  const [archivedTableSelection, setArchivedTableSelection] = useState({});

  const tableSelectionState = {
    pendingTable: { state: pendingTableSelection, setState: setPendingTableSelection },
    inprocessTable: { state: inprocessTableSelection, setState: setInprocessTableSelection },
    activeTable: { state: activeTableSelection, setState: setActiveTableSelection },
    archivedTable: { state: archivedTableSelection, setState: setArchivedTableSelection },
  };

  // Ensure that tableSelectionState is accessed safely
  const getTableSelectionState = (tableType) => {
    return tableSelectionState[tableType] || { state: {}, setState: () => {} };
  };

  const setPageIndex = (tableType, pageIndex) => {
    setPaginationState((prevState) => ({
      ...prevState,
      [tableType]: { ...prevState[tableType], pageIndex },
    }));
  };

  const setPageSize = (tableType, pageSize) => {
    setPaginationState((prevState) => ({
      ...prevState,
      [tableType]: { pageIndex: 0, pageSize },
    }));
  };

  const setColumnVisibility = useCallback((tableType, newVisibility) => {
    setColumnVisibilityState((prev) => ({
      ...prev,
      [tableType]: newVisibility,
    }));
  }, []);

  // Initialize column visibility from cookies on mount
  useEffect(() => {
    const tables = ['pendingTable', 'inprocessTable', 'activeTable', 'archivedTable'];
    const initialVisibility = {};

    tables.forEach((tableType) => {
      const cookieKey = `columnVisibility_${tableType}`;
      const savedVisibility = Cookies.get(cookieKey);
      if (savedVisibility) {
        try {
          initialVisibility[tableType] = JSON.parse(savedVisibility);
        } catch (error) {
          console.error(`Error parsing column visibility cookie for ${tableType}:`, error);
          // Don't set empty object as default
          // initialVisibility[tableType] = {};
        }
      }
    });

    // Only update state if we have actual saved values
    if (Object.keys(initialVisibility).length > 0) {
      setColumnVisibilityState((prev) => ({
        ...prev,
        ...initialVisibility,
      }));
    }
  }, []); // Run only on mount

  useEffect(() => {
    getData('/admin/records');
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setRecords({
        pending: data.filter((record) => record.status === 'pending'),
        inprocess: data.filter((record) => record.status === 'inprocess'),
        active: data.filter((record) => record.status === 'active'),
        archived: data.filter((record) => record.status === 'archived'),
      });
    }
  }, [data]);

  const handleWebSocketMessage = useCallback(
    (data) => {
      if (data.collection === 'ClientRecord') {
        if (data.type === 'batch') {
          data.changes.forEach((change) => {
            if (change.operationType === 'update' || change.operationType === 'insert') {
              const updatedRecord = change.fullDocument;
              const newStatus = updatedRecord.status;

              setRecords((prevRecords) => {
                const newRecords = { ...prevRecords };
                let oldStatus = null;

                // Find old status
                Object.entries(prevRecords).forEach(([status, statusRecords]) => {
                  if (statusRecords.some((record) => record._id === updatedRecord._id)) {
                    oldStatus = status;
                  }
                });

                // Handle record movement between statuses
                if (oldStatus && oldStatus !== newStatus) {
                  // Remove from old status
                  newRecords[oldStatus] = prevRecords[oldStatus].filter((record) => record._id !== updatedRecord._id);

                  // Clear selection
                  const oldTableType = `${oldStatus}Table`;
                  const selectionState = tableSelectionState[oldTableType];
                  if (selectionState) {
                    selectionState.setState((prev) => {
                      const newSelection = { ...prev };
                      delete newSelection[updatedRecord._id];
                      return newSelection;
                    });
                  }
                }

                // Update or add to new status
                if (newStatus in newRecords) {
                  const statusRecords = [...prevRecords[newStatus]];
                  const existingIndex = statusRecords.findIndex((record) => record._id === updatedRecord._id);

                  if (existingIndex !== -1) {
                    statusRecords[existingIndex] = updatedRecord;
                  } else {
                    statusRecords.push(updatedRecord);
                  }

                  newRecords[newStatus] = statusRecords;
                }

                return newRecords;
              });
            }
          });
        }
      }
    },
    [tableSelectionState] // Stable dependency
  );

  useWebSocket(handleWebSocketMessage);

  const counts = useMemo(
    () => ({
      pending: filteredRecords.pending.length,
      inprocess: filteredRecords.inprocess.length,
      active: filteredRecords.active.length,
      archived: filteredRecords.archived.length,
    }),
    [filteredRecords]
  );

  const handleRemoveFilter = (indexToRemove) => {
    setTableFilter((prevFilters) => prevFilters.filter((_, index) => index !== indexToRemove));
    // Reset pageIndex to 0 for all table types
    setPaginationState((prevState) => ({
      ...prevState,
      pendingTable: { ...prevState.pendingTable, pageIndex: 0 },
      inprocessTable: { ...prevState.inprocessTable, pageIndex: 0 },
      activeTable: { ...prevState.activeTable, pageIndex: 0 },
      archivedTable: { ...prevState.archivedTable, pageIndex: 0 },
    }));
  };

  const handleAddFilter = (propertyName, filterValue, display) => {
    setTableFilter((prevFilters) => {
      // Check if the filter already exists
      const existingFilterIndex = prevFilters.findIndex((filter) => filter.propertyName === propertyName && filter.filterValue === filterValue);

      if (existingFilterIndex !== -1) {
        // If the filter already exists, don't add it again
        return prevFilters;
      }

      // Add the new filter with the optional display property
      return [...prevFilters, { propertyName, filterValue, display }];
    });
    // Reset pageIndex to 0 for all table types
    setPaginationState((prevState) => ({
      ...prevState,
      pendingTable: { ...prevState.pendingTable, pageIndex: 0 },
      inprocessTable: { ...prevState.inprocessTable, pageIndex: 0 },
      activeTable: { ...prevState.activeTable, pageIndex: 0 },
      archivedTable: { ...prevState.archivedTable, pageIndex: 0 },
    }));
  };

  const ViewRecord = () => {
    const { recordId } = useParams();

    const record = useMemo(() => {
      const allRecords = [...records.active, ...records.archived, ...records.inprocess, ...records.pending];

      return allRecords.find((r) => String(r.recordId) === String(recordId));
    }, [records, recordId]);

    if (!record) {
      return <div>Record not found for ID: {recordId}</div>;
    }
    console.log('viewing-record', record);

    return (
      <PhotoSwipe
        pdfRendered={record}
        setPdfRendered={() => {}}
        subdomain={portalConfig.clientSubdomain}
        isMobile={true}
        userSettings={{ showPhotos: true }}
        onClose={() => window.history.back()}
      />
    );
  };

  // Add these new states for column visibility and leaf columns
  const [leafColumnsState, setLeafColumnsState] = useState({
    pendingTable: null,
    inprocessTable: null,
    activeTable: null,
    archivedTable: null,
  });

  const initializeLeafColumns = useCallback((tableType, columns) => {
    setLeafColumnsState((prevState) => {
      if (prevState[tableType] === null) {
        return {
          ...prevState,
          [tableType]: columns,
        };
      }
      return prevState;
    });
  }, []);

  // Add this near your other state declarations
  const [showReports, setShowReports] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [showAddSds, setShowAddSds] = useState(false);

  // Update hash change handler to include AddSds
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      console.log('hash', hash);
      setShowReports(hash === '#reports');
      setShowUsers(hash === '#users');
      setShowAddSds(hash === '#add-sds');
    };

    // Initial check
    handleHashChange();

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  const handleReportsClose = () => {
    setShowReports(false);
    window.location.hash = '';
  };

  const handleUsersClose = () => {
    setShowUsers(false);
    window.location.hash = '';
  };

  const handleAddSdsClose = () => {
    setShowAddSds(false);
    window.location.hash = '';
  };

  // Add sorting state
  const [sortingState, setSortingState] = useState({
    pendingTable: [],
    inprocessTable: [],
    activeTable: [],
    archivedTable: [],
  });

  const handleSortingChange = useCallback((updater, tableType) => {
    setSortingState((prev) => {
      const newSorting = typeof updater === 'function' ? updater(prev[tableType]) : updater;
      const cookieKey = `sorting_${tableType}`;
      Cookies.set(cookieKey, JSON.stringify(newSorting), { expires: 365 });
      return {
        ...prev,
        [tableType]: newSorting,
      };
    });
  }, []);

  // Effect to initialize sorting state from cookies
  useEffect(() => {
    const tables = ['pendingTable', 'inprocessTable', 'activeTable', 'archivedTable'];
    const initialSortingState = {};

    tables.forEach((tableType) => {
      const cookieKey = `sorting_${tableType}`;
      const savedSorting = Cookies.get(cookieKey);
      if (savedSorting) {
        try {
          initialSortingState[tableType] = JSON.parse(savedSorting);
        } catch (error) {
          console.error(`Error parsing sorting cookie for ${tableType}:`, error);
          initialSortingState[tableType] = [];
        }
      } else {
        initialSortingState[tableType] = [];
      }
    });

    setSortingState(initialSortingState);
  }, []);

  // Add loading and error indicators to the UI where appropriate
  const isLoading = getLoading || searchLoading || keywordSearch.isSearching;
  const error = getError || searchError || keywordSearch.searchError;

  // Add the Reports component render near your main content
  return (
    <Container fluid className="d-flex flex-column p-0 min-vh-100" style={{ backgroundColor: 'white' }}>
      <NavigationBar />
      <Routes>
        <Route path="view/:recordId" element={<ViewRecord />} />
        <Route path="edit/:recordId" element={<Editor records={records} />} />
        <Route
          path="*"
          element={
            <>
              <LifecycleBar
                counts={counts}
                onSearchChange={handleSearchChange}
                searchTerm={keywordSearch.searchTerm}
                searchType={keywordSearch.searchType}
              />

              <div id="tableArea" className="main-content-new d-flex flex-column flex-grow-1">
                <div className="table-filters-container flex-shrink-0 mx-2">
                  <TableFilters filters={tableFilter} onRemoveFilter={handleRemoveFilter} />
                </div>

                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error: {error.message}</p>
                ) : (
                  <Routes>
                    {/* Table routes with nested reports */}
                    {['pending', 'in-process', 'active', 'archived'].map((status) => {
                      const tableType = `${status.replace('-', '')}Table`;
                      return (
                        <Route
                          key={status}
                          path={status}
                          element={
                            <>
                              <LifecycleTable
                                data={filteredRecords[status.replace('-', '')]}
                                tableType={tableType}
                                pageIndex={paginationState[tableType]?.pageIndex || 0}
                                pageSize={paginationState[tableType]?.pageSize || 10}
                                setPageIndex={(pageIndex) => setPageIndex(tableType, pageIndex)}
                                setPageSize={(pageSize) => setPageSize(tableType, pageSize)}
                                onAddFilter={handleAddFilter}
                                columnVisibility={columnVisibilityState[tableType]}
                                setColumnVisibility={(visibility) => setColumnVisibility(tableType, visibility)}
                                rowSelection={getTableSelectionState(tableType).state}
                                setRowSelection={(updater) => {
                                  if (typeof updater === 'function') {
                                    getTableSelectionState(tableType).setState(updater(getTableSelectionState(tableType).state));
                                  } else {
                                    getTableSelectionState(tableType).setState(updater);
                                  }
                                }}
                                sorting={sortingState[tableType]}
                                onSortingChange={(updater) => handleSortingChange(updater, tableType)}
                              />
                            </>
                          }
                        />
                      );
                    })}

                    {/* Fallback route */}
                    <Route path="/" element={<Navigate to="active" replace />} />
                  </Routes>
                )}
              </div>
            </>
          }
        />
      </Routes>
      {showReports && <Reports onClose={handleReportsClose} />}
      {showUsers && <Users onClose={handleUsersClose} />}
      {showAddSds && <AddSds onClose={handleAddSdsClose} />}
    </Container>
  );
};

const Admin = () => {
  return (
    <>
      <AdminContent />
      <Toaster />
    </>
  );
};

export default Admin;
