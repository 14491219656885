import React, { useState, useCallback, useRef } from 'react';
import { Form, Badge, Button } from 'react-bootstrap';
import { DndContext, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import { KeyboardSensor, PointerSensor } from '@dnd-kit/core';
import { useModal } from 'context/ModalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import HelpText from './HelpText';
import { usePost } from 'hooks/usePost';

const preprocessImageUrl = (photoURL, isThumbnail = false) => {
  const subdomain = window.location.hostname.split('.')[0];
  const baseUrl = process.env.REACT_APP_API_URL;
  const resourcePath = isThumbnail ? 'thumbnail' : 'resources';
  return process.env.REACT_APP_DEV === 'true' ? `${baseUrl}/${resourcePath}/${subdomain}/${photoURL}` : `${baseUrl}/${resourcePath}/${photoURL}`;
};

const LoadingIndicator = () => (
  <div className="position-fixed top-50 start-50 translate-middle">
    <i className="fas fa-cog fa-spin fa-3x"></i>
  </div>
);

const PhotoArrayDisplay = ({ attribute }) => {
  const { showModal } = useModal();
  const { name, label, value = [], displayStyles, displayClasses } = attribute;
  const [isLoading, setIsLoading] = useState(false);

  const defaultClasses = {
    component: 'photoArrayDisplay',
    label: 'photoArray-label',
    value: 'photoArray-value d-flex flex-wrap gap-2',
  };

  const defaultStyles = {
    component: {},
    label: {},
    value: {},
  };

  const preloadImage = useCallback((url) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }, []);

  const handleImageClick = useCallback(
    async (index) => {
      setIsLoading(true);
      try {
        await preloadImage(preprocessImageUrl(value[index]));
        showModal({
          title: 'Product Image',
          draggable: true,
          resizable: true,
          overlay: false,
          children: (
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={preprocessImageUrl(value[index])}
                className="img-fluid"
                style={{
                  maxHeight: '75vh',
                  maxWidth: '75vw',
                  objectFit: 'contain',
                }}
                alt="Product"
              />
            </div>
          ),
        });
      } finally {
        setIsLoading(false);
      }
    },
    [value, showModal, preloadImage]
  );

  return (
    <div className={displayClasses?.component || defaultClasses.component} style={displayStyles?.component || defaultStyles.component || {}}>
      <div className={displayClasses?.label || defaultClasses.label} style={displayStyles?.label || defaultStyles.label || {}}>
        {label || name}:
      </div>
      <div className={displayClasses?.value || defaultClasses.value} style={displayStyles?.value || defaultStyles.value || {}}>
        {Array.isArray(value) && value.length > 0 ? (
          value.map((photo, index) => (
            <div key={index} className="position-relative d-inline-block">
              <img
                src={preprocessImageUrl(photo, true)}
                alt={`Product ${index + 1}`}
                className="img-thumbnail"
                style={{
                  cursor: 'pointer',
                  maxHeight: '100px',
                  opacity: isLoading ? 0.5 : 1,
                }}
                onClick={() => handleImageClick(index)}
              />
            </div>
          ))
        ) : (
          <span className="text-muted">No images uploaded</span>
        )}
      </div>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

const SortablePhoto = ({ id, url, onRemove, styles, classes }) => {
  const { showModal } = useModal();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const [isLoading, setIsLoading] = useState(false);

  const preloadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  };

  const handleImageClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    try {
      await preloadImage(preprocessImageUrl(url));
      showModal({
        title: 'Product Image',
        draggable: true,
        resizable: true,
        overlay: false,
        size: 'sm',
        width: '25%',
        children: (
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={preprocessImageUrl(url)}
              className="img-fluid"
              style={{
                maxHeight: '75vh',
                maxWidth: '75vw',
                objectFit: 'contain',
              }}
              alt="Product"
            />
          </div>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    position: 'relative',
    display: 'inline-block',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="position-relative">
        {isLoading && (
          <div className="position-absolute top-50 start-50 translate-middle" style={{ zIndex: 2 }}>
            <i className="fas fa-cog fa-spin"></i>
          </div>
        )}
        <img
          src={preprocessImageUrl(url, true)}
          alt="Product"
          className="img-thumbnail"
          style={{
            maxHeight: '100px',
            cursor: 'pointer',
            opacity: isLoading ? 0.5 : 1,
          }}
          onClick={handleImageClick}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
          style={{
            position: 'absolute',
            top: '-8px',
            right: '-8px',
            background: 'white',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #6c757d',
            padding: 0,
            cursor: 'pointer',
            zIndex: 1,
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
        >
          <FontAwesomeIcon icon={faTimes} style={{ color: '#dc3545', fontSize: '0.75rem' }} />
        </button>
      </div>
    </div>
  );
};

const PhotoArrayEdit = ({ attribute, onChange }) => {
  const { name, label, value = [], required, helpText, editClasses, editStyles } = attribute;
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const { postData } = usePost();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const defaultClasses = {
    component: 'col-12 p-2',
    label: 'form-label fw-bold',
    photoContainer: 'd-flex flex-wrap gap-2 mb-2',
  };

  const defaultStyles = {
    component: {},
    label: {},
    photoContainer: { minHeight: '38px' },
  };

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = value.findIndex((url) => url === active.id);
      const newIndex = value.findIndex((url) => url === over.id);
      const newValue = arrayMove(value, oldIndex, newIndex);
      onChange(name, newValue);
    }
  };

  const handleRemovePhoto = (index) => {
    const newValue = value.filter((_, i) => i !== index);
    onChange(name, newValue);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files.length) return;

    setUploading(true);
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i]);
    }

    try {
      const { urls } = await postData('/admin/upload-images', formData, {
        headers: {
          Accept: 'application/json',
        },
        transformRequest: [(data) => data],
      });

      // Remove subdomain from URLs before saving
      const cleanUrls = urls.map((url) => url.split('/').slice(-2).join('/'));
      onChange(name, [...value, ...cleanUrls]);
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div className={editClasses?.component || defaultClasses.component} style={editStyles?.component || defaultStyles.component}>
      <Form.Label className={editClasses?.label || defaultClasses.label} style={editStyles?.label || defaultStyles.label}>
        {label || name} {required && <span style={{ color: 'red' }}>*</span>}
        <HelpText text={helpText} />
      </Form.Label>

      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={value} strategy={rectSortingStrategy}>
          <div
            className={editClasses?.photoContainer || defaultClasses.photoContainer}
            style={editStyles?.photoContainer || defaultStyles.photoContainer}
          >
            {value.map((url, index) => (
              <SortablePhoto key={url} id={url} url={url} onRemove={() => handleRemovePhoto(index)} styles={editStyles} classes={editClasses} />
            ))}
          </div>
        </SortableContext>
      </DndContext>

      <Form.Group>
        <Form.Control ref={fileInputRef} type="file" accept="image/*" multiple onChange={handleFileUpload} disabled={uploading} />
      </Form.Group>
    </div>
  );
};

const PhotoArrayCell = ({ value }) => {
  const { showModal, updateModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const createModalId = useCallback((index) => {
    return `photo-modal-${index}-${Date.now()}`;
  }, []);

  const preloadImage = useCallback((url) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }, []);

  const handlePrev = useCallback(
    async (index, modalId) => {
      const prevIndex = index === 0 ? value.length - 1 : index - 1;
      updateModal(modalId, { children: <LoadingIndicator /> });
      await preloadImage(preprocessImageUrl(value[prevIndex]));
      updateModal(modalId, {
        children: renderModalContent(prevIndex, modalId),
      });
    },
    [value, updateModal, preloadImage]
  );

  const handleNext = useCallback(
    async (index, modalId) => {
      const nextIndex = index === value.length - 1 ? 0 : index + 1;
      updateModal(modalId, { children: <LoadingIndicator /> });
      await preloadImage(preprocessImageUrl(value[nextIndex]));
      updateModal(modalId, {
        children: renderModalContent(nextIndex, modalId),
      });
    },
    [value, updateModal, preloadImage]
  );

  const renderModalContent = useCallback(
    (index, modalId) => (
      <div className="d-flex justify-content-center align-items-center">
        {value.length > 1 && (
          <Button variant="outline-primary" onClick={() => handlePrev(index, modalId)} className="me-3">
            <i className="fa-sharp fa-solid fa-arrow-left"></i>
          </Button>
        )}
        <img
          src={preprocessImageUrl(value[index])}
          className="img-fluid"
          style={{
            maxHeight: '75vh',
            maxWidth: '75vw',
            objectFit: 'contain',
          }}
          alt="Product"
        />
        {value.length > 1 && (
          <Button variant="outline-primary" onClick={() => handleNext(index, modalId)} className="ms-3">
            <i className="fa-sharp fa-solid fa-arrow-right"></i>
          </Button>
        )}
      </div>
    ),
    [value, handlePrev, handleNext]
  );

  const handleShow = useCallback(
    async (index) => {
      setIsLoading(true);
      try {
        await preloadImage(preprocessImageUrl(value[index]));
        const modalId = createModalId(index);
        showModal({
          id: modalId,
          title: 'Product Image',
          draggable: true,
          resizable: true,
          overlay: false,
          size: 'sm',
          width: '25%',
          children: renderModalContent(index, modalId),
        });
      } finally {
        setIsLoading(false);
      }
    },
    [showModal, value, createModalId, renderModalContent]
  );

  if (!value || value.length === 0) {
    return null;
  }

  return (
    <div className="photoArrayCell">
      <div className="position-relative d-inline-block">
        <img
          src={preprocessImageUrl(value[0], true)}
          alt="Product"
          className="img-thumbnail"
          style={{
            cursor: 'pointer',
            maxHeight: '100px',
            opacity: isLoading ? 0.5 : 1,
          }}
          onClick={() => handleShow(0)}
        />
        {value.length > 1 && (
          <Badge pill bg="info" className="position-absolute top-0 end-0">
            {value.length}
          </Badge>
        )}
      </div>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export { PhotoArrayDisplay, PhotoArrayEdit, PhotoArrayCell };
