import React from 'react';
import { Card } from 'react-bootstrap';

const ManualEntry = () => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Manual Product Entry</Card.Title>
        <Card.Text>This section will allow you to manually enter product information and upload associated SDS documents.</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ManualEntry;
