import React, { useState, useRef, useEffect } from 'react';
import { Form, Badge, InputGroup, Button } from 'react-bootstrap';
import { DndContext, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import { KeyboardSensor, PointerSensor } from '@dnd-kit/core';
import HelpText from './HelpText';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StringArrayDisplay = ({ attribute }) => {
  const { name, label, value, displayStyles, displayClasses } = attribute;

  const defaultClasses = {
    component: 'stringArrayDisplay',
    label: 'stringArray-label',
    value: 'stringArray-value',
  };

  const defaultStyles = {
    component: {},
    label: {},
    value: {},
  };

  return (
    <div className={displayClasses?.component || defaultClasses.component} style={displayStyles?.component || defaultStyles.component || {}}>
      <div className={displayClasses?.label || defaultClasses.label} style={displayStyles?.label || defaultStyles.label || {}}>
        {label || name}:
      </div>
      <div className={displayClasses?.value || defaultClasses.value} style={displayStyles?.value || defaultStyles.value || {}}>
        {Array.isArray(value) &&
          value.map((item, index) => (
            <Badge key={index} bg="secondary" className="me-1 mb-1">
              {item}
            </Badge>
          ))}
      </div>
    </div>
  );
};

const StringArrayEdit = ({ attribute, onChange }) => {
  const { name, label, value = [], required, helpText, editClasses, editStyles } = attribute;
  const [newItem, setNewItem] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [newItemIndices, setNewItemIndices] = useState([]);
  const formRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target) && editingIndex !== null) {
        setEditingIndex(null);
        setNewItem('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingIndex]);

  const defaultClasses = {
    component: 'col-12 p-2',
    label: 'form-label fw-bold',
    input: 'form-control',
    badgeContainer: 'd-flex flex-wrap gap-1 mb-2 justify-content-start',
    badge: 'flex-grow-0',
  };

  const defaultStyles = {
    component: {},
    label: {},
    input: {},
    badgeContainer: { minHeight: '38px' },
    badge: {
      display: 'inline-block',
      maxWidth: '100%',
      whiteSpace: 'normal',
      textAlign: 'left',
      wordBreak: 'break-word',
      cursor: 'grab',
      padding: '0.5rem',
      transition: 'background-color 0.3s ease',
      position: 'relative',
      zIndex: 1,
      margin: '0.25rem',
    },
    removeButton: {
      position: 'absolute',
      top: '-8px',
      right: '-8px',
      background: 'white',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #6c757d',
      padding: 0,
      cursor: 'pointer',
      zIndex: 1,
    },
    removeIcon: {
      color: '#dc3545',
      fontSize: '0.75rem',
    },
  };

  const classes = { ...defaultClasses, ...editClasses };
  const styles = { ...defaultStyles, ...editStyles };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleAddItem = (e) => {
    e.preventDefault();
    if (!newItem.trim()) return;

    if (editingIndex !== null) {
      const updatedValue = [...value];
      updatedValue[editingIndex] = newItem.trim();
      onChange(name, updatedValue);
      setEditingIndex(null);
    } else {
      const updatedValue = [...value];
      const items = newItem
        .split(/[,;]+/)
        .map((item) => item.trim())
        .filter(Boolean);
      const duplicateIndices = [];
      const newIndices = [];
      let currentIndex = value.length;

      items.forEach((item) => {
        const existingIndex = value.findIndex((existing) => existing.toLowerCase() === item.toLowerCase());
        if (existingIndex === -1) {
          updatedValue.push(item);
          newIndices.push(currentIndex++);
        } else {
          duplicateIndices.push(existingIndex);
        }
      });

      if (newIndices.length > 0) onChange(name, updatedValue);
      if (duplicateIndices.length > 0 || newIndices.length > 0) {
        setHighlightedIndices(duplicateIndices);
        setNewItemIndices(newIndices);
        setTimeout(() => {
          setHighlightedIndices([]);
          setNewItemIndices([]);
        }, 3000);
      }
    }
    setNewItem('');
  };

  const handleEditItem = (index) => {
    setNewItem(value[index]);
    setEditingIndex(index);
  };

  const handleRemoveItem = (index) => {
    const updatedValue = value.filter((_, i) => i !== index);
    onChange(name, updatedValue);
  };

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = value.findIndex((item) => item === active.id);
      const newIndex = value.findIndex((item) => item === over.id);
      const newValue = arrayMove(value, oldIndex, newIndex);
      onChange(name, newValue);
    }
  };

  return (
    <div className={classes.component} style={styles.component}>
      <Form.Label className={classes.label} style={styles.label}>
        {label || name} {required && <span style={{ color: 'red' }}>*</span>}
        <HelpText text={helpText} />
      </Form.Label>

      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={value} strategy={rectSortingStrategy}>
          <div className={classes.badgeContainer} style={styles.badgeContainer}>
            {value.map((item, index) => (
              <SortableItem
                key={item}
                id={item}
                index={index}
                item={item}
                highlighted={highlightedIndices.includes(index)}
                newItem={newItemIndices.includes(index)}
                onEdit={() => handleEditItem(index)}
                onRemove={() => handleRemoveItem(index)}
                styles={styles}
                classes={classes}
                isEditing={editingIndex === index}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>

      <Form ref={formRef} onSubmit={handleAddItem}>
        <InputGroup>
          <Form.Control
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder={editingIndex !== null ? 'Edit item...' : 'Type to add...'}
            className={classes.input}
            style={styles.input}
          />
          <Button variant={editingIndex !== null ? 'info' : 'success'} onClick={handleAddItem} disabled={!newItem.trim()}>
            {editingIndex !== null ? 'Save' : 'Add'}
          </Button>
        </InputGroup>
      </Form>
    </div>
  );
};

const SortableItem = ({ id, item, highlighted, newItem, onEdit, onRemove, styles, classes, isEditing }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    ...styles.badge,
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  if (highlighted) {
    style.backgroundColor = '#0d6efd';
  } else if (newItem) {
    style.backgroundColor = '#28a745';
  }

  return (
    <div style={{ display: 'inline-block', position: 'relative' }}>
      <Badge
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        className={classes.badge}
        style={style}
        bg={isEditing ? 'info' : !highlighted && !newItem ? 'secondary' : undefined}
        onClick={onEdit}
      >
        {item}
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
          style={styles.removeButton}
          onMouseDown={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
        >
          <FontAwesomeIcon icon={faTimes} style={styles.removeIcon} />
        </button>
      </Badge>
    </div>
  );
};

const StringArrayCell = ({ value, columnName, onAddFilter }) => (
  <div className="stringArrayCell">
    {Array.isArray(value) && value.length > 0 ? (
      value.map((item, index) => (
        <Badge key={index} bg="secondary" className="me-1 mb-1" onClick={() => onAddFilter(columnName, item)} style={{ cursor: 'pointer' }}>
          {item}
        </Badge>
      ))
    ) : (
      <div>-</div>
    )}
  </div>
);

export { StringArrayDisplay, StringArrayEdit, StringArrayCell };
