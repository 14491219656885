import React, { useState, useEffect, useMemo, memo } from 'react';
import { Tab, Tabs, Nav, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import ManualEntry from './ManualEntry';
import FileUpload from './FileUpload';
import CommunitySearch from './CommunitySearch';
import { useModal } from '../../../../context/ModalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlaskVial, faFileArrowUp, faDatabase } from '@fortawesome/pro-solid-svg-icons';

// Memoized Tab Navigation Component
const TabNavigation = memo(({ activeTab, onTabSelect }) => (
  <Nav variant="tabs" className="mb-3">
    <Nav.Item>
      <Nav.Link eventKey="manual">
        <FontAwesomeIcon icon={faFlaskVial} className="me-2" />
        Enter Products Manually
      </Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="upload">
        <FontAwesomeIcon icon={faFileArrowUp} className="me-2" />
        Upload SDS Files
      </Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="community">
        <FontAwesomeIcon icon={faDatabase} className="me-2" />
        Search Community Database
      </Nav.Link>
    </Nav.Item>
  </Nav>
));

// Memoized Tab Content Component
const TabContentWrapper = memo(({ activeTab }) => (
  <TabContent className="flex-grow-1 d-flex flex-column">
    <TabPane eventKey="manual" className="flex-grow-1">
      <ManualEntry />
    </TabPane>
    <TabPane eventKey="upload" className="flex-grow-1">
      <FileUpload />
    </TabPane>
    <TabPane eventKey="community" className="flex-grow-1">
      <CommunitySearch />
    </TabPane>
  </TabContent>
));

const AddSdsContent = memo(({ onClose, activeTab, onTabSelect }) => {
  return (
    <TabContainer activeKey={activeTab} onSelect={onTabSelect}>
      <TabNavigation activeTab={activeTab} onTabSelect={onTabSelect} />
      <TabContentWrapper activeTab={activeTab} />
    </TabContainer>
  );
});

// Add display name for debugging
AddSdsContent.displayName = 'AddSdsContent';

const AddSds = memo(({ onClose }) => {
  console.log('AddSds component rendered');
  const { showModal, hideModal } = useModal();
  const [activeTab, setActiveTab] = useState('manual');

  // Memoize content component
  const content = useMemo(() => <AddSdsContent onClose={onClose} activeTab={activeTab} onTabSelect={setActiveTab} />, [onClose, activeTab]);

  // Show modal on mount
  useEffect(() => {
    const modalId = 'add-sds';
    const modalConfig = {
      id: modalId,
      title: 'Add New Chemicals',
      size: 'lg',
      width: 800,
      height: 600,
      resizable: true,
      centered: true,
      overlay: false,
      closeButton: true,
      onHide: () => {
        hideModal(modalId);
        if (window.location.hash === '#add-sds') {
          window.location.hash = '';
        }
        window.history.replaceState(null, '', window.location.pathname);
        onClose?.();
      },
      component: () => content,
    };

    showModal(modalConfig);
    return () => hideModal(modalId);
  }, [showModal, hideModal, onClose, content]);

  return null;
});

// Add display name for debugging
AddSds.displayName = 'AddSds';

export default AddSds;
