import React from 'react';
import { Form, Badge } from 'react-bootstrap';
import HelpText from './HelpText';

const CheckboxDisplay = ({ attribute }) => {
  const { name, label, value, displayStyles, displayClasses } = attribute;

  const defaultClasses = {
    component: 'checkboxDisplay',
    label: 'checkbox-label',
    value: 'checkbox-value',
  };

  const defaultStyles = {
    component: {},
    label: {},
    value: {},
  };

  return (
    <div className={displayClasses?.component || defaultClasses.component} style={displayStyles?.component || defaultStyles.component || {}}>
      <div className={displayClasses?.label || defaultClasses.label} style={displayStyles?.label || defaultStyles.label || {}}>
        {label || name}:
      </div>
      <div className={displayClasses?.value || defaultClasses.value} style={displayStyles?.value || defaultStyles.value || {}}>
        {value ? 'Yes' : 'No'}
      </div>
    </div>
  );
};

const CheckboxEdit = ({ attribute, onChange }) => {
  const { name, label, value, required, helpText, editClasses, editStyles } = attribute;

  const defaultClasses = {
    component: 'col-12 col-md-6 p-2',
    label: 'form-label fw-bold',
    input: '',
  };

  const defaultStyles = {
    component: {},
    label: {},
    input: {},
  };

  return (
    <div className={editClasses?.component || defaultClasses.component} style={editStyles?.component || defaultStyles.component}>
      <Form.Check
        type="checkbox"
        id={`checkbox-edit-${name}`}
        checked={value || false}
        onChange={(e) => onChange(name, e.target.checked)}
        className={editClasses?.input || defaultClasses.input}
        style={editStyles?.input || defaultStyles.input}
        required={required}
        label={
          <span>
            {label || name} {required && <span style={{ color: 'red' }}>*</span>}
            <HelpText text={helpText} />
          </span>
        }
      />
    </div>
  );
};

const CheckboxCell = ({ value, columnName, onAddFilter }) => {
  const displayValue = value ? 'Yes' : 'No';
  const badgeColor = value ? 'success' : 'secondary';

  return (
    <div className="checkboxCell">
      <Badge bg={badgeColor} onClick={() => onAddFilter(columnName, value)} style={{ cursor: 'pointer' }}>
        {displayValue}
      </Badge>
    </div>
  );
};

export { CheckboxDisplay, CheckboxEdit, CheckboxCell };
