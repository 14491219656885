import { useEffect, useState, useRef } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import { useGet } from 'hooks/useGet';
import { usePortal } from 'context/Portal';
import { useNavigate } from 'react-router-dom';

const PDFJS = require('pdfjs-dist/webpack');

const Photoswipe = ({ pdfRendered, setPdfRendered, subdomain, isMobile, userSettings, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [documentData, setDocumentData] = useState(false);
  const [previewURL, setPreviewURL] = useState(false);
  const previewRef = useRef(previewURL);
  const [totalPages, setTotalPages] = useState(0);
  const documentRequest = useGet();
  const { portalConfig, updatePortalConfig } = usePortal();
  const navigate = useNavigate();

  const printDocument = (dataURL) => {
    const printWindow = window.open(dataURL, '_blank');
    printWindow.onload = () => {
      printWindow.print();
    };
    return false;
  };

  useEffect(() => {
    if (documentRequest.data && !documentRequest.isLoading) {
      setDocumentData(documentRequest.data);
    }
  }, [documentRequest.data]);

  useEffect(() => {
    previewRef.current = previewURL;
  }, [previewURL]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const newfilename = pdfRendered.name || pdfRendered.productIdentifier;
        const downloadButton = document.getElementById('download-button');
        if (downloadButton) {
          downloadButton.href = previewRef.current;
          downloadButton.download = newfilename.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.pdf';
        }
      }, 1000);
    }
  }, [isOpen, pdfRendered]);

  useEffect(() => {
    documentRequest.getDocument(pdfRendered);
    document.body.style.overflow = 'hidden';

    if (!portalConfig.lightbox) {
      initializeLightbox();
    }

    return () => {
      cleanup();
    };
  }, []);

  const initializeLightbox = () => {
    const options = {
      dataSource: [],
      pswpModule: () => import('photoswipe'),
      index: 0,
      bgOpacity: 0.7,
      showHideOpacity: true,
      closeOnScroll: false,
      hideAnimationDuration: 0,
      showAnimationDuration: 0,
      preload: [1, 1],
    };

    const lightbox = new PhotoSwipeLightbox(options);
    lightbox.on('close', handleClose);

    lightbox.on('uiRegister', () => {
      lightbox.pswp.ui.registerElement({
        name: 'download-button',
        order: 8,
        isButton: true,
        tagName: 'a',
        html: {
          isCustomSVG: true,
          inner: '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" id="pswp__icn-download"/>',
          outlineID: 'pswp__icn-download',
        },
        onInit: (el) => {
          el.setAttribute('download', '');
          el.setAttribute('target', '_blank');
          el.setAttribute('rel', 'noopener');
          el.href = 'none';
          el.download = 'none.pdf';
          el.id = 'download-button';
        },
      });
    });

    lightbox.init();
    updatePortalConfig({ ...portalConfig, lightbox });
  };

  const cleanup = () => {
    document.body.style.overflow = 'auto';
    URL.revokeObjectURL(previewURL);
    previewRef.current = false;
    setPreviewURL(false);
    setPdfRendered(false);
    if (document.querySelector('.pswp')) {
      document.querySelector('.pswp').style.display = 'none';
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (portalConfig.lightbox) {
      portalConfig.lightbox.options.dataSource = items;
    }
  }, [items, portalConfig.lightbox]);

  const convertPdfToImages = async (documentData) => {
    const pdf = await PDFJS.getDocument({ data: documentData }).promise;
    setTotalPages(pdf.numPages);
    const canvas = document.createElement('canvas');

    if (document.querySelector('.pswp')) {
      document.querySelector('.pswp').style.display = 'block';
    }

    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 2 });
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;

      setItems((prevItems) => [
        ...prevItems,
        {
          src: canvas.toDataURL(),
          w: viewport.width,
          h: viewport.height,
        },
      ]);
    }
  };

  useEffect(() => {
    if (documentData) {
      setPreviewURL(URL.createObjectURL(new Blob([documentData], { type: 'application/pdf' })));

      if (isMobile || userSettings.internalViewer) {
        convertPdfToImages(documentData).then(() => {
          if (portalConfig.lightbox) {
            portalConfig.lightbox.loadAndOpen(0);
            setIsOpen(true);
          }
        });
      }
    }
  }, [documentData, isMobile, userSettings.internalViewer, portalConfig.lightbox]);

  const handleClose = () => {
    setIsOpen(false);
    setPdfRendered(false);
    if (onClose) {
      onClose();
    } else {
      navigate('/');
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        zIndex: 8888,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {items.length <= totalPages && (isMobile || userSettings.internalViewer) && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 className="text-light">
            <i className="fa-duotone fa-loader fa-spin-pulse fa-2xl" />
          </h1>
          <br />
          <p style={{ color: '#fff' }}>
            Loading {items.length || 0} of {totalPages || 0}
          </p>
        </div>
      )}
      {!previewURL && !isMobile && !userSettings.internalViewer && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 className="text-light">
            <i className="fa-duotone fa-loader fa-spin-pulse fa-2xl" />
          </h1>
          <br />
          <p style={{ color: '#fff' }}>Loading Document</p>
        </div>
      )}
      {previewURL && !isMobile && !userSettings.internalViewer && (
        <iframe
          src={previewURL}
          title="PDF Document"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
        />
      )}
    </div>
  );
};

export default Photoswipe;
