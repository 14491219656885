import React, { useMemo } from 'react';
import { usePortal } from 'context/Portal';
import LocationListItem from './Controls-Location-ListItem';

const Locations = ({ selectedLocation, setSelectedLocation }) => {
  const { portalConfig } = usePortal();

  //remove all locations that have a status other than active
  const activeLocations = portalConfig.locations.filter((location) => location.status === 'active');

  const locationList = useMemo(() => buildLocationList(activeLocations, selectedLocation, setSelectedLocation), [activeLocations]);
  return <div className="w-100 locationList py-2 my-2">{locationList}</div>;
};

const buildLocationList = (locations, selectedLocation, setSelectedLocation) => {
  const locationMap = new Map();
  const rootLocations = [];

  //alphabetize the locations but keep the all documents location at the top
  locations.sort((a, b) => {
    if (a.locationID === 0) return -1;
    if (b.locationID === 0) return 1;
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  // Create a map of locations using their IDs as keys
  locations.forEach((location) => {
    locationMap.set(location.locationID, { ...location, children: [] });
  });

  // Iterate over the locations and nest child objects inside their parents
  locationMap.forEach((location) => {
    const parentObj = locationMap.get(location.parent);
    if (parentObj) {
      parentObj.children.push(location);
    } else {
      rootLocations.push(location);
    }
    // Pop the object with locationID 0 off the locationMap array
    const location0 = locationMap.get(0);
    locationMap.delete(0);

    // Add it back to the top of the rootLocations array
    //rootLocations.unshift(location0);
  });

  // Recursively build the nested location list
  const buildNestedList = (locations) => {
    return locations.map((location) => {
      return (
        <LocationListItem key={location.locationID} location={location} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation}>
          {location.children.length > 0 && buildNestedList(location.children)}
        </LocationListItem>
      );
    });
  };

  return buildNestedList(rootLocations);
};

export default Locations;
