/**
 * ModalContext
 *
 * This context provides a way to manage multiple modals within an application. It allows for showing, hiding, updating, and bringing modals to the front.
 *
 * Functions provided by the context:
 * - showModal(modalProps): Displays a modal with the given properties.
 * - hideModal(id): Hides the modal with the specified ID.
 * - updateModal(id, newProps): Updates the properties of the modal with the specified ID.
 * - bringToFront(id): Brings the modal with the specified ID to the front.
 *
 * Usage:
 *
 * ```jsx
 * import React from 'react';
 * import { ModalProvider, useModal } from './context/ModalContext';
 * import PowerModal from './components/admin/sub-components/PowerModal';
 *
 * function App() {
 *   const { showModal, hideModal } = useModal();
 *
 *   const handleShowModal = () => {
 *     showModal({
 *       id: 'exampleModal',
 *       title: 'Example Modal',
 *       children: <p>This is an example modal content.</p>,
 *       size: 'lg',
 *       draggable: true,
 *       resizable: true,
 *       overlay: true,
 *     });
 *   };
 *
 *   return (
 *     <ModalProvider>
 *       <button onClick={handleShowModal}>Show Modal</button>
 *     </ModalProvider>
 *   );
 * }
 * ```
 *
 * This context leverages `useState`, `useContext`, `useCallback`, and `useMemo` hooks from React to manage the state and behavior of modals.
 *
 * Ensure you have the `PowerModal` component properly set up to work with this context.
 */

import React, { createContext, useState, useContext, useCallback, useMemo } from 'react';
import PowerModal from '../components/admin/sub-components/PowerModal';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

// Backdrop component for overlay
const Backdrop = ({ zIndex }) => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      zIndex: zIndex - 1, // Place backdrop just below the modal
    }}
  />
);

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);

  const showModal = useCallback((modalProps) => {
    setModals((prevModals) => {
      const existingModalIndex = prevModals.findIndex((modal) => modal.id === modalProps.id);
      const maxZIndex = prevModals.length > 0 ? Math.max(...prevModals.map((modal) => modal.zIndex)) : 1000;
      const newZIndex = maxZIndex + 100;

      const newModal = {
        ...modalProps,
        show: true,
        zIndex: newZIndex,
        modalId: `modal-${Date.now()}`,
      };

      // If the new modal has overlay=true, mark other modals as hidden
      const updatedModals = prevModals.map((modal) => ({
        ...modal,
        show: modalProps.overlay ? false : modal.show,
      }));

      if (existingModalIndex !== -1) {
        updatedModals[existingModalIndex] = newModal;
        return updatedModals;
      } else {
        return [...updatedModals, newModal];
      }
    });
  }, []);

  const hideModal = useCallback((id) => {
    setModals((prevModals) => {
      // Find the modal being hidden
      const modalToHide = prevModals.find((modal) => modal.id === id);

      if (modalToHide?.overlay) {
        // If we're hiding an overlay modal, show all other modals again
        return prevModals.filter((modal) => modal.id !== id).map((modal) => ({ ...modal, show: true }));
      }

      // Normal hide behavior for non-overlay modals
      return prevModals.filter((modal) => modal.id !== id);
    });
  }, []);

  const updateModal = useCallback((id, newProps) => {
    setModals((prevModals) => prevModals.map((modal) => (modal.id === id ? { ...modal, ...newProps } : modal)));
  }, []);

  const bringToFront = useCallback((id) => {
    setModals((prevModals) => {
      const maxZIndex = prevModals.length > 0 ? Math.max(...prevModals.map((modal) => modal.zIndex)) : 1000;
      const newZIndex = maxZIndex + 100;
      return prevModals.map((modal) => (modal.id === id ? { ...modal, zIndex: newZIndex } : modal));
    });
  }, []);

  const renderModalContent = useCallback(
    (modal) => {
      if (!modal.component) {
        console.error('No component provided for modal:', modal.id);
        return {
          content: modal.children || <div>No content provided</div>,
          footerContent: modal.footerContent,
        };
      }

      const Component = modal.component;
      const componentInstance = <Component {...modal.props} key={modal.id} />;

      const footerContent = Component.FooterContent ? <Component.FooterContent modalId={modal.id} hideModal={hideModal} /> : modal.footerContent;

      return {
        content: componentInstance,
        footerContent,
      };
    },
    [hideModal]
  );

  const contextValue = useMemo(
    () => ({
      showModal,
      hideModal,
      updateModal,
      bringToFront,
    }),
    [showModal, hideModal, updateModal, bringToFront]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {modals.map((modal) => {
        if (!modal.show) return null;
        const { content, footerContent } = renderModalContent(modal);
        return (
          <PowerModal
            key={modal.id}
            {...modal}
            footerContent={footerContent}
            onHide={() => {
              modal.onHide?.();
              hideModal(modal.id);
            }}
            onClick={() => bringToFront(modal.id)}
          >
            {content}
          </PowerModal>
        );
      })}
    </ModalContext.Provider>
  );
};
