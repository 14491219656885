import React, { useState, useMemo, useCallback } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, getFilteredRowModel, getSortedRowModel, flexRender } from '@tanstack/react-table';
import { Table } from 'react-bootstrap';
import { getUserColumnDefinitions } from './userColumnDefinitions';
import TableHeader from '../../tables/TableHeader';
import TableConfig, { useFormattedColumns, getGlobalFilterFn } from '../../tables/TableConfig';
import PaginationComponent from '../../sub-components/Pagination';
import { usePatch } from '../../../../hooks/usePatch';
import { useModal } from '../../../../context/ModalContext';
import { usePortal } from '../../../../context/Portal';
import EditUserModal from './EditUserModal';
import Cookies from 'js-cookie';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useConfirmationModal } from '../../../common/ConfirmationModal';

const UserEditor = ({ users, onRefresh, userHandlers }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const { isLoading } = usePatch();
  const { showModal } = useModal();
  const { portalConfig } = usePortal();
  const showConfirmation = useConfirmationModal();

  // Filter active users and ensure we have an array
  const usersList = useMemo(() => {
    const allUsers = Array.isArray(users?.users) ? users.users : Array.isArray(users) ? users : [];
    return allUsers.filter((user) => user.status === 'active');
  }, [users]);

  const handleConfirmDelete = useCallback(
    async (user) => {
      await userHandlers.onDeleteUser(user);
    },
    [userHandlers]
  );

  const handleDeleteUser = useCallback(
    (user) => {
      showConfirmation({
        id: `delete-user-${user._id}`,
        title: 'Delete User',
        message: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
        onConfirm: () => handleConfirmDelete(user),
        confirmText: 'Delete',
        confirmVariant: 'danger',
        isLoading,
        loadingText: 'Deleting...',
        icon: faTrash,
      });
    },
    [showConfirmation, handleConfirmDelete, isLoading]
  );

  const handleEditUser = useCallback(
    (user) => {
      const modalId = `edit-user-${user._id}`;
      showModal({
        id: modalId,
        component: EditUserModal,
        props: {
          user,
          users: usersList,
          onSave: (userData) => userHandlers.onSaveUser(userData, modalId),
          onDelete: handleDeleteUser,
          modalId,
        },
        title: 'Edit User',
        size: 'lg',
        resizable: true,
        centered: true,
        overlay: false,
        width: 775,
        height: 700,
      });
    },
    [showModal, usersList, userHandlers, handleDeleteUser]
  );

  const handleColumnVisibilityChange = useCallback((newVisibility) => {
    setColumnVisibility(newVisibility);
    Cookies.set('userTableColumnVisibility', JSON.stringify(newVisibility), { expires: 365 });
  }, []);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPageSize(newPageSize);
    setPageIndex(0);
  }, []);

  const handlePageChange = useCallback((newPageIndex) => {
    setPageIndex(newPageIndex);
  }, []);

  // Get base columns and format them
  const baseColumns = useMemo(() => getUserColumnDefinitions(handleEditUser, handleDeleteUser), [handleEditUser, handleDeleteUser, portalConfig]);

  const columns = useFormattedColumns(baseColumns, globalFilter);

  const table = useReactTable({
    data: usersList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      rowSelection,
      pagination: { pageIndex, pageSize },
      columnVisibility,
      globalFilter,
      sorting,
    },
    globalFilterFn: getGlobalFilterFn(columns),
    enableSortingRemoval: false,
    enableMultiSort: false,
    enableColumnResizing: true,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onSortingChange: setSorting,
    getRowId: (row) => row._id,
    onRowSelectionChange: setRowSelection,
    pageCount: Math.ceil(usersList.length / pageSize),
  });

  return (
    <div className="table-wrapper-new h-100">
      <div className="table-container-new d-flex flex-column h-100">
        <TableConfig
          table={table}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          title="User Management"
          totalCount={usersList.length}
        />

        <div className="table-responsive flex-fill">
          <Table striped bordered hover className="flex-grow-1">
            <TableHeader headerGroups={table.getHeaderGroups()} />
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="align-middle" style={{ textAlign: cell.column.columnDef.meta?.align || 'left' }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div
          className="sticky-pagination-new d-flex justify-content-end align-items-center shadow-lg flex-shrink-0"
          style={{ marginLeft: '-1rem', marginRight: '-1rem' }}
        >
          <PaginationComponent
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            pageSize={table.getState().pagination.pageSize}
            totalEntries={usersList.length}
            onPageChange={handlePageChange}
            previousPage={() => handlePageChange(table.getState().pagination.pageIndex - 1)}
            nextPage={() => handlePageChange(table.getState().pagination.pageIndex + 1)}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default UserEditor;
