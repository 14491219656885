import React, { useState } from 'react';
import HelpText from './HelpText';

const NumberDisplay = ({ attribute }) => {
  const { name, value, displayStyles, displayClasses } = attribute;

  const defaultClasses = {
    component: 'numberDisplay',
    label: 'number-label',
    value: 'number-value',
  };

  const defaultStyles = {
    component: {},
    label: {},
    value: {},
  };

  return (
    <div className={displayClasses?.component || defaultClasses.component} style={displayStyles?.component || defaultStyles.component}>
      <div className={displayClasses?.label || defaultClasses.label} style={displayStyles?.label || defaultStyles.label}>
        {name}:
      </div>
      <div className={displayClasses?.value || defaultClasses.value} style={displayStyles?.value || defaultStyles.value}>
        {value}
      </div>
    </div>
  );
};

const NumberEdit = ({ attribute, onChange }) => {
  //create object for default class and style
  const defaultClass = {
    component: 'col-12 col-md-6 p-2',
    label: 'form-label fw-bold',
    input: 'form-control',
  };

  const defaultStyle = {
    component: {},
    label: {},
    style: {},
  };

  const { name, value, label, required, editStyles, editClasses, helpText } = attribute;

  const handleChange = (e) => {
    onChange(name, e.target.value);
  };

  return (
    <div
      className={`numberEditComponent ${editClasses?.component || defaultClass.component}`}
      style={editStyles?.component || defaultStyle.component}
    >
      <label
        htmlFor={`number-edit-${name}`}
        className={'numberEditLabel ' + (editClasses?.label || defaultClass.label)}
        style={editStyles?.label || defaultStyle.label}
      >
        {label || name} {required && <span style={{ color: 'red' }}>*</span>}
        <HelpText text={helpText} />
      </label>
      <input
        type="number"
        className={'numberEditInput ' + (editClasses?.input || defaultClass.input)}
        id={`number-edit-${name}`}
        name={name}
        value={value || ''}
        onChange={handleChange}
        required={required}
        style={editStyles?.input || defaultStyle.input}
      />
    </div>
  );
};

const NumberCell = ({ value }) => {
  return <div className="numberCell">{value}</div>;
};

export { NumberDisplay, NumberEdit, NumberCell };
