import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Badge } from 'react-bootstrap';

export const getGroupColumnDefinitions = (onEditGroup, onDeleteGroup) => [
  {
    id: 'name',
    header: 'Group Name',
    accessorKey: 'name',
    defaultVisible: true,
    enableSorting: true,
    enableFiltering: true,
  },
  {
    id: 'description',
    header: 'Description',
    accessorKey: 'description',
    defaultVisible: true,
    enableSorting: false,
    enableFiltering: true,
  },
  {
    id: 'permissions',
    header: 'Permissions',
    accessorKey: 'permissions',
    defaultVisible: true,
    enableSorting: false,
    meta: {
      align: 'center',
    },
    cell: ({ getValue }) => {
      const permissions = getValue() || [];
      return (
        <div className="d-flex flex-wrap gap-1 justify-content-center align-items-center">
          {permissions.map((permission) => (
            <Badge
              key={permission}
              bg="secondary"
              className="text-wrap"
              style={{
                fontSize: '0.8rem',
                fontWeight: 'bold',
                padding: '0.25rem 0.5rem',
                margin: '0.1rem',
              }}
            >
              {permission}
            </Badge>
          ))}
          {permissions.length === 0 && <span className="text-muted">No permissions</span>}
        </div>
      );
    },
  },
  {
    id: 'actions',
    header: 'Actions',
    defaultVisible: true,
    enableSorting: false,
    meta: {
      align: 'center',
    },
    cell: ({ row }) => {
      const group = row.original;
      return (
        <div className="d-flex gap-2 justify-content-center">
          <button className="btn btn-lg icon-btn text-primary" onClick={() => onEditGroup(group)} title="Edit Group">
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button className="btn btn-lg icon-btn text-danger" onClick={() => onDeleteGroup(group)} title="Delete Group">
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      );
    },
  },
];
