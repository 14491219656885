import React, { useState, useMemo } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { Table } from 'react-bootstrap';
import TableHeader from '../../tables/TableHeader';
import TableBody from '../../tables/TableBody';
import TableConfig, { useFormattedColumns, getGlobalFilterFn } from '../../tables/TableConfig';
import PaginationComponent from '../../sub-components/Pagination';
import Cookies from 'js-cookie';
import { useModal } from '../../../../context/ModalContext';
import EditGroupModal from './EditGroupModal';
import { usePortal } from '../../../../context/Portal';
import { getGroupColumnDefinitions } from './groupColumnDefinitions';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useConfirmationModal } from '../../../common/ConfirmationModal';

const GroupEditor = ({ groupHandlers }) => {
  const { showModal } = useModal();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [sorting, setSorting] = useState([]);
  const { portalConfig } = usePortal();
  const [globalFilter, setGlobalFilter] = useState('');
  const showConfirmation = useConfirmationModal();

  const groups = portalConfig?.permissionGroups || [];

  const getNextGroupId = () => {
    if (!groups.length) return 0;
    return Math.max(...groups.map((g) => g.groupID)) + 1;
  };

  const handleCreateGroup = () => {
    const nextGroupId = getNextGroupId();
    const modalId = `edit-group-${nextGroupId}`;

    const newGroup = {
      groupID: nextGroupId,
      name: '',
      description: '',
      permissions: [],
    };

    showModal({
      id: modalId,
      component: EditGroupModal,
      props: {
        group: newGroup,
        onSave: (groupData) => groupHandlers.onSaveGroup(groupData, modalId),
        modalId,
      },
      title: 'Create Group',
      size: 'lg',
      resizable: true,
      centered: true,
      overlay: false,
      width: 500,
      height: 400,
    });
  };

  const handleConfirmDelete = async (group) => {
    await groupHandlers.onDeleteGroup(group);
  };

  const handleDeleteGroup = (group) => {
    showConfirmation({
      id: `delete-group-${group.groupID}`,
      title: 'Delete Group',
      message: `Are you sure you want to delete the group "${group.name}"? This action cannot be undone.`,
      onConfirm: () => handleConfirmDelete(group),
      confirmText: 'Delete',
      confirmVariant: 'danger',
      icon: faTrash,
    });
  };

  const handleEditGroup = (group) => {
    const modalId = `edit-group-${group.groupID}`;
    showModal({
      id: modalId,
      component: EditGroupModal,
      props: {
        group,
        onSave: (groupData) => groupHandlers.onSaveGroup(groupData, modalId),
        onDelete: handleDeleteGroup,
        modalId,
      },
      title: 'Edit Group',
      size: 'lg',
      resizable: true,
      centered: true,
      overlay: false,
      width: 500,
      height: 400,
    });
  };

  const baseColumns = useMemo(() => getGroupColumnDefinitions(handleEditGroup, handleDeleteGroup), [handleEditGroup, handleDeleteGroup]);
  const columns = useFormattedColumns(baseColumns, globalFilter);

  const handleColumnVisibilityChange = (newVisibility) => {
    setColumnVisibility(newVisibility);
    Cookies.set('groupTableColumnVisibility', JSON.stringify(newVisibility), { expires: 365 });
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPageIndex(0);
  };

  const handlePageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  const table = useReactTable({
    data: groups,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination: { pageIndex, pageSize },
      columnVisibility,
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    enableColumnResizing: true,
    globalFilterFn: getGlobalFilterFn(columns),
    onColumnVisibilityChange: handleColumnVisibilityChange,
    getRowId: (row) => row.groupID.toString(),
    pageCount: Math.ceil((groups?.length || 0) / pageSize),
  });

  return (
    <div className="table-wrapper-new h-100">
      <div className="table-container-new d-flex flex-column h-100">
        <TableConfig
          table={table}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          title="Group Management"
          totalCount={groups.length}
        />
        <div className="table-responsive flex-fill overflow-auto">
          <Table striped bordered hover className="flex-grow-1">
            <TableHeader headerGroups={table.getHeaderGroups()} />
            <TableBody rows={table.getRowModel().rows} />
          </Table>
        </div>

        <div
          className="sticky-pagination-new d-flex justify-content-end align-items-center shadow-lg flex-shrink-0"
          style={{ marginLeft: '-1rem', marginRight: '-1rem' }}
        >
          <PaginationComponent
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            pageSize={table.getState().pagination.pageSize}
            totalEntries={groups?.length || 0}
            onPageChange={handlePageChange}
            previousPage={() => handlePageChange(table.getState().pagination.pageIndex - 1)}
            nextPage={() => handlePageChange(table.getState().pagination.pageIndex + 1)}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupEditor;
