import React from 'react';
import { Form } from 'react-bootstrap';
import Pictogram from '../Pictograms';
import HelpText from './HelpText';

const ALL_PICTOGRAMS = ['GHS01', 'GHS02', 'GHS03', 'GHS04', 'GHS05', 'GHS06', 'GHS07', 'GHS08', 'GHS09'];

const PictogramArrayDisplay = ({ attribute }) => {
  const { name, label, value = [], displayStyles, displayClasses } = attribute;

  const defaultClasses = {
    component: 'pictogramArrayDisplay',
    label: 'pictogramArray-label',
    value: 'pictogramArray-value d-flex flex-wrap gap-2',
  };

  const defaultStyles = {
    component: {},
    label: {},
    value: {},
  };

  return (
    <div className={displayClasses?.component || defaultClasses.component} style={displayStyles?.component || defaultStyles.component || {}}>
      <div className={displayClasses?.label || defaultClasses.label} style={displayStyles?.label || defaultStyles.label || {}}>
        {label || name}:
      </div>
      <div className={displayClasses?.value || defaultClasses.value} style={displayStyles?.value || defaultStyles.value || {}}>
        {Array.isArray(value) && value.length > 0 ? (
          value.map((ghs, index) => <Pictogram key={index} ghs={ghs} />)
        ) : (
          <span className="text-muted">No pictograms selected</span>
        )}
      </div>
    </div>
  );
};

const PictogramArrayEdit = ({ attribute, onChange }) => {
  const { name, label, value = [], required, helpText, editClasses, editStyles } = attribute;

  const defaultClasses = {
    component: 'col-12 p-2',
    label: 'form-label fw-bold',
    pictogramsContainer: 'd-flex flex-wrap justify-content-between mt-2',
  };

  const defaultStyles = {
    component: {},
    label: {},
    pictogramsContainer: {},
    pictogramWrapper: {
      cursor: 'pointer',
      transition: 'opacity 0.2s ease-in-out, filter 0.2s ease-in-out',
    },
  };

  const handleTogglePictogram = (ghs) => {
    const newValue = [...value];
    const index = newValue.findIndex((item) => item.toLowerCase() === ghs.toLowerCase());

    if (index > -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push(ghs);
    }

    onChange(name, newValue);
  };

  return (
    <div className={editClasses?.component || defaultClasses.component} style={editStyles?.component || defaultStyles.component}>
      <Form.Label className={editClasses?.label || defaultClasses.label} style={editStyles?.label || defaultStyles.label}>
        {label || name} {required && <span style={{ color: 'red' }}>*</span>}
        <HelpText text={helpText} />
      </Form.Label>

      <div
        className={editClasses?.pictogramsContainer || defaultClasses.pictogramsContainer}
        style={editStyles?.pictogramsContainer || defaultStyles.pictogramsContainer}
      >
        {ALL_PICTOGRAMS.map((ghs) => {
          const isSelected = value.some((item) => item.toLowerCase() === ghs.toLowerCase());
          return (
            <div
              key={ghs}
              onClick={() => handleTogglePictogram(ghs)}
              style={{
                ...defaultStyles.pictogramWrapper,
                opacity: isSelected ? 1 : 0.5,
                filter: isSelected ? 'none' : 'grayscale(100%)',
                ...(editStyles?.pictogramWrapper || {}),
              }}
            >
              <Pictogram ghs={ghs} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PictogramArrayCell = ({ value }) => {
  if (!Array.isArray(value) || value.length === 0) {
    return <div className="pictogramArrayCell">-</div>;
  }

  return (
    <div className="pictogramArrayCell d-flex flex-wrap gap-1">
      {value.map((ghs, index) => (
        <Pictogram key={index} ghs={ghs} />
      ))}
    </div>
  );
};

export { PictogramArrayDisplay, PictogramArrayEdit, PictogramArrayCell };
