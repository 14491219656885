export const updateRecordStatus = async (records, newStatus, patchData) => {
  try {
    // Ensure records is an array for consistent processing
    const recordsArray = Array.isArray(records) ? records : [records];

    // Construct operations for each record
    const operations = recordsArray.map((record) => ({
      recordId: record._id,
      changes: [
        {
          action: 'set',
          field: 'status',
          value: newStatus,
        },
      ],
    }));

    console.log('Batch update operations:', operations);

    // Use the new batch update endpoint
    const response = await patchData('/admin/records/update', { operations });
    console.log('Records updated:', response);
  } catch (error) {
    console.error('Error updating records:', error);
  }
};

export const viewDocument = (record, navigate) => {
  console.log('View Document:', record);
  navigate(`/admin/view/${record.recordId}`);
};

export const editDocument = (record, navigate) => {
  console.log('Edit Document:', record);
  navigate(`/admin/edit/${record.recordId}`);
};
