import React from 'react';
import { Form, Badge } from 'react-bootstrap';
import HelpText from './HelpText';

const DateDisplay = ({ attribute }) => {
  const { name, label, value, displayStyles, displayClasses } = attribute;

  const defaultClasses = {
    component: 'dateDisplay',
    label: 'date-label',
    value: 'date-value',
  };

  const defaultStyles = {
    component: {},
    label: {},
    value: {},
  };

  const formatDate = (dateValue) => {
    if (!dateValue) return 'Not set';
    const date = new Date(dateValue);
    return date.toLocaleDateString();
  };

  return (
    <div className={displayClasses?.component || defaultClasses.component} style={displayStyles?.component || defaultStyles.component || {}}>
      <div className={displayClasses?.label || defaultClasses.label} style={displayStyles?.label || defaultStyles.label || {}}>
        {label || name}:
      </div>
      <div className={displayClasses?.value || defaultClasses.value} style={displayStyles?.value || defaultStyles.value || {}}>
        {formatDate(value)}
      </div>
    </div>
  );
};

const DateEdit = ({ attribute, onChange }) => {
  const { name, label, value, required, helpText, editClasses, editStyles } = attribute;

  const defaultClasses = {
    component: 'col-12 col-md-6 p-2',
    label: 'form-label fw-bold',
    input: 'form-control',
  };

  const defaultStyles = {
    component: {},
    label: {},
    input: {},
  };

  const formatDateForInput = (dateValue) => {
    if (!dateValue) return '';
    const date = new Date(dateValue);
    return date.toISOString().split('T')[0];
  };

  const handleDateChange = (e) => {
    const dateValue = e.target.value ? new Date(e.target.value) : null;
    onChange(name, dateValue);
  };

  return (
    <div className={editClasses?.component || defaultClasses.component} style={editStyles?.component || defaultStyles.component}>
      <Form.Label
        htmlFor={`date-edit-${name}`}
        className={editClasses?.label || defaultClasses.label}
        style={editStyles?.label || defaultStyles.label}
      >
        {label || name} {required && <span style={{ color: 'red' }}>*</span>}
        <HelpText text={helpText} />
      </Form.Label>
      <Form.Control
        type="date"
        id={`date-edit-${name}`}
        value={formatDateForInput(value)}
        onChange={handleDateChange}
        className={editClasses?.input || defaultClasses.input}
        style={editStyles?.input || defaultStyles.input}
        required={required}
      />
    </div>
  );
};

const DateCell = ({ value, columnName, onAddFilter }) => {
  if (!value) {
    return <div className="dateCell">-</div>;
  }

  const date = new Date(value);
  const formattedDate = date.toLocaleDateString();

  return (
    <div className="dateCell">
      <Badge bg="secondary" onClick={() => onAddFilter(columnName, value)} style={{ cursor: 'pointer' }}>
        {formattedDate}
      </Badge>
    </div>
  );
};

export { DateDisplay, DateEdit, DateCell };
