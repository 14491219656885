import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Form, Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare as faSquareRegular, faCheckSquare as faCheckSquareRegular, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faSquare, faCheckSquare, faPlusSquare, faMinusSquare, faEdit } from '@fortawesome/pro-solid-svg-icons';
import { usePortal } from '../../context/Portal';
import HelpText from './HelpText';
import './LocationArray.css';
import { useModal } from '../../context/ModalContext';
import { usePatch } from '../../hooks/usePatch';
import { usePermissions } from '../../context/Portal';

const highlightText = (text, filter) => {
  if (!filter) return text;

  const lowerText = text.toLowerCase();
  const lowerFilter = filter.toLowerCase();
  const index = lowerText.indexOf(lowerFilter);

  if (index === -1) return text;

  return (
    <>
      {text.substring(0, index)}
      <span className="location-highlight">{text.substring(index, index + filter.length)}</span>
      {text.substring(index + filter.length)}
    </>
  );
};

const filterLocationHierarchy = (hierarchy, filterText, setExpandedNodes) => {
  if (!filterText) return hierarchy;

  const matchesFilter = (node) => {
    return node.name.toLowerCase().includes(filterText.toLowerCase());
  };

  const filterNode = (node) => {
    // If the node matches, keep it and all its children
    if (matchesFilter(node)) {
      return true;
    }

    // If any children match, keep this node and the matching children
    if (node.children && node.children.length) {
      const filteredChildren = node.children.filter(filterNode);
      if (filteredChildren.length) {
        // Automatically expand this node since it has matching children
        setExpandedNodes((prev) => new Set([...prev, node.locationID]));
        node.children = filteredChildren;
        return true;
      }
    }

    return false;
  };

  return hierarchy.filter(filterNode);
};

const buildLocationHierarchy = (locations, selectedLocations = [], indeterminateLocations = []) => {
  // Ensure selectedLocations and indeterminateLocations are arrays
  const selectedArray = Array.isArray(selectedLocations) ? selectedLocations : [];
  const indeterminateArray = Array.isArray(indeterminateLocations) ? indeterminateLocations : [];

  const locationMap = new Map();

  // First pass: Create all location nodes
  locations.forEach((location) => {
    const locationId = String(location.locationID);
    locationMap.set(locationId, {
      ...location,
      locationID: locationId,
      parent: location.parent ? String(location.parent) : null,
      children: [],
      isSelected: selectedArray.map(String).includes(locationId),
      isIndeterminate: indeterminateArray.map(String).includes(locationId),
    });
  });

  // Second pass: Build hierarchy
  const rootNodes = [];
  locationMap.forEach((location) => {
    if (location.parent && locationMap.get(String(location.parent))) {
      locationMap.get(String(location.parent)).children.push(location);
    } else {
      rootNodes.push(location);
    }
  });

  return rootNodes;
};

const LocationNode = ({ node, onToggle, isSelectionMode = false, level = 0, expandedNodes, setExpandedNodes, filterText = '' }) => {
  const [localExpanded, setLocalExpanded] = useState(false);

  // Use either forced expansion from filter or local state
  const isExpanded = expandedNodes?.has(node.locationID) || localExpanded;

  const handleExpand = (e) => {
    e.stopPropagation();
    if (setExpandedNodes) {
      // If we're using external expansion state
      if (expandedNodes.has(node.locationID)) {
        const newExpanded = new Set(expandedNodes);
        newExpanded.delete(node.locationID);
        setExpandedNodes(newExpanded);
      } else {
        setExpandedNodes(new Set([...expandedNodes, node.locationID]));
      }
    } else {
      // Fallback to local state if no external state provided
      setLocalExpanded(!localExpanded);
    }
  };

  const getTreeIcon = () => {
    return node.children.length ? (isExpanded ? faMinusSquare : faPlusSquare) : faSquare;
  };

  const getSelectionIcon = () => {
    if (node.isIndeterminate) {
      return { icon: faCheckSquare, isRegular: false };
    }
    return {
      icon: node.isSelected ? faCheckSquareRegular : faSquareRegular,
      isRegular: true,
    };
  };

  const handleSelectionClick = (e) => {
    e.stopPropagation();
    console.log('handleSelectionClick called for node:', node);
    if (isSelectionMode) {
      console.log('Calling onToggle with:', String(node.locationID));
      onToggle?.(String(node.locationID));
    }
  };

  return (
    <div className="locationNode" style={{ marginLeft: `${level * 20}px` }}>
      <div className="d-flex align-items-center my-1">
        <FontAwesomeIcon
          icon={getTreeIcon()}
          onClick={handleExpand}
          className={`text-${node.color}`}
          style={{
            cursor: 'pointer',
            marginRight: '0.5rem',
          }}
          size="2x"
        />
        <span className="locationName">{highlightText(node.name, filterText)}</span>
        {isSelectionMode && (
          <FontAwesomeIcon
            icon={getSelectionIcon().icon}
            onClick={handleSelectionClick}
            style={{
              marginLeft: 'auto',
              cursor: 'pointer',
            }}
            size="2x"
          />
        )}
        {!isSelectionMode && (
          <FontAwesomeIcon
            icon={faEdit}
            className="ms-2 text-secondary"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              // We'll implement location editing later
            }}
            size="2x"
          />
        )}
      </div>
      {isExpanded &&
        node.children.map((child) => (
          <LocationNode
            key={child.locationID}
            node={child}
            onToggle={onToggle}
            isSelectionMode={isSelectionMode}
            level={level + 1}
            expandedNodes={expandedNodes}
            setExpandedNodes={setExpandedNodes}
            filterText={filterText}
          />
        ))}
    </div>
  );
};

const LocationArrayDisplay = ({ attribute }) => {
  const { portalConfig } = usePortal();
  const locations = portalConfig.locations || [];
  const { name, label, value = [], displayStyles = {}, displayClasses = {} } = attribute;

  const defaultClasses = {
    component: 'locationArrayDisplay',
    label: 'locationArray-label',
    value: 'locationArray-value',
  };

  const defaultStyles = {
    component: {},
    label: {},
    value: { marginTop: '0.5rem' },
  };

  const hierarchy = useMemo(() => buildLocationHierarchy(Object.values(locations), value), [locations, value]);

  return (
    <div className={displayClasses.component || defaultClasses.component} style={{ ...defaultStyles.component, ...displayStyles.component }}>
      <div className={displayClasses.label || defaultClasses.label} style={{ ...defaultStyles.label, ...displayStyles.label }}>
        {label || name}:
      </div>
      <div className={displayClasses.value || defaultClasses.value} style={{ ...defaultStyles.value, ...displayStyles.value }}>
        {hierarchy.map((node) => (
          <LocationNode key={node.locationID} node={node} />
        ))}
      </div>
    </div>
  );
};

const LocationArrayEdit = ({ attribute, onChange }) => {
  const { portalConfig } = usePortal();
  const locations = portalConfig.locations || [];
  const { name, label, value = [], required, helpText } = attribute;
  const [filterText, setFilterText] = useState('');

  const handleToggle = useCallback(
    (locationId) => {
      const newValue = value.includes(locationId) ? value.filter((id) => id !== locationId) : [...value, locationId];
      onChange(name, newValue);
    },
    [value, onChange, name]
  );

  const baseHierarchy = useMemo(() => buildLocationHierarchy(Object.values(locations), value), [locations, value]);

  const filteredHierarchy = useMemo(() => filterLocationHierarchy(baseHierarchy, filterText), [baseHierarchy, filterText]);

  return (
    <div className="col-12 p-2">
      <Form.Label className="form-label fw-bold">
        {label || name} {required && <span style={{ color: 'red' }}>*</span>}
        <HelpText text={helpText} />
      </Form.Label>
      <div className="border rounded p-2">
        <div className="location-filter mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Filter locations..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
        <div className="location-tree">
          {filteredHierarchy.map((node) => (
            <LocationNode key={node.locationID} node={node} onToggle={handleToggle} isSelectionMode={true} />
          ))}
        </div>
      </div>
    </div>
  );
};

const LocationCellNode = ({ node, level = 0, onAddFilter }) => {
  const [localExpanded, setIsExpanded] = useState(false);
  const isExpanded = localExpanded;

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!localExpanded);
  };

  const handleClick = () => {
    onAddFilter?.('locations', node.locationID, node.name);
  };

  const getBoxShadow = (children) => {
    if (!children.length) return 'none';
    const colors = [...new Set(children.map((child) => child.color))].slice(0, 5);
    return colors.map((color, index) => `${(index + 1) * 2}px ${(index + 1) * 2}px 0 var(--bs-${color})`).join(', ');
  };

  const boxShadow = node.children.length > 0 && !isExpanded ? getBoxShadow(node.children) : 'none';

  return (
    <span className="locationCellNodeContainer d-inline-flex align-items-center flex-wrap gap-1">
      <Badge
        bg={node.color}
        className={`locationCellNode ${node.isAncestor ? 'unselectedAncestor' : ''}`}
        style={{
          cursor: 'pointer',
          boxShadow,
          border: `1px solid var(--bs-${node.color})`,
          margin: '2px',
          opacity: node.isAncestor ? 0.7 : 1,
        }}
        onClick={handleClick}
      >
        {node.name}
        {node.children.length > 0 && (
          <span onClick={toggleExpand} style={{ marginLeft: '5px', cursor: 'pointer' }}>
            {isExpanded ? '-' : '+'}
          </span>
        )}
      </Badge>
      {isExpanded && node.children.map((child) => <LocationCellNode key={child.locationID} node={child} onAddFilter={onAddFilter} />)}
    </span>
  );
};

const LocationArrayCell = ({ value, onAddFilter, recordId }) => {
  const { portalConfig } = usePortal();
  const locations = portalConfig.locations || [];
  const { showModal, hideModal, updateModal } = useModal();
  const { patchData } = usePatch();
  const selectedValueRef = useRef(value);
  const { hasAnyPermission } = usePermissions();
  const canEdit = hasAnyPermission(['editLocations', 'restrictEditLoc']);

  // Update ref when value prop changes
  useEffect(() => {
    selectedValueRef.current = value;
  }, [value]);

  // Get all ancestors of selected nodes
  const getAncestors = (locationId, locationMap) => {
    const ancestors = new Set();
    let current = locationMap.get(String(locationId));
    while (current?.parent) {
      ancestors.add(String(current.parent));
      current = locationMap.get(String(current.parent));
    }
    return ancestors;
  };

  // Build hierarchy for display
  const displayNodes = useMemo(() => {
    const selectedLocations = value.selected || [];

    // Create a map of all locations for easy lookup
    const locationMap = new Map();
    locations.forEach((loc) => {
      locationMap.set(String(loc.locationID), {
        ...loc,
        parent: loc.parent ? String(loc.parent) : null,
      });
    });

    // Get all ancestors of selected locations
    const ancestorIds = new Set();
    selectedLocations.forEach((id) => {
      const stringId = String(id);
      const ancestors = getAncestors(stringId, locationMap);
      ancestors.forEach((ancestorId) => ancestorIds.add(ancestorId));
    });

    // Build hierarchy with only selected nodes and their ancestors
    const buildNode = (location) => {
      const id = String(location.locationID);
      const isSelected = selectedLocations.map(String).includes(id);
      const isAncestor = ancestorIds.has(id);

      // Skip nodes that are neither selected nor ancestors
      if (!isSelected && !isAncestor) return null;

      // Get relevant children (only selected nodes or ancestors of selected nodes)
      const children = locations
        .filter((child) => String(child.parent) === id)
        .map((child) => buildNode(child))
        .filter(Boolean);

      return {
        ...location,
        children,
        isSelected,
        isAncestor: !isSelected && isAncestor,
      };
    };

    // Start with root nodes and build tree
    const rootNodes = locations
      .filter((loc) => !loc.parent)
      .map(buildNode)
      .filter(Boolean);

    return rootNodes;
  }, [locations, value.selected]);

  const handleEditClick = (e) => {
    e.stopPropagation();

    const modalConfig = {
      id: `edit-locations-${recordId}`,
      title: 'Edit Location Assignments',
      draggable: true,
      height: 700,
      width: 500,
      resizable: true,
      backdrop: false,
      component: LocationArraySelect,
      props: {
        value: selectedValueRef.current,
        onChange: (newValue) => {
          console.log('Modal LocationArraySelect onChange called with:', newValue);
          selectedValueRef.current = newValue;
          updateModal(`edit-locations-${recordId}`, {
            props: {
              value: newValue,
              onChange: modalConfig.props.onChange,
              className: 'p-2',
            },
          });
        },
        className: 'p-2',
      },
      footerContent: (
        <Button
          variant="primary"
          onClick={async () => {
            console.log('Saving with selectedValue:', selectedValueRef.current);
            try {
              const operations = [
                {
                  recordId: recordId,
                  changes: [
                    {
                      action: 'set',
                      field: 'locations',
                      value: selectedValueRef.current.selected,
                    },
                  ],
                },
              ];

              await patchData('/admin/records/update', { operations });
              hideModal(`edit-locations-${recordId}`);
            } catch (error) {
              console.error('Error updating locations:', error);
            }
          }}
        >
          Confirm Selections
        </Button>
      ),
      onClose: () => hideModal(`edit-locations-${recordId}`),
      size: 'lg',
    };

    showModal(modalConfig);
  };

  return (
    <div className="location-list p-1 d-flex align-items-center">
      <div className="d-flex flex-wrap gap-1 align-items-center">
        {displayNodes.map((node) => (
          <LocationCellNode key={node.locationID} node={node} onAddFilter={onAddFilter} />
        ))}
      </div>
      {canEdit && <FontAwesomeIcon icon={faEdit} className="ms-2 text-secondary" style={{ cursor: 'pointer' }} onClick={handleEditClick} size="lg" />}
    </div>
  );
};

const countLocations = (hierarchy) => {
  let count = 0;
  const countNodes = (nodes) => {
    nodes.forEach((node) => {
      count++;
      if (node.children?.length) {
        countNodes(node.children);
      }
    });
  };
  countNodes(hierarchy);
  return count;
};

// Add this helper function to find nodes that should be expanded
const findNodesToExpand = (hierarchy, selectedIds, indeterminateIds) => {
  const expandedNodes = new Set();
  const locationMap = new Map();

  // First build a map of all nodes with their parents
  const mapNodes = (nodes, parent = null) => {
    nodes.forEach((node) => {
      locationMap.set(node.locationID, { node, parent });
      if (node.children?.length) {
        mapNodes(node.children, node);
      }
    });
  };
  mapNodes(hierarchy);

  // Then find all parents of selected/indeterminate nodes and add them to expandedNodes
  const addParentsToExpanded = (nodeId) => {
    let current = locationMap.get(nodeId);
    while (current?.parent) {
      expandedNodes.add(current.parent.locationID);
      current = locationMap.get(current.parent.locationID);
    }
  };

  // Process selected nodes
  selectedIds.forEach((id) => {
    addParentsToExpanded(String(id));
  });

  // Process indeterminate nodes
  indeterminateIds.forEach((id) => {
    addParentsToExpanded(String(id));
  });

  return expandedNodes;
};

// Add this helper function to get all child location IDs
const getAllChildLocationIds = (node) => {
  let childIds = [];
  if (node.children?.length) {
    node.children.forEach((child) => {
      childIds.push(child.locationID);
      childIds = childIds.concat(getAllChildLocationIds(child));
    });
  }
  return childIds;
};

const LocationArraySelect = ({ value = { selected: [], indeterminate: [] }, onChange, className, style, disabled = false }) => {
  const { portalConfig } = usePortal();
  const { hasPermission, locations: userLocations } = usePermissions();
  const locations = portalConfig.locations || [];
  const [filterText, setFilterText] = useState('');
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const [savedExpandState, setSavedExpandState] = useState(null);

  // Check if user has restricted edit permission
  const hasRestrictedEdit = hasPermission('restrictEditLoc');

  // Filter locations based on user permissions and locations
  const filteredLocations = useMemo(() => {
    if (!hasRestrictedEdit) {
      return locations;
    }

    // Convert userLocations to Set for faster lookup
    const userLocationSet = new Set(userLocations.map(String));

    return locations.filter((location) => userLocationSet.has(String(location.locationID)));
  }, [locations, hasRestrictedEdit, userLocations]);

  // Calculate hidden locations
  const hiddenLocations = useMemo(() => {
    if (!hasRestrictedEdit) {
      return [];
    }
    return locations.filter((location) => !filteredLocations.some((fl) => String(fl.locationID) === String(location.locationID)));
  }, [locations, filteredLocations, hasRestrictedEdit]);

  // Add console log for value prop
  console.log('LocationArraySelect value:', value);

  const baseHierarchy = useMemo(
    () => buildLocationHierarchy(Object.values(filteredLocations), value.selected, value.indeterminate),
    [filteredLocations, value.selected, value.indeterminate]
  );

  // Initialize expanded nodes to show selected/indeterminate locations
  useEffect(() => {
    if (!filterText && !expandedNodes.size) {
      // Only set initial expansion if no nodes are expanded
      const nodesToExpand = findNodesToExpand(baseHierarchy, value.selected, value.indeterminate);
      setExpandedNodes(nodesToExpand);
    }
  }, [baseHierarchy]);

  // Save expand state when filter is applied and restore when cleared
  useEffect(() => {
    if (filterText && !savedExpandState) {
      setSavedExpandState(new Set(expandedNodes));
    } else if (!filterText && savedExpandState) {
      setExpandedNodes(savedExpandState);
      setSavedExpandState(null);
    }
  }, [filterText, savedExpandState, expandedNodes]);

  const handleToggle = useCallback(
    (locationId) => {
      console.log('handleToggle called with locationId:', locationId);
      if (disabled) return;

      // If user has restricted edit, check if they can toggle this location
      if (hasRestrictedEdit) {
        const userLocationSet = new Set(userLocations.map(String));
        if (!userLocationSet.has(String(locationId))) {
          return;
        }
      }

      const { selected, indeterminate } = value;
      const stringLocationId = String(locationId);
      let newSelected = selected.map(String);
      let newIndeterminate = indeterminate.map(String);

      // Find the node being toggled
      const findNode = (nodes, targetId) => {
        for (const node of nodes) {
          if (String(node.locationID) === targetId) {
            return node;
          }
          if (node.children?.length) {
            const found = findNode(node.children, targetId);
            if (found) return found;
          }
        }
        return null;
      };

      const toggledNode = findNode(baseHierarchy, stringLocationId);

      if (newSelected.includes(stringLocationId)) {
        // If deselecting, remove this node and all its children
        const childIds = getAllChildLocationIds(toggledNode);
        newSelected = newSelected.filter((id) => id !== stringLocationId && !childIds.includes(id));
      } else {
        // If selecting, add this node and all its children
        const childIds = getAllChildLocationIds(toggledNode);
        newSelected = [...newSelected, stringLocationId, ...childIds];
        // Remove any children from indeterminate state
        newIndeterminate = newIndeterminate.filter((id) => !childIds.includes(id));
      }

      console.log('Calling onChange with:', {
        selected: [...new Set(newSelected)],
        indeterminate: newIndeterminate,
      });

      onChange?.({
        selected: [...new Set(newSelected)],
        indeterminate: newIndeterminate,
      });
    },
    [value, onChange, disabled, baseHierarchy, hasRestrictedEdit, userLocations]
  );

  const filteredHierarchy = useMemo(() => filterLocationHierarchy(baseHierarchy, filterText, setExpandedNodes), [baseHierarchy, filterText]);

  const totalCount = useMemo(() => countLocations(baseHierarchy), [baseHierarchy]);
  const filteredCount = useMemo(() => countLocations(filteredHierarchy), [filteredHierarchy]);
  const hiddenByFilterCount = totalCount - filteredCount;

  const handleClearFilter = () => {
    setFilterText('');
  };

  // Create tooltip text for selected and indeterminate locations
  const getLocationNames = useCallback(
    (locationIds) => {
      return locationIds
        .map((id) => locations.find((loc) => String(loc.locationID) === String(id))?.name)
        .filter(Boolean)
        .join('\n');
    },
    [locations]
  );

  const selectedLocationsTooltip = useMemo(() => getLocationNames(value.selected), [getLocationNames, value.selected]);
  const indeterminateLocationsTooltip = useMemo(() => getLocationNames(value.indeterminate), [getLocationNames, value.indeterminate]);
  const hiddenLocationsTooltip = useMemo(
    () =>
      hasRestrictedEdit
        ? `You do not have permission to modify the following locations:\n${getLocationNames(hiddenLocations.map((loc) => loc.locationID))}`
        : '',
    [hasRestrictedEdit, hiddenLocations, getLocationNames]
  );

  return (
    <div
      className={`location-select ${className || ''}`}
      style={{
        opacity: disabled ? 0.7 : 1,
        pointerEvents: disabled ? 'none' : 'auto',
        ...style,
      }}
    >
      <div className="location-status mb-2">
        <small className="text-muted d-flex justify-content-between">
          {value.selected.length > 0 && (
            <span className="me-2" title={selectedLocationsTooltip}>
              <strong>{value.selected.length}</strong> selected
            </span>
          )}
          {value.indeterminate.length > 0 && (
            <span className="me-2" title={indeterminateLocationsTooltip}>
              <strong>{value.indeterminate.length}</strong> indeterminate
            </span>
          )}
          {hasRestrictedEdit && hiddenLocations.length > 0 && (
            <span title={hiddenLocationsTooltip}>
              <strong>{hiddenLocations.length}</strong> hidden
            </span>
          )}
          {value.selected.length === 0 && value.indeterminate.length === 0 && <span>No locations selected</span>}
        </small>
      </div>
      <div className="location-filter mb-2">
        <div className="filter-input-wrapper">
          <input
            type="text"
            className="form-control"
            placeholder="Filter locations..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          {filterText && <FontAwesomeIcon icon={faXmark} className="filter-clear-icon" onClick={handleClearFilter} style={{ color: '#dc3545' }} />}
        </div>
        {filterText && hiddenByFilterCount > 0 && (
          <small className="text-muted mt-1 d-block">
            {hiddenByFilterCount} location{hiddenByFilterCount !== 1 ? 's' : ''} hidden by filter
          </small>
        )}
      </div>
      <div className="location-tree">
        {filteredHierarchy.map((node) => (
          <LocationNode
            key={node.locationID}
            node={node}
            onToggle={handleToggle}
            isSelectionMode={true}
            expandedNodes={expandedNodes}
            setExpandedNodes={setExpandedNodes}
            filterText={filterText}
          />
        ))}
      </div>
    </div>
  );
};

export { LocationArrayDisplay, LocationArrayEdit, LocationArrayCell, LocationArraySelect };
