import React, { useEffect, useRef } from 'react';
import { ListGroup, Form, Badge } from 'react-bootstrap';
import HelpText from './HelpText';

const SelectDisplay = ({ attribute }) => {
  const { name, label, value, displayStyles, displayClasses } = attribute;

  const defaultClasses = {
    component: 'selectDisplay',
    label: 'select-label',
    value: 'select-value',
  };

  const defaultStyles = {
    component: {},
    label: {},
    value: {},
  };

  return (
    <div className={displayClasses?.component || defaultClasses.component} style={displayStyles?.component || defaultStyles.component || {}}>
      <div className={displayClasses?.label || defaultClasses.label} style={displayStyles?.label || defaultStyles.label || {}}>
        {label || name}:
      </div>
      <div className={displayClasses?.value || defaultClasses.value} style={displayStyles?.value || defaultStyles.value || {}}>
        {value || 'Not selected'}
      </div>
    </div>
  );
};

const SelectEdit = ({ attribute, onChange }) => {
  const { name, label, value, required, helpText, options, editClasses, editStyles } = attribute;
  const initialRender = useRef(true);

  const defaultClasses = {
    component: 'col-12 col-md-6 p-2',
    label: 'form-label fw-bold',
    input: 'form-select',
  };

  const defaultStyles = {
    component: {},
    label: {},
    input: {},
  };

  // Only set initial value on mount if required and no value is set
  useEffect(() => {
    if (initialRender.current && required && !value && options?.length > 0) {
      initialRender.current = false;
      onChange(name, options[0]);
    }
  }, []); // Empty dependency array - only run on mount

  return (
    <div className={editClasses?.component || defaultClasses.component} style={editStyles?.component || defaultStyles.component}>
      <Form.Label
        htmlFor={`select-edit-${name}`}
        className={editClasses?.label || defaultClasses.label}
        style={editStyles?.label || defaultStyles.label}
      >
        {label || name} {required && <span style={{ color: 'red' }}>*</span>}
        <HelpText text={helpText} />
      </Form.Label>
      <Form.Select
        id={`select-edit-${name}`}
        value={value || ''}
        onChange={(e) => onChange(name, e.target.value)}
        className={editClasses?.input || defaultClasses.input}
        style={editStyles?.input || defaultStyles.input}
        required={required}
      >
        {!required && <option value="">Select an option</option>}
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

const SelectCell = ({ value, columnName, onAddFilter }) => {
  if (!value) {
    return <div className="selectCell">-</div>;
  }

  return (
    <div className="selectCell">
      <Badge bg="secondary" onClick={() => onAddFilter(columnName, value)} style={{ cursor: 'pointer' }}>
        {value}
      </Badge>
    </div>
  );
};

export { SelectDisplay, SelectEdit, SelectCell };
